import { Editor } from "@tinymce/tinymce-react"
import api from "src/api"
import { STATE_MEDIA } from "src/config"

export function TinyMCE({ value, onChange }) {
  const imageUploadUrl = "dashboard-image-upload"

  async function handleImageUpload(blobInfo, success) {
    let data = new FormData()
    data.append("file", blobInfo.blob())
    await api.post(imageUploadUrl, data).then(({ data }) => {
      console.log(data)
      success(STATE_MEDIA + data.file.fullPath)
    })
  }

  return (
    <Editor
      apiKey="ugmfg6g1fu7ncu8isbzyv8j22pokpn2n2u1vsleuruh9mz36"
      value={value}
      onEditorChange={onChange}
      init={{
        menubar: false,
        toolbar:
          "formatselect fontsizeselect forecolor alignleft aligncenter alignright alignjustify bold italic underline strikethrough blockquote bullist numlist table image emoticons link anchor subscript superscript undo redo removeformat code fullscreen preview",
        toolbar_mode: "floating",
        skin: "snow",
        language: "ru",
        toolbar_sticky: true,
        block_formats:
          "Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6; NOINDEX=noindex",
        plugins:
          "code emoticons fullscreen image link autolink lists paste autoresize preview anchor table",
        valid_children: "+body[style]",
        advlist_bullet_styles: "default",
        automatic_uploads: true,
        images_upload_url: imageUploadUrl,
        paste_as_text: true,
        images_upload_handler: handleImageUpload,
        extended_valid_elements: "noindex",
        custom_elements: "noindex",
        rel_list: [
          { title: "None", value: null },
          { title: "No Follow", value: "nofollow" },
        ],
        color_map: [
          "000000",
          "Black",
          "292C33",
          "Dark Gray",
          "7A7D87",
          "Medium Gray",
          "A6A9AE",
          "Light Gray",
          "ef5a5a",
          "Red",
          "318C71",
          "Green",
          "17795C",
          "Dark Green",
        ],
        fontsize_formats: "32px 28px 24px 22px 20px 18px 16px 14px 12px 10px",
        custom_colors: false,
        table_appearance_options: false,
        table_advtab: false,
        table_cell_advtab: false,
        table_row_advtab: false,
        formats: {
          alignleft: {
            selector: "p,h1,h2,h3,h4,h5,h6,div,img,tr,td,th",
            classes: "align-left",
          },
          aligncenter: {
            selector: "p,h1,h2,h3,h4,h5,h6,div,img,tr,td,th",
            classes: "align-center",
          },
          alignright: {
            selector: "p,h1,h2,h3,h4,h5,h6,div,img,tr,td,th",
            classes: "align-right",
          },
          alignfull: {
            selector: "p,h1,h2,h3,h4,h5,h6,div,img",
            classes: "align-justify",
          },
          noindex: {
            block: "noindex",
            wrapper: true,
            exact: true,
          },
        },
        content_style:
          "body { font-size: 18px; line-height: 1.3!important; color: #494B52;}" +
          "p { margin-bottom: 24px; }" +
          "img { max-width: 100%; margin-top: 32px; margin-bottom: 32px; height: auto; }" +
          ".align-left,.ql-align-left { text-align: left; } " +
          ".align-right,.ql-align-right { text-align: right; } " +
          "img.align-right,img.ql-align-right { display: block; margin-left: auto; }" +
          ".align-center,.ql-align-center { text-align: center; } " +
          "img.align-center,img.ql-align-center { display: block; margin-right: auto; margin-left: auto; } " +
          ".align-justify,.ql-align-justify { text-align: justify; } " +
          "blockquote { margin: 0; margin-bottom: 24px; white-space: pre-wrap; display: block; padding: 25px 30px; border: 3px solid #222!important; } " +
          "blockquote>p { margin: 0 } " +
          " h2,h3,h4,h5,h6 { margin-top: 40px; margin-bottom: 24px; color: #292C33; font-size: 28px; line-height: 1.2; } " +
          " h3,h4,h5,h6 { font-size: 20px; line-height: 1.3; } " +
          "a { display: inline; background-image: linear-gradient(180deg, #A6A9AE, #A6A9AE); background-position: 0 100%; background-repeat: no-repeat; background-size: 100% 1px; color: #494B52; text-decoration: none; } " +
          " ul,ol { margin-top: 32px; margin-bottom: 32px; } " +
          " noindex { display: block; background-image: linear-gradient(45deg, #eee 25%, rgba(238, 238, 238, 0) 25%, rgba(238, 238, 238, 0) 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%); background-position: 0px 0px, 5px 5px; background-size: 10px 10px; } " +
          " a[rel=nofollow] { background-image: linear-gradient(180deg, #A6A9AE, #A6A9AE), linear-gradient(45deg, #eee 25%, rgba(238, 238, 238, 0) 25%, rgba(238, 238, 238, 0) 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%); background-position: 0 100%, 0px 0px, 5px 5px; background-size: 100% 1px, 10px 10px, 10px 10px; background-repeat: no-repeat, repeat, repeat;} " +
          " table { margin-top: 24px; margin-bottom: 24px; } ",
        init_instance_callback: function (editor) {
          editor.on("KeyDown", function (e) {
            if (e.keyCode === 27) {
              let editor = window.tinyMCE.activeEditor
              const dom = editor.dom
              const parentBlock =
                window.tinyMCE.activeEditor.selection.getSelectedBlocks()[0]
              const containerBlock =
                parentBlock.parentNode.nodeName == "BODY"
                  ? dom.getParent(parentBlock, dom.isBlock)
                  : dom.getParent(parentBlock.parentNode, dom.isBlock)
              let newBlock = window.tinyMCE.activeEditor.dom.create("p")
              newBlock.innerHTML = "<br data-mce-bogus='1'>"
              dom.insertAfter(newBlock, containerBlock)
              let rng = dom.createRng()
              newBlock.normalize()
              rng.setStart(newBlock, 0)
              rng.setEnd(newBlock, 0)
              editor.selection.setRng(rng)
            }
          })
        },
      }}
    />
  )
}
