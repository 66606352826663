import React from "react"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import {
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row, Select,
  Spin,
  Typography,
  Upload,
} from "antd"
import moment from "moment"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import {getInitialValues, getOldImage, whiteSpaceValidation} from "src/utils/helpers"
import styled from "styled-components"
import {StoriesLinkBuilder} from "src/pages-lib/stories/stories-link-builder";
import {VisibilityProvider} from "../../components/visibility-provider";
import {TranslatableInput, TranslatableTextarea} from "../../components/Translatable";
import { getProductHintsWithGroup} from "../../utils";
import {LinkBuilder} from "../../components/link-builder/link-builder";

const { Title } = Typography

export function EditDrawer({
                             title,
                             initialValues,
                             onClose,
                             onSave,
                             rewardTypeOptions,
                             eventOptions,
                             categoryOptions
                           }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)
  const [previewImage, setPreviewImage] = React.useState(null)
  const [imageUpdated, setImageUpdated] = React.useState(false)
  const [updatedImagesIds, setUpdatedImagesIds] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorLength, setErrorLength] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const fetchRef = React.useRef(0)
  const [linkBuildState, setLinkBuildState] = React.useState(false)

  const [form] = Form.useForm()

  function getImage(files) {
    const file = files?.file ?? files?.fileList?.[0] ?? files[0]
    if (file instanceof File) {
      file.url = URL.createObjectURL(file)
    }
    return file
  }

  const loadProductOptions = (value) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setOptions([])
    setFetching(true)
    getProductHintsWithGroup(value).then((newOptions) => {
      const result = []
      newOptions.data.items.forEach((product) => {
        result.push({
          label: product.name,
          value: product.id
        })
      })
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }
      setOptions(result)
      setFetching(false)
    })
  }

  function getElements(elements) {
    const newElements = elements.map((element) => ({
      ...element,
      image: getImage(element.image),
      imageUpdated: element.id ? (updatedImagesIds.includes(element.id) ? 1 : 0 ) : 1,
    }))

    if (!initialValues) {
      return newElements
    }

    const deleted = initialValues.elements
      .filter((element) => !elements.find((newElement) => newElement.id === element.id))
      .map((element) => ({ ...element, isDeleted: 1 }))

    return [...newElements, ...deleted]
  }

  async function onSubmit(values) {
    setIsLoading(true)
    if (values.elements?.length > 0) {
      setErrorLength(false)
      const file = getImage(values.image)
      const id = initialValues?.id
      const isNeedImage = !id || (id && imageUpdated)

      const data = {
        ...(id && { id, _method: "PUT" }),
        ...(values.link && { link: values.link  }),
        imageUpdated: id ? (imageUpdated ? 1 : 0) : 1,
        isActive: values.isActive ? 1 : 0,
        ...(isNeedImage && { image: file }),
      }

      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => formData.append(key, value))

      Object.entries(values.name).forEach(([key, value], index) => {
        if (value) {
          formData.append(`name[${index}][language]`, key)
          formData.append(`name[${index}][content]`, value)
        }
      })

      Object.entries(values.description).forEach(([key, value], index) => {
        if (value) {
          formData.append(`description[${index}][language]`, key)
          formData.append(`description[${index}][content]`, value)
        }
      })

      Object.entries(values.additionalText).forEach(([key, value], index) => {
        if (value) {
          formData.append(`additionalText[${index}][language]`, key)
          formData.append(`additionalText[${index}][content]`, value)
        }
      })

      Object.entries(values.linkText).forEach(([key, value], index) => {
        if (value) {
          formData.append(`linkText[${index}][language]`, key)
          formData.append(`linkText[${index}][content]`, value)
        }
      })

      const newElements = getElements(values.elements)

      newElements.forEach((element, index) => {
        Object.entries(element).forEach(([key, value]) => {
          if(value) {
            if (key === "startAt" || key === "endAt") {
              formData.append(`elements[${index}][${key}]`, value ? moment(value).utcOffset(360).toISOString() : null)
            } else if (key === "partnerLinkText") {
              formData.append(`elements[${index}][${key}][0][language]`, "ru")
              formData.append(`elements[${index}][${key}][0][content]`, value)
            } else {
              formData.append(`elements[${index}][${key}]`, value ?? null)
            }
          }

        })
      })

      const isPut = data._method === "PUT" && data.id

      try {
        await onSave(formData, data.id, isPut)
        form.resetFields()
      } finally {
        setIsLoading(false)
      }
    } else {
      setErrorLength(true)
      setIsLoading(false)
    }

  }

  React.useEffect(() => {
    if (initialValues) {
      console.log(initialValues?.link?.includes("bushe:"))
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  const id = initialValues?.id ?? "new"

  async function onPreview(file) {
    if (file instanceof File) {
      file.url = await URL.createObjectURL(file)
    }
    setPreviewImage(file)
  }

  function onChange(e) {
    setImageUpdated(true)
    return e
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }

  function onChangeImage(e, key) {
    const id = form.getFieldValue("elements")?.[key]?.id
    if (id) {
      setUpdatedImagesIds([...updatedImagesIds, id])
    }
    return e
  }

  async function onReset() {
    if (initialValues) {
      await form.resetFields()
      await form.setFieldsValue(initialValues)
      return
    }
    form.resetFields()
  }

  function handleRouteChange(value) {
    if(value.target.value === "innerLink") {
      setLinkBuildState(true)
    }
  }

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "50%"}
      title={title}
      onClose={handleClose}
      open
    >
      <StyledImage
        alt={previewImage?.name || "Превью"}
        preview={{
          visible: previewImage,
          onVisibleChange: () => setPreviewImage(null),
        }}
        src={previewImage?.url || previewImage?.thumbUrl}
      />
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <Divider orientation="left">Картинка</Divider>
        <StyledFormItem
          name={"image"}
          rules={[
            {
              required: true,
              message: "Выберите изображение!",
            },
          ]}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            accept="image/jpeg,image/png,image/jpg"
            onPreview={onPreview}
            status="done"
            beforeUpload={() => false}
            onChange={onChange}
            defaultFileList={getOldImage(initialValues?.image)}
            dependencies={"image"}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить изображение</div>
            </div>
          </Upload>
        </StyledFormItem>

        <Divider orientation="left">Настройки</Divider>

        <StyledFormItem label={"Название"}>
          <TranslatableInput name={"name"} id={id}></TranslatableInput>
        </StyledFormItem>

        <StyledFormItem
            label={"Описание"}
        >
          <TranslatableTextarea name={"description"} id={id}></TranslatableTextarea>
        </StyledFormItem>

        <StyledFormItem
            label={"Дополнительный текст"}
        >
          <TranslatableTextarea name={"additionalText"} id={id} required={false}></TranslatableTextarea>
        </StyledFormItem>

        <StyledFormItem

            key={id + "_route"}
            label={"Роутинг"}
            name={"route"}
            initialValue={
              initialValues?.link?.includes("bushe:") ? "innerLink" : "outherLink"
            }
        >
          <Radio.Group onChange={handleRouteChange} >
            <Radio value={"outherLink"}>Внешний роутинг</Radio>
            <Radio value={"innerLink"}>Внутренний роутинг</Radio>
          </Radio.Group>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_link"}
            label={"Ссылка"}
            name={"link"}
            rules={[
              {
                validator: whiteSpaceValidation,
              },
            ]}
        >
          <Input
              type={"text"}
              placeholder={"Ссылка"}
          />
        </StyledFormItem>

        <StyledFormItem
            label={"Текст для ссылки"}
        >
          <TranslatableInput name={"linkText"} id={id} required={false}></TranslatableInput>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_isActive"}
            label={"Активность"}
            name={"isActive"}
            initialValue={form.isActive ? form.isActive : true}
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите!",
              },
            ]}
        >
          <Radio.Group buttonStyle="solid" value={form.isActive}>
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>

        <Divider orientation="left">Элементы</Divider>

        { errorLength && (<>
          <div style={{ color: "red" }}>Добавьте хотя-бы 1-ин элемент!</div>
        </>)}

        <Form.List name="elements">
          {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...field }, index) => (
                    <React.Fragment key={`RowTitle_${index}`}>
                      <RowTitle level={4}>
                        Элемент № {key + 1}{" "}
                        {fields.length > 0 ? (
                            <MinusCircleOutlined
                                className="dynamic-delete-button"
                                style={{ marginLeft: "12px" }}
                                onClick={() => {
                                  remove(name)
                                }}
                            />
                        ) : null}
                      </RowTitle>
                      <Form.Item required={false} key={key}>
                        <StyledFormItem
                            name={[name, "image"]}
                            rules={[
                              {
                                required: true,
                                message: "Выберите изображение!",
                              },
                            ]}
                        >
                          <Upload
                              listType="picture-card"
                              maxCount={1}
                              onPreview={onPreview}
                              status="done"
                              beforeUpload={() => false}
                              onChange={(e) => onChangeImage(e, key)}
                              defaultFileList={getOldImage(initialValues?.elements?.[key]?.image)}
                          >
                            <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Загрузить изображение</div>
                            </div>
                          </Upload>
                        </StyledFormItem>

                        <StyledFormItem
                            {...field}
                            key={id + "_elementName"}
                            label={"Название элемента"}
                            name={[name, "elementName"]}
                            rules={[
                              {
                                message: "Введите название",
                                required: true
                              },
                              {
                                validator: whiteSpaceValidation
                              }
                            ]}
                        >
                          <Input type={"text"} maxLength={255} placeholder={"Название"} />
                        </StyledFormItem>

                        <StyledFormItem
                            {...field}
                            key={id + "_typeId"}
                            label={"Тип награды"}
                            name={[name, "typeId"]}
                            rules={[
                              {
                                message: "Выберите тип!",
                                required: true
                              }
                            ]}
                        >
                          <Select options={rewardTypeOptions} placeholder={"Тип награды"} />
                        </StyledFormItem>

                        <StyledFormItem
                            {...field}
                            key={id + "_productId"}
                            label={"Продукт"}
                            name={[name, "productId"]}
                        >
                          <Select
                              showSearch={true}
                              filterOption={false}
                              onSearch={loadProductOptions}
                              notFoundContent={fetching ? <Spin size="small" /> : null}
                              options={options}
                          />
                        </StyledFormItem>

                        <StyledFormItem
                            {...field}
                            key={id + "_eventId"}
                            label={"Мероприятие"}
                            name={[name, "eventId"]}
                        >
                          <Select options={eventOptions} placeholder={"Мероприятие"} />
                        </StyledFormItem>

                        <StyledFormItem
                            {...field}
                            key={id + "_code"}
                            label={"Код"}
                            name={[name, "code"]}
                        >
                          <Input type={"text"} maxLength={255} placeholder={"Код"} />
                        </StyledFormItem>

                        <StyledFormItem
                            {...field}
                            key={id + "_qrСode"}
                            label={"QR Код"}
                            name={[name, "qrСode"]}
                        >
                          <Input type={"text"} maxLength={255} placeholder={"QR Код"} />
                        </StyledFormItem>

                        <Col className="gutter-row" span={12}>
                          <StyledFormItem
                              {...field}
                              key={id + "_route"}
                              label={"Роутинг"}
                              name={[name, "route"]}
                              initialValue={
                                initialValues?.elements?.[key]?.partnerLink?.includes("bushe:") ? "innerLink" : "outherLink"
                              }
                          >
                            <Radio.Group onChange={handleRouteChange} >
                              <Radio value={"outherLink"}>Внешний роутинг</Radio>
                              <Radio value={"innerLink"}>Внутренний роутинг</Radio>
                            </Radio.Group>
                          </StyledFormItem>

                        </Col>
                        <StyledFormItem
                            {...field}
                            key={id + "_partnerLink"}
                            label={"Ссылка"}
                            name={[name, "partnerLink"]}
                        >
                          <Input type={"text"} maxLength={255} placeholder={"Ссылка на партнёра"} />
                        </StyledFormItem>

                        <StyledFormItem
                            {...field}
                            key={id + "_partnerLinkText"}
                            label={"Текст ссылки на партнёра"}
                            name={[name, "partnerLinkText"]}
                        >
                          <Input type={"text"} maxLength={255} placeholder={"Текст"} />
                        </StyledFormItem>

                        <Row gutter={28}>
                          <Col className="gutter-row" span={8}>
                            <StyledFormItem
                                {...field}
                                key={id + "_startAt"}
                                label={"Срок действия (начало)"}
                                name={[name, "startAt"]}
                            >
                              <DatePicker
                                  showHour
                                  style={{ width: "100%" }}
                              />
                            </StyledFormItem>
                          </Col>
                          <Col className="gutter-row" span={8}>
                            <StyledFormItem
                                {...field}
                                key={id + "_endAt"}
                                label={"Срок действия (конец)"}
                                name={[name, "endAt"]}
                            >
                              <DatePicker
                                  showHour
                                  style={{ width: "100%" }}
                              />
                            </StyledFormItem>
                          </Col>

                          <Col className="gutter-row" span={8}>
                            <StyledFormItem
                                {...field}
                                key={id + "_count"}
                                label={"Количество"}
                                name={[name, "count"]}
                            >
                              <InputNumber
                                  min={1}
                                  placeholder={"Количество"}
                                  style={{ width: "100%" }}
                              />
                            </StyledFormItem>
                          </Col>
                        </Row>
                      </Form.Item>
                    </React.Fragment>
                ))}

                <Row gutter={28}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item>
                      <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                      >
                        Добавить
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
          )}
        </Form.List>

        <ButtonRow>
          <ButtonCol>
            <Button danger onClick={onReset} icon={<RotateClockwiseSvg />}>
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button
              icon={<SaveSvg />}
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>

      <VisibilityProvider visible={linkBuildState}>
        <StoriesLinkBuilder
            title={"Новая ссылка"}
            onCloseDrawer={() => setLinkBuildState(false)}
        />
      </VisibilityProvider>

    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledImage = styled(Image)`
  display: none;
`

const RowFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`

const RowTitle = styled(Title)`
  margin-bottom: 20px;
`
