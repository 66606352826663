import React from "react";
import {useGetData, useManageItems} from "../pages-lib/faq/model";
import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import {getColumns} from "../pages-lib/faq/table-columns";
import { Table as AntTable } from "antd";
import styled from "styled-components";
import { VisibilityProvider } from "src/components/visibility-provider";
import {EditDrawer} from "../pages-lib/faq/edit-drawer";

export default function Faq() {

    const [createDrawerState, setCreateDrawerState] = React.useState(false)
    const [editDrawerState, setEditDrawerState] = React.useState(false)

    const {
        isLoading,
        dataItems,
        dataItemsColumn,
        refetch: getItems,
        options
    } = useGetData()

    const {
        editData,
        onSaveEdit,
        onCreate,
        onDelete,
        onEditItem
    } = useManageItems(dataItems, getItems, onSuccessEdit, onSuccessCreate)

    const headerTitle = useUpdateHeaderTitle({
        items: dataItems,
        defaultTitle: "0 Блоков",
        arrType: ["Блок", "Блока", "Блоков"],
    })

    const columns = getColumns(onEditItem, onDelete)

    function onSuccessEdit() {
        setEditDrawerState(!editDrawerState)
    }

    function onSuccessCreate() {
        setCreateDrawerState(!createDrawerState)
    }

    return (
        <>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        onCreate={() => setCreateDrawerState(true)}
                        // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                        // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                        titles={{ onCreate: "Добавить блок" }}
                    />
                }
            />

            <StyledTable
                dataSource={dataItemsColumn}
                columns={columns}
                loading={isLoading}
                pagination={false}
            />

            <VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    initialValues={false}
                    onSave={onCreate}
                    onClose={() => setCreateDrawerState(false)}
                    title={"Создание блока"}
                    typeOptions={options.typeOptions}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    initialValues={editData}
                    onSave={onSaveEdit}
                    onClose={() => setEditDrawerState(false)}
                    title={"Редактирование блока"}
                    typeOptions={options.typeOptions}
                />
            </VisibilityProvider>

        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`