import React from "react"
import { notification } from "antd"
import api from "src/api"

export function useManageItems(items, getItems, onSuccessEdit, onSuccessCreate) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id)
    const data = formmatedEditData(item)
    setEditData(data)
    onSuccessEdit()
  }

  async function onCreate(values) {
    try {
      await api.post("promotions", values)

      notification.success({ message: "Акция добавлена" })
      onSuccessCreate()
      getItems()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }

  async function onSaveEdit(values, id) {
    try {
      await api.post(`promotions/${id}`, values)

      notification.success({ message: "Акция изменена" })
      getItems()
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  async function onDelete(id) {
    try {
      await api.delete(`promotions/${id}`)

      notification.success({ message: "Акция удалена" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  function resetData() {
    setEditData(null)
  }

  function formmatedEditData(item) {
    const pointIds = item.points.map((element) => element.id)

    const productIds = item.products.map((element) => ({
      label: element.name,
      value: element.id,
    }))

    return {
      key: item.id,
      ...item,
      promotionCategoryId: item.promotionCategory.id,
      pointIds,
      productIds,
    }
  }

  return { editData, resetData, onSaveEdit, onCreate, onDelete, onEditItem }
}
