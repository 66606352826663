import api from "src/api/index";
import {STATE_MEDIA} from "src/config";

export async function getProductCategories(){
  try {
    const response =  await api.get(STATE_MEDIA + "api/dashboard/product-categories")
    return response.data?.items ?? []
  } catch (error) {
    console.error("ошибка загрузки категорий", error?.message)
  }
}
