import React from "react"
import { notification } from "antd"
import api from "src/api"

export function useManageItems(items, getItems) {
  const [updatingStep, updateStep] = React.useState(null)

  function onCreateItem() {
    updateStep({ state: "create" })
  }

  function onEditItem(id) {
    const step = items.find((step) => step.id === id)
    updateStep({ state: "edit", step })
  }

  async function onDelete(id) {
    try {
      await api.delete(`onboarding/${id}`)
      notification.success({ message: "Онбординг удалён" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
    }
  }

  async function onSave(values, id, isPut) {
    const method = id && isPut ? `onboarding/${id}` : "onboarding"

    try {
      await api.post(method, values)

      notification.success({
        message: isPut ? "Онбординг изменён" : "Онбординг добавлен",
      })
      updateStep(null)
      getItems()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }

  function resetItem() {
    updateStep(null)
  }

  return { updatingStep, onSave, onDelete, onEditItem, onCreateItem, resetItem }
}


export function getFormattedItems(items) {
  let data = [];
  items.forEach((item) => {
    data.push({
      key: item.id,
      id: item.id,
      isActive: item.isActive,
      versionIOs: item.versionIOs,
      versionAndroid: item.versionAndroid,
      nameRu: item.name?.find((el) => el.language === "ru").content,
      descriptionRu: item.description?.find((el) => el.language === "ru").content,
      stepsCount: item.steps.length
    })
  })
  return data;
}
