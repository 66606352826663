import React from "react"
import { notification } from "antd"
import api from "src/api"

export function useManageItems(items, getItems, onSuccessEdit) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id)
    setEditData(item)
    onSuccessEdit()
  }

  async function onDelete(id) {
    try {
      await api.delete(`payment-accounts/${id}`)

      notification.success({ message: "Аккаунт удалён" })
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    } finally {
      getItems()
    }
  }

  async function onCreate(data) {
    try {
      await api.post("payment-accounts", data)

      notification.success({ message: "Аккаунт добавлен" })
    } catch (error) {
      console.log("Ошибка создания", error)
      notification.error({ message: "Ошибка создания" })
      throw error
    } finally {
      getItems()
    }
  }

  async function onSaveEdit(values) {
    try {
      await api.post(`payment-accounts/${editData.id}`, values)

      notification.success({ message: "Аккаунт изменен" })

      onSuccessEdit()
      setEditData(false)
      getItems()
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  function resetData() {
    setEditData(null)
  }

  return { editData, resetData, onSaveEdit, onCreate, onDelete, onEditItem }
}
