import { Popconfirm, Space, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import { PencilSvg, TrashSvg } from "src/components/Svg"
import styled from "styled-components"

export function getColumns(onEdit, onDelete) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
    },
    {
      title: "Название",
      dataIndex: "nameRu",
      key: "nameRU",
      sorter: (a, b) => a.nameRU - b.nameRU,
    },
    {
      title: "Системное название",
      dataIndex: "slug",
      key: "slug"
    },
    {
      title: "Логин",
      dataIndex: "login",
      key: "login"
    },
    {
      title: "Пароль",
      dataIndex: "password",
      key: "password",
      render: (_, record) => (
          <span>****</span>
      ),
    },
    {
      title: "Налогооблажение",
      dataIndex: "taxSystemName",
      key: "taxSystemName",
      render: (text) => <StyledTag color="default">{text}</StyledTag>,
    },
    {
      title: "С НДС",
      dataIndex: "isHaveVat",
      key: "isHaveVat",
      render: (text) => (
          <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
            {text ? "ДА" : "НЕТ"}
          </StyledTag>
      ),
    },
    {
      title: "Основной",
      dataIndex: "isDefault",
      key: "isDefault",
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },
    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
            <Popconfirm
              placement="left"
              title="Действительно хотите удалить?"
              onConfirm={() => onDelete(record.id)}
              onCancel={() => stopPropagation}
            >
              <Button size="small" type="link" onClick={stopPropagation}>
                <TrashSvg />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]
}

const stopPropagation = (e) => {
  e?.stopPropagation()
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`
