import React from "react"
import api from "src/api"

export function useGetData() {
  const [dataItems, setDataItems] = React.useState([])
  const [dataItemsColumn, setDataItemsColumn] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)

  const [typeOptions, setTypeOptions] = React.useState([])

  React.useEffect(() => {
    getItems()
    getTypeOptions()
  }, [])

  async function getTypeOptions() {
    api.get("loyalty-faq/types")
        .then(({ data }) => {
          const options = []
          data.items.forEach((item) => {
            options.push({
              label: item.name,
              value: item.id,
            })
          })
          setTypeOptions(options)
        })
  }

  const getItems = () => {
    setIsLoading(true)
    api.get("loyalty-faq").then(({ data }) => {
      updateItems(data.items)
    })

    setIsLoading(false)
  }

  function updateItems(items) {
    const newData = getFormattedItems(items)
    setDataItemsColumn(newData)
    setDataItems(items)
  }

  function getFormattedItems(items) {
    const newItems = []

    items.forEach((item) => {
      let newItem = {
        key: item.id,
        id: item.id,
        sortWeight: item.sortWeight,
        isActive: item.isActive,
        isForStaff: item.isForStaff,
        title: item.title?.find((el) => el.language === "ru")?.content ?? null,
        content: item.content?.find((el) => el.language === "ru")?.content ?? null,
        type: item.type.name,
      }
      newItems.push(newItem)
    })

    return newItems
  }

  return {
    dataItems,
    dataItemsColumn,
    isLoading,
    //handleTableChange,
    refetch: () => getItems(),
    options: { typeOptions },
  }
}
