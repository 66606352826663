import React, {useState} from "react";
import api from "src/api";
import {getProductPointStatus, getPoints} from "src/utils";

export function useGetPointProducts() {
    const [pagination, setPagination] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [pointOptions, setPointOptions] = React.useState([]);
    const [productPointStatusOptions, setProductPointStatusOptions] = React.useState([]);

    async function getItems(page = 1, countOnPage = 20, filters = "") {
        setIsLoading(true);
        try {
            const response = await api.get(`product-point?page=${page}&countOnPage=${countOnPage}${filters}`);

            setPagination({
                current: response.data?.pagination?.currentPage ?? page,
                pageSize: response.data?.pagination?.countOnPage ?? countOnPage,
                total: response.data?.pagination?.total ?? countOnPage
            });
            setItems(response.data.pointProducts);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    async function getPointOptions() {
        let data = await getPoints()

        const options = data.map((item) =>({
            label: item.name,
            value: item.id
        }));
        setPointOptions(options)
    }

    function getPointProductsStatus() {
        let data = getProductPointStatus()
        setProductPointStatusOptions(data)
    }




    const handleTableChange = (pagination) => {
        getItems(pagination.current, pagination.pageSize )
    };

    function handleSearchTable(filters) {
        let filters_str = ""
        let count = 0;
        for (let [key, value] of Object.entries(filters)) {

            if(value) {
                switch (key) {
                    case "nameProduct":
                        filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=product.name->>'ru'&filters[${count}][v]=${value}`
                        break;
                    case "namePoint":
                        filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=point.name->>'ru'&filters[${count}][v]=${value}`
                        break;
                }
            }

            count++
        }
        getItems(1,  20, filters_str)
    }

    React.useEffect(() => {
        getItems();
        getPointOptions();
        getPointProductsStatus()
    }, []);

    return {
        items,
        pagination,
        isLoading,
        refetch: () => getItems(),
        handleTableChange,
        options: { pointOptions, productPointStatusOptions },
        handleSearchTable
    };
}


export function getFormattedItems(items, statuses) {
    const data = items.map((item) => {
        return {
            key: item.id,
            id: item.id,
            pointId: item.point.id,
            pointName: item.point.name,
            pointNameRu: item.point.name?.find((el) => el.language === "ru")?.content,
            productId: item.product.id,
            productName: item.product.name,
            productNameRu: item.product.name?.find((el) => el.language === "ru")?.content,
            balanceOfGoods: item.balanceOfGoods,
            deliveryPrice: item.deliveryPrice,
            price: item.price,
            isUnlimited: item.isUnlimited,
            status: statuses.find(status => status.value === item.status).label}
    })

    return data
}

export async function SearchProducts(name) {
    let data = [];
    if(name.length > 2) {
        let response = await api.get(`product/filter?name=${name}`)


        response.data.products.forEach((item) => {
            data.push({
                label: item.name.find((el) => el.language === "ru")?.content,
                value: item.id
            })
        })
    }
    return data;
}