import { Popconfirm, Space, Tag, Tooltip } from "antd";
import { Button } from "src/components/actions/button";
import { PencilSvg, TrashSvg } from "src/components/Svg";
import styled from "styled-components";
import {MessageOutlined, MobileOutlined, ProjectOutlined} from "@ant-design/icons";

export function getColumns(onEdit, onDelete, onPush, onGetNotification, onOrderListUpdate) {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 50,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "ascend",
        },
        {
            title: "Имя",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name - b.name,
        },
        {
            title: "Фамилия",
            dataIndex: "surname",
            key: "surname",
            sorter: (a, b) => a.surname - b.surname,
        },
        {
            title: "Телефон",
            dataIndex: "phone",
            key: "phone"
        },
        {
            title: "E-Mail",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email - b.email,
        },
        {
            title: "Дата рождения",
            dataIndex: "birthdayDate",
            key: "birthdayDate",
            sorter: (a, b) => a.birthdayDate - b.birthdayDate,
        },
        {
            title: "Пол",
            dataIndex: "sex",
            key: "sex"
        },
        {
            title: "Никнейм",
            dataIndex: "nickname",
            key: "nickname",
            sorter: (a, b) => a.nickname - b.nickname,
        },
        {
            title: "Процент скидки %",
            dataIndex: "amount",
            key: "amount",
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: "Баллы",
            dataIndex: "bonuses",
            key: "bonuses",
            sorter: (a, b) => a.bonuses - b.bonuses,
        },
        {
            title: "Блокировка",
            dataIndex: "isBlocked",
            key: "isBlocked",
            render: (text) => (
                <StyledTag color={text ? "rgb(211, 47, 47)" : "rgb(56, 142, 60)" }>
                    {text ? "ДА" : "НЕТ"}
                </StyledTag>
            ),
        },
        {
            title: "Дата создания",
            dataIndex: "createdAt",
            key: "createdAt",
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title: "Пользователь удален",
            dataIndex: "deletedAt",
            key: "deletedAt",
            render: (text) => (
                <StyledTag color={text !== null ? "rgb(211, 47, 47)" : "rgb(56, 142, 60)" }>
                    {text ? "ДА" : "НЕТ"}
                </StyledTag>
            ),
        },
        {
            title: "Действие",
            key: "action",
            width: 100,
            fixed: "right",
            render: (_, record) => (
                <Space size="small">
                    {!record.deletedAt && (
                        <>
                            <Tooltip placement="top" title="Отправить PUSH " mouseLeaveDelay={0}>
                                <Button size="small" type="link" onClick={() => onPush(record.id)}>
                                    <MessageOutlined style={{ fontSize: "16px" }} />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title="Список уведомлений" mouseLeaveDelay={0}>
                                <Button size="small" type="link" onClick={() => onGetNotification(record.id)}>
                                    <MobileOutlined style={{ fontSize: "16px" }} />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
                                <Button size="small" type="link" onClick={() => onEdit(record.id)}>
                                    <PencilSvg />
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
                                <Popconfirm
                                    placement="left"
                                    title="Действительно хотите удалить?"
                                    onConfirm={() => onDelete(record.id)}
                                    onCancel={() => stopPropagation}
                                >
                                    <Button size="small" type="link" onClick={stopPropagation}>
                                        <TrashSvg />
                                    </Button>
                                </Popconfirm>
                            </Tooltip>
                            <Tooltip placement="top" title="Обновление истории заказов" mouseLeaveDelay={0}>
                                <Button size="small" type="link" onClick={() => onOrderListUpdate(record.id)}>
                                    <ProjectOutlined style={{ fontSize: "16px" }}/>
                                </Button>
                            </Tooltip>
                        </>

                    )}

                </Space>
            ),
        },
    ];
}

const stopPropagation = (e) => {
    e?.stopPropagation();
};

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`;
