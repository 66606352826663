import React from "react";
import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import styled from "styled-components";
import {notification, Popconfirm, Space, Table, Table as AntTable, Tooltip} from "antd";
import {useGetClients} from "src/pages-lib/clients/model/use-get-clients";
import {useManageItems} from "src/pages-lib/clients/model";
import {getColumns} from "src/pages-lib/clients/table-columns";
import {getFormattedItems} from "src/pages-lib/clients/model/use-manage-items";
import {VisibilityProvider} from "src/components/visibility-provider";
import {EditDrawer} from "src/pages-lib/clients/edit-drawer";
import {Button} from "../components/actions/button";
import {CloseSquareOutlined, MessageOutlined} from "@ant-design/icons";
import {PushDrawer} from "../pages-lib/clients/push-drawer";
import api from "../api";
import {ClientsFilters} from "../pages-lib/clients/filters";
import {GetExpandedRow, getExpandedRow} from "../pages-lib/clients/sub-column";
import {TrashSvg} from "../components/Svg";
import {useGetNotificationClients} from "../pages-lib/clients/model/get-user-notifications";
import {NotificationsDrawer} from "../pages-lib/clients/notifications-drawer";
import {getUserTasks, sendOrderUserUpdate} from "../utils";



export default function Clients() {
    const [createDrawerState, setCreateDrawerState] = React.useState(false);
    const [editDrawerState, setEditDrawerState] = React.useState(false);
    const [pushDrawerState, setPushDrawerState] = React.useState(false);
    const [notificationDrawerState, setNotificationDrawerState] = React.useState(false);
    const [pushAllDrawerState, setPushAllDrawerState] = React.useState(false);
    const [pushUserState, setPushUserState] = React.useState(false);

    // const [filterDrawerState, setFilterDrawerState] = React.useState(false)
    // const [sortableDrawerState, setSortableDrawerState] = React.useState(false)


    const { items: dataItems, refetch: getItems, isLoading, pagination, handleTableChange, handleSearchTable } = useGetClients()
    const { editData, onSaveEdit, onCreate, onDelete, onEditItem, handleClearDelete, handleUserTasksUpdate } = useManageItems(
        dataItems,
        getItems,
        onSuccessEdit
    );

    const TasksEnum = getUserTasks();

    const {
        notificationItems,
        notificationPagination,
        isNotificationLoading,
        getNotificationItems,
        handleNotificationTableChange,
        clearNotificationData,
    } = useGetNotificationClients()

    const headerTitle = useUpdateHeaderTitle({
        items: dataItems,
        defaultTitle: "0 Категорий",
        arrType: ["Клиент", "Клиента", "клиентов"],
    });

    const onOrderListUpdate = (id) => {
        sendOrderUserUpdate(id)
    }

    const onGetNotification = (id) => {
        getNotificationItems(id)
            .then(r => setNotificationDrawerState(true))


    }
    const onPush = (id) => {
        setPushUserState(id)
        setPushDrawerState(true)
    }

    const onSendPush = async (data) => {
        try {
            await api.post("start-mailing", data);
            notification.success({ message: "PUSH отправлен" });
            setPushUserState(false)
            setPushDrawerState(false)
            setPushAllDrawerState(false)
        } catch (error) {
            console.log("Ошибка отправки", error);
            notification.error({ message: "Ошибка отправки" });
            throw error;
        }


    }

    const columns = getColumns(onEditItem, onDelete, onPush, onGetNotification, onOrderListUpdate);

    function onSuccessEdit() {
        setEditDrawerState(!editDrawerState);
    }

    const handleCloseNotifications = () => {
        setNotificationDrawerState(false)
        clearNotificationData()
    }

    return (
      <>
          <Header
              title={headerTitle}
              extra={
                  <ExtraButtons
                      onCreate={() => setCreateDrawerState(!createDrawerState)}
                      // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                      // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                      titles={{ onCreate: "Добавить Клиента" }}
                  />
              }
          />

          {/*<StyledButton color={"#808080"} onClick={() => setPushAllDrawerState(true)} icon={<MessageOutlined style={{ fontSize: "16px"}}/>}>
              {"Отправить PUSH всем" }
          </StyledButton>*/}

          <ClientsFilters handleSearch={handleSearchTable} />


          <StyledTable
              dataSource={getFormattedItems(dataItems)}
              columns={columns}
              pagination={pagination}
              loading={isLoading}
              onChange={handleTableChange}
              expandable={{
                  expandedRowRender: (record) => <GetExpandedRow data={record} clearDelete={handleClearDelete}/>
                }}
          />

          <VisibilityProvider visible={createDrawerState}>
              <EditDrawer
                  onSave={onCreate}
                  onClose={() => setCreateDrawerState(!createDrawerState)}
                  title={"Создание клиента"}
                  taskEnum={null}
                  onSendTasks={false}
              />
          </VisibilityProvider>

          <VisibilityProvider visible={editDrawerState}>
              <EditDrawer
                  onSave={onSaveEdit}
                  onClose={() => setEditDrawerState(!editDrawerState)}
                  initialValues={editData}
                  title={"Редактирование клиента"}
                  taskEnum={TasksEnum}
                  onSendTasks={handleUserTasksUpdate}
              />
          </VisibilityProvider>

          <VisibilityProvider visible={pushDrawerState}>
              <PushDrawer
                  onSave={onSendPush}
                  onClose={() => setPushDrawerState(!pushDrawerState)}
                  title={"Отправить PUSH пользователю"}
                  idUser={pushUserState}
                  allUsers={false}
              />
          </VisibilityProvider>

          <VisibilityProvider visible={pushAllDrawerState}>
              <PushDrawer
                  onSave={onSendPush}
                  onClose={() => setPushAllDrawerState(!pushAllDrawerState)}
                  title={"Отправить PUSH всем"}
                  allUsers={true}
              />
          </VisibilityProvider>

          <VisibilityProvider visible={notificationDrawerState}>
              <NotificationsDrawer
                  onClose={() => setNotificationDrawerState(!notificationDrawerState)}
                  title={"Список уведомлений пользователя"}
                  clearData={handleCloseNotifications}
                  items={notificationItems}
                  pagination={notificationPagination}
                  onChange={handleNotificationTableChange}
                  isLoad={isNotificationLoading}
              />
          </VisibilityProvider>
      </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
  .ant-table-row{
    background: #ffffff;
  }
  max-width: 100%;
  overflow: scroll;
`;

const StyledButton = styled(Button)`
    margin: 10px 0 20px 0 ;
`;



