import React from "react"
import moment from "moment"
import api from "../../../api";
import {notification} from "antd";

const statuses = {
  0: "Ожидание информации от клиента",
  2: "Ожидание информации от исполнителя",
}

export function useManageItems(items, getItems, onSuccessEdit) {
  const [infoData, setInfoData] = React.useState(null)

    async function onCreate(data) {
        try {
            await api.post("create-mailing-notification", data);
            notification.success({ message: "Уведомление добавлено" });
        } catch (error) {
            notification.error({ message: "Ошибка создания" });
            throw error;
        } finally {
            getItems();
        }
    }

    function onEditItem(id) {
        const item = items.find((item) => item.id === id);

        setInfoData(item);
        onSuccessEdit()
    }

  return { onCreate, onEditItem, infoData}
}
