import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks"
import { EditDrawer } from "src/pages-lib/event-categories/edit-drawer"
import {
  useGetCategories,
  useManageItems,
} from "src/pages-lib/event-categories/model"
import { getColumns } from "src/pages-lib/event-categories/table-columns"
import styled from "styled-components"

export default function EventCategories() {
  const [createDrawerState, setCreateDrawerState] = React.useState(false)
  const [editDrawerState, setEditDrawerState] = React.useState(false)

  // const [filterDrawerState, setFilterDrawerState] = React.useState(false)
  // const [sortableDrawerState, setSortableDrawerState] = React.useState(false)

  const {
    items: dataCategories,
    paginationParams,
    refetch: getItems,
  } = useGetCategories()

  const { editData, onSaveEdit, onCreate, onDelete, onEditItem } = useManageItems(
    dataCategories,
    getItems,
    onSuccessEdit
  )

  const headerTitle = useUpdateHeaderTitle({
    items: dataCategories,
    defaultTitle: "0 Категорий",
    arrType: ["Категория", "Категории", "Категорий"],
  })

  const columns = getColumns(onEditItem, onDelete)

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  return (
    <>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            onCreate={() => setCreateDrawerState(true)}
            // onFilter={() => setFilterDrawerState(!filterDrawerState)}
            // onSort={() => setSortableDrawerState(!sortableDrawerState)}
            titles={{ onCreate: "Добавить Категорию" }}
          />
        }
      />
      <StyledTable
        dataSource={dataCategories}
        columns={columns}
        pagination={paginationParams.pagination}
      />

      <VisibilityProvider visible={createDrawerState}>
        <EditDrawer
          onSave={onCreate}
          onClose={() => setCreateDrawerState(false)}
          title={"Создание категории"}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onSave={onSaveEdit}
          onClose={() => setEditDrawerState(false)}
          initialValues={editData}
          title={"Редактирование категории"}
          visible={editDrawerState}
        />
      </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
