import React from "react";
import {
    Col,

    Drawer, Form, Input, Row, Select
} from "antd";
import {useAppData} from "../../store/app-data";
import styled from "styled-components";
import {Button} from "../../components/actions/button";
import {getInitialValues} from "../../utils/helpers";
import {CloseSvg, RotateClockwiseSvg, SaveSvg} from "../../components/Svg";

export function ChangePaymentDrawer({
                            title,
                            onClose,
                            paymentTypesOption,
                            initialValues,
                            onSaveChange,
                            handleSetChangeValue,
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);

    const [form] = Form.useForm()

    React.useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(initialValues)
        }
    }, [initialValues])

    function onSubmit(values) {
        const newForm =  new FormData()

        newForm.append("orderId", initialValues.orderId)
        newForm.append("paymentTypeId", values.paymentTypeId)

        onSaveChange(newForm, initialValues.orderId)
        onClose()
        handleSetChangeValue(false)
    }
    const id = initialValues?.orderId ?? "new"

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={onClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>
                <StyledFormItem
                    label="Тип оплаты"
                    key={id + "_paymentTypeId"}
                    name={"paymentTypeId"}
                >
                    <Select options={paymentTypesOption} placeholder="Тип оплаты" />
                </StyledFormItem>
                <ButtonRow>
                    <ButtonCol>
                        <Button
                            danger
                            onClick={() => {
                                if (initialValues) {
                                    form.setFieldsValue(getInitialValues(initialValues))
                                    return
                                }
                                form.resetFields()
                            }}
                            icon={<RotateClockwiseSvg />}
                        >
                            Вернуть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button onClick={onClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>

        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`
