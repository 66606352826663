import React from "react"
import api from "src/api"
import {getCities, getPaymentTypes, getPointsFeatures} from "src/utils"

export function useGetPoints() {
  const [pagination, setPagination] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [items, setItems] = React.useState([])
  const [pointsFeatures, setPointsFeatures] = React.useState([])
  const [deliveryTypes, setDeliveryTypes] = React.useState([])
  const [paymentTypes, setPaymentTypes] = React.useState([])
  const [paymentAccounts, setPaymentAccounts] = React.useState([])
  const [cityOptions, setCityOptions] = React.useState([])

  async function getItems(page = 1, countOnPage = 20, filters = "") {
    setIsLoading(true)
    try {
      const response = await api.get(`points?page=${page}&countOnPage=${countOnPage}${filters}`)
      setPagination({
        current: response.data.pagination.currentPage,
        pageSize: response.data.pagination.countOnPage,
        total: response.data.pagination.total
      });
      setItems(response.data.items)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    getItems()
    getDeliveryTypes()
    getPaymentTypesOptions()
    getPaymentsAccount()
    getPointsFeaturesOptions()
    getCityOptions()
  }, [])

  async function getPaymentTypesOptions() {
    const data = await getPaymentTypes()

    const options = data.map((item) => ({
      ...item,
      nameRu: item?.name?.find((el) => el.language === "ru").content,
    }))
    setPaymentTypes(options)
  }

  async function getCityOptions() {
    const data = await getCities()

    const options = data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    setCityOptions(options)
  }

  const handleTableChange = (pagination) => {
    getItems(pagination.current, pagination.pageSize )
  };

  async function getPointsFeaturesOptions() {
    const data = await getPointsFeatures()

    const options = data.map((item) => ({
      ...item,
      label: item?.name?.find((el) => el.language === "ru").content,
      value: item.id,
    }))
    setPointsFeatures(options)
  }

  async function getDeliveryTypes() {
    try {
      const { data } = await api.get("delivery-types")
      const options = data.items.map((item) => ({
        ...item,
        nameRu: item?.name?.find((el) => el.language === "ru").content,
      }))
      setDeliveryTypes(options)
    } catch (error) {
      console.error(error)
    }
  }

  async function getPaymentsAccount() {
    try {
      const { data } = await api.get("payment-accounts")
      const options = data.items.map((item) => ({
        ...item,
        label: item?.name?.find((el) => el.language === "ru").content,
        value: item.id,
      }))
      setPaymentAccounts(options)
    } catch (error) {
      console.error(error)
    }
  }

  function handleSearchTable(filters) {
    let filters_str = ""
    let count = 0;
    for (let [key, value] of Object.entries(filters)) {

      if(value) {
        switch (key) {
          case "name":
            filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=name->>'ru'&filters[${count}][v]=${value}`
            break;
          case "short_name":
            filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=short_name->>'ru'&filters[${count}][v]=${value}`
            break;
        }
      }

      count++
    }
    getItems(1,  20, filters_str)
  }

  return {
    items,
    pagination,
    isLoading,
    refetch: () => getItems(),
    options: { deliveryTypes, paymentTypes, paymentAccounts, pointsFeatures, cityOptions },
    handleTableChange,
    handleSearchTable
  }
}

export function getFormattedItems(items) {
  const data = items.map((item) => ({
    key: item.id,
    id: item.id,
    name: item.name,
    nameRu: item.name?.find((el) => el.language === "ru")?.content,
    shortNameRu: item.shortName?.find((el) => el.language === "ru")?.content,
    addressString: item.addressString,
    isPickup: item.isPickup,
    isDelivery: item.isDelivery,
    isActive: item.isActive,
    isShow: item.isShow,
  }))

  return data
}
