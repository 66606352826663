import {useGetDeliverySetting, useManageItems} from "../pages-lib/delivery-setting/model";
import styled from "styled-components";
import {Switch, Table as AntTable} from "antd";
import React from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import {EditDrawer} from "../pages-lib/delivery-setting/edit-drawer";
import {VisibilityProvider} from "../components/visibility-provider";
import {getColumns} from "../pages-lib/delivery-setting/table-columns";
import {getFormattedItems} from "../pages-lib/delivery-setting/model/use-get-points"

export default function DeliverySetting() {

    const [createDrawerState, setCreateDrawerState] = React.useState(false)
    const [editDrawerState, setEditDrawerState] = React.useState(false)

    const {
        systemPoints,
        setSystemItemHandled,
        isLoading,
        dateItems,
        refetch: getDateItems,
        handleTableChange,
        options
    } = useGetDeliverySetting()
    const {
        onChangeSystemPoint,
        onCreate,
        onDelete,
        onEditItem,
        editData,
        onEdit
    } = useManageItems(
        systemPoints,
        setSystemItemHandled,
        getDateItems,
        dateItems,
        onSuccessCreate,
        onSuccessEdit
    )

    function onSuccessCreate() {
        setCreateDrawerState(!createDrawerState)
    }

    function onSuccessEdit() {
        setEditDrawerState(!editDrawerState)
    }

    const columns = getColumns(onDelete, onEditItem)

    return (
        <>
            <Header
                title={"Настройки доставки"}
                extra={
                    <ExtraButtons
                        onCreate={() => setCreateDrawerState(true)}
                        // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                        // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                        titles={{ onCreate: "Блокировать дату" }}
                    />
                }
            />

            <SystemPointsWrap>
                {(systemPoints && systemPoints.length > 0)
                    ? systemPoints.map((item) =>  (
                            <SystemPoint key={`point_${item.key}`}>
                                <strong>Доставка во всех точках г. {item.cityName}:</strong>

                                {item.isLoading ? (
                                    <LoadingOutlined
                                        style={{
                                            color: "#6574cd",
                                            fontSize: "20px"
                                        }}/>
                                ) : (
                                    <Switch
                                        onChange={() => onChangeSystemPoint(!item.isDelivery, item.key)}
                                        style={{ marginLeft: "20px" }}
                                        checked={item.isDelivery}
                                    />
                                )}

                            </SystemPoint>
                        )
                    )
                    : (
                        <LoadingOutlined
                            style={{
                                maxWidth: "40px",
                                color: "#6574cd",
                                fontSize: "40px",
                                marginLeft: "60px"
                            }}/>
                    )}
            </SystemPointsWrap>

            <StyledTable
                dataSource={getFormattedItems(dateItems, options.pointOptions)}
                columns={columns}
                pagination={false}
                loading={isLoading}
                onChange={handleTableChange}
            />

            <VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    onSave={onCreate}
                    onClose={() => setCreateDrawerState(false)}
                    title={"Блокирование даты"}
                    pointOptions={options.pointOptions}
                    initialValues={false}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    onSave={onEdit}
                    onClose={() => setEditDrawerState(false)}
                    title={"Редактирование локирование даты"}
                    pointOptions={options.pointOptions}
                    initialValues={editData}
                />
            </VisibilityProvider>
        </>

    )
}

const SystemPointsWrap = styled.div`
  margin: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const SystemPoint = styled.div`
  font-size: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
`

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`