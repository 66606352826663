import React from "react";
import { notification } from "antd";
import api from "src/api";

export function useManageItems(items, getItems, onSuccessEdit) {
  const [editData, setEditData] = React.useState(null);

  function onEditItem(id) {
    const item = items.find((item) => item.id === id);
    const newItem = {
      ...item,
      image: item.image !== "null" ? item.image : null,
    };
    setEditData(newItem);
    onSuccessEdit();
  }

  async function onDelete(id) {
    try {
      await api.delete(`product-categories/${id}`);
      notification.success({ message: "Категория удалёна" });
    } catch (error) {
      console.log("Ошибка удаления", error);
      notification.error({ message: "Ошибка удаления" });
      throw error;
    } finally {
      getItems();
    }
  }

  async function updateToCrm() {
    try {
      let data = new FormData;
      await api.post("product-categories/update-to-crm", data);
      notification.success({ message: "Категории обновляются" });
    } catch (error) {
      console.log("Ошибка создания", error);
      notification.error({ message: "Ошибка запуска обновлений" });
      throw error;
    }
  }

  async function onCreate(data) {
    try {
      await api.post("product-categories", data);
      notification.success({ message: "Категория добавлена" });
    } catch (error) {
      console.log("Ошибка создания", error);
      notification.error({ message: "Ошибка создания" });
      throw error;
    } finally {
      getItems();
    }
  }

  async function onSaveEdit(values) {
    try {
      await api.put(`product-categories/${editData.id}`, values);

      notification.success({ message: "Категория изменена" });

      onSuccessEdit();
      setEditData(false);
    } catch (error) {
      console.log("Ошибка изменения", error);
      notification.error({ message: "Ошибка изменения" });
      throw error;
    } finally {
      getItems();
    }
  }

  return { editData, onSaveEdit, onCreate, onDelete, onEditItem, updateToCrm };
}
