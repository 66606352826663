export function getProductType() {
    return getTypes();
}

function getTypes() {
    return [
        {
            value: "product",
            label: "Продукт"
        },
        {
            value: "cake",
            label: "Торт"
        },
        {
            value: "certificate",
            label: "Сертификат"
        },
        {
            value: "clothes",
            label: "Одежда"
        },
    ];
}