import React, {useState} from "react"
import {PlusOutlined, UploadOutlined} from "@ant-design/icons"
import {
  Col, DatePicker, Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select, Spin, Switch,
  Tabs,
  Upload,
} from "antd"
import moment from "moment/moment";
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import {
  TranslatableInput
} from "src/components/Translatable"
import { useImage } from "src/hooks/use-image"
import { useAppData } from "src/store/app-data"
import {
  getFormattedLangData,
  getFormData,
  getInitialValues,
  getNestedFormData,
  getOldImage,
} from "src/utils/helpers"
import styled from "styled-components"

import api from "../../api";
import {DebounceSelect} from "../../components/debounce-select";
import {LinkBuilder} from "../../components/link-builder/link-builder";
import {DATE_FORMAT} from "../../config";
import {getProductHints} from "../../utils/getProductHints";

export function EditDrawer({
                             title,
                             initialValues,
                             onClose,
                             onSave,
                             typeOptions,
                             categoryOptions,
                             pointOptions,
                             userStatusOptions
                           }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  const [stateType, setStateType] = React.useState(false)
  const [stateBuyType, setStateBuyType] = React.useState(false)
  const [stateIsAlways, setStateIsAlways] = React.useState(true)
  const [helpActiveTo, setHelpActiveTo] = useState(false)
  const [validateActiveTo, setValidateActiveTo] = useState(false)
  const [options, setOptions] = React.useState([])
  const fetchRef = React.useRef(0)
  const [fetching, setFetching] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isForAll, setIsForAll] = React.useState(true)
  const [csvFileList, setCsvFileList] = React.useState([])

  const { getImage, imageUpdated, onPreview, onChange, preview } = useImage()

  React.useEffect(() => {
    if (initialValues) {
      (initialValues.startAt !== null || initialValues.endAt !== null) ? setStateIsAlways(false) : setStateIsAlways(true)
      const slugType = typeOptions.find((el) => el.value === initialValues.typeId).slug
      setStateType(slugType)
      if(!initialValues.isForAll) {
        setIsForAll(initialValues.isForAll)
      }
      if(slugType === "kupit") {
        initialValues.productIds.length > 0 ? setStateBuyType("product") : setStateBuyType("category")
      }
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  const [form] = Form.useForm()

  function prepareData(values) {
    const file = getImage(values.image)
    const id = initialValues?.id
    const isNeedImage = !id || (id && imageUpdated)

    let csv = false;

    if (values.file) {
      csv = getImage(values.file)
    }

    const data = {
      ...(id && { id, _method: "PUT" }),
      imageUpdated: id ? (imageUpdated ? 1 : 0) : 1,
      typeId: values.typeId,
      isOnCashBox: values.isOnCashBox ? 1 : 0,
      isOnApp: values.isOnApp ? 1 : 0,
      isOnDelivery: values.isOnDelivery ? 1 : 0,
      isOnPickup: values.isOnPickup ? 1 : 0,
      isActive: values.isActive ? 1 : 0,
      isForAll: isForAll ? 1 : 0,
      count: values.count,
      ...(values.startAt && { startAt: moment(values.startAt).toISOString(true) }),
      ...(values.endAt && { endAt: moment(values.endAt).toISOString(true) }),
      ...(values.productCategoryId && { productCategoryId: values.productCategoryId }),
      ...(values.pointId && { pointId: values.pointId }),
      ...(isNeedImage && { image: file }),
      ...(values.link && {link: values.link}),
      ...(csv && {file: csv})
    }

    return data
  }

  async function onSubmit(values) {
    const baseData = prepareData(values)
    const formData = getFormData({
      values: baseData,
      image: baseData.imageUpdated && baseData.image,
    })

    values.rewardIds = values.rewardIds.map((item) => item.value)
    if(values.productIds) {
      values.productIds = values.productIds.map((item) => item.value)
    }


    if(!values.linkText?.ru && !values.linkText?.en) {
      delete values.linkText
    }

    const data = getFormattedLangData(values)

    const dataWithNestedItem = {
      name: data.name,
      description: data.description,
      tag: data.tag,
      rewardIds: data.rewardIds,
      ...(values.linkText && {linkText: data.linkText}),
      ...(values.productIds && {productIds: data.productIds}),
      ...(values.pointIds && { pointIds: data.pointIds }),
      ...(values.levels && { levels: data.levels }),
    }

    const formDataWithNestedFormData = getNestedFormData(
        dataWithNestedItem,
        formData
    )

    await onSave(formDataWithNestedFormData, initialValues?.id)
    onClose()
    form.resetFields()
  }

  const loadRewardOptions = (value) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setOptions([])
    setFetching(true)
    const rewards = api.get(`loyalty-rewards-hints?query=${value}`)

    rewards.then((newOptions) => {
      const result = []
      newOptions.data.items.forEach((reward) => {
        result.push({
          label: reward.name.find((el) => el.language === "ru").content,
          value: reward.id
        })
      })
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }
      setOptions(result)
      setFetching(false)
    })
  }

  function handleChangeIsLimited(e) {
    setIsForAll(e)
  }

  function handleClose() {
    onClose()
    setStateType(false)
    setStateBuyType(false)
    setStateIsAlways(true)
    form.resetFields()
  }

  function onChangeActiveTo(value) {

    if (form.getFieldValue("startAt")) {
      if (form.getFieldValue("startAt") > value) {
        setValidateActiveTo("error")
        setHelpActiveTo("Дата ДО не может быть меньше")
        form.setFieldValue("endAt", null)
      } else {
        setValidateActiveTo(false)
        setHelpActiveTo(false)
      }
    } else {
      setValidateActiveTo("error")
      setHelpActiveTo("Сначала выберите дату ОТ")
      form.setFieldValue("endAt", null)
    }
  }

  function handleSelectTaskType(type) {
    const slugType = typeOptions.find((el) => el.value === type).slug
    setStateType(slugType)
    setStateBuyType(false)
  }

  function selectAllPoints() {
    let all = [];
    pointOptions.forEach((item) => {
      all.push(item.value)
    })
    form.setFieldValue("pointIds", all)
  }

  function handleSelectBuyType(type) {
    setStateBuyType(type.target.value)
  }

  function handleSetValue(value) {
    form.setFieldValue("link", value)
  }

  function handleChangeIsAlways(e) {
    setStateIsAlways(e.target.value)
  }

  const id = initialValues?.id ?? "new"

  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "80%"}
          title={title}
          onClose={handleClose}
          open
      >
        <FormEdit
            form={form}
            layout={"vertical"}
            onFinish={(values) => onSubmit(values)}
        >
          <Divider orientation="left">Картинка</Divider>
          <StyledFormItem
              name={"image"}
              label={"Обложка"}
              rules={[
                {
                  required: true,
                  message: "Выберите изображение!",
                },
              ]}
          >
            <Upload
                listType="picture-card"
                maxCount={1}
                onPreview={onPreview}
                accept="image/png"
                status="done"
                beforeUpload={() => false}
                onChange={onChange}
                defaultFileList={getOldImage(initialValues?.image)}
                dependencies={"image"}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Загрузить изображение</div>
              </div>
            </Upload>
          </StyledFormItem>

          <StyledImage
              alt={preview.previewImage?.name || "Превью"}
              preview={{
                visible: preview.visible,
                onVisibleChange: preview.onVisibleChange,
              }}
              src={preview.previewImage?.largePreview || preview.previewImage?.url}
          />

          <Divider orientation="left">Описание</Divider>

          <StyledFormItem label={"Название"}>
            <TranslatableInput name={"name"} id={id}></TranslatableInput>
          </StyledFormItem>

          <StyledFormItem label={"Описание"}>
            <TranslatableInput name={"description"} id={id}></TranslatableInput>
          </StyledFormItem>

          <StyledFormItem label={"Тэг"}>
            <TranslatableInput name={"tag"} id={id}></TranslatableInput>
          </StyledFormItem>

          <Divider orientation="left">Задание</Divider>

          <StyledFormItem
              key={id + "_typeId"}
              label={"Тип задания"}
              name={"typeId"}
              rules={[
                {
                  required: true,
                  message: "Выберите тип!",
                },
              ]}
          >
            <Select options={typeOptions} onSelect={handleSelectTaskType} />
          </StyledFormItem>

          {(stateType && stateType === "kupit") && (
              <>
                <Row
                    gutter={24}
                    style={{ marginBottom: "16px" }}
                    key={"dashboardKupit"}
                >
                  <Col className="gutter-row" key={"isOnCashBox"} span={6}>
                    <StyledFormItem
                        key={id + "_isOnCashBox"}
                        label={"На кассе"}
                        name={"isOnCashBox"}
                        initialValue={form.isOnCashBox ? form.isOnCashBox : true}
                        rules={[
                          {
                            required: true,
                            message: "Выберите вариант",
                          },
                        ]}
                    >
                      <Radio.Group
                          buttonStyle="solid"
                          value={form.isOnCashBox ? form.isOnCashBox : true}
                      >
                        <Radio.Button value={true}>Да</Radio.Button>
                        <Radio.Button value={false}>Нет</Radio.Button>
                      </Radio.Group>
                    </StyledFormItem>
                  </Col>
                  <Col className="gutter-row" key={"isOnApp"} span={6}>
                    <StyledFormItem
                        key={id + "_isOnApp"}
                        label={"В МП"}
                        name={"isOnApp"}
                        initialValue={form.isOnApp ? form.isOnApp : true}
                        rules={[
                          {
                            required: true,
                            message: "Выберите вариант",
                          },
                        ]}
                    >
                      <Radio.Group
                          buttonStyle="solid"
                          value={form.isOnApp ? form.isOnApp : true}
                      >
                        <Radio.Button value={true}>Да</Radio.Button>
                        <Radio.Button value={false}>Нет</Radio.Button>
                      </Radio.Group>
                    </StyledFormItem>
                  </Col>
                  <Col className="gutter-row" key={"isOnDelivery"} span={6}>
                    <StyledFormItem
                        key={id + "_isOnDelivery"}
                        label={"На доставку"}
                        name={"isOnDelivery"}
                        initialValue={form.isOnDelivery ? form.isOnDelivery : true}
                        rules={[
                          {
                            required: true,
                            message: "Выберите вариант",
                          },
                        ]}
                    >
                      <Radio.Group
                          buttonStyle="solid"
                          value={form.isOnDelivery ? form.isOnDelivery : true}
                      >
                        <Radio.Button value={true}>Да</Radio.Button>
                        <Radio.Button value={false}>Нет</Radio.Button>
                      </Radio.Group>
                    </StyledFormItem>
                  </Col>
                  <Col className="gutter-row" key={"isOnPickup"} span={6}>
                    <StyledFormItem
                        key={id + "_isOnPickup"}
                        label={"На самовывоз"}
                        name={"isOnPickup"}
                        initialValue={form.isOnPickup ? form.isOnPickup : true}
                        rules={[
                          {
                            required: true,
                            message: "Выберите вариант",
                          },
                        ]}
                    >
                      <Radio.Group
                          buttonStyle="solid"
                          value={form.isOnPickup ? form.isOnPickup : true}
                      >
                        <Radio.Button value={true}>Да</Radio.Button>
                        <Radio.Button value={false}>Нет</Radio.Button>
                      </Radio.Group>
                    </StyledFormItem>
                  </Col>
                </Row>

                <StyledFormItem
                    key={id + "_buyType"}
                    label={"Что купить"}
                    rules={[
                      {
                        message: "Выберите что купить!",
                        required: true
                      }
                    ]}
                    name={"buyType"}
                    initialValue={stateBuyType}
                >
                  <Radio.Group
                      onChange={handleSelectBuyType}
                  >
                    <Radio value={"product"}>Продукты</Radio>
                    <Radio value={"category"}>Из категория</Radio>
                  </Radio.Group>
                </StyledFormItem>

                {(stateBuyType && stateBuyType === "product") && (
                    <StyledFormItem
                        key={id + "_productIds"}
                        label={"Товар"}
                        name={"productIds"}
                        rules={[
                          {
                            message: "Выберите продукт!",
                            required: true
                          }
                        ]}
                    >
                      <DebounceSelect
                          mode="multiple"
                          showSearch={true}
                          value={""}
                          placeholder="Выберите продукт"
                          fetchOptions={getProductHints}
                      />
                    </StyledFormItem>
                )}

                {(stateBuyType && stateBuyType === "category") && (
                    <StyledFormItem
                        key={id + "_productCategoryId"}
                        label={"Категория"}
                        name={"productCategoryId"}
                        rules={[
                          {
                            message: "Выберите категорию!",
                            required: true
                          }
                        ]}
                    >
                      <Select options={categoryOptions} placeholder={"Выберите категорию"} disabled={false} />
                    </StyledFormItem>
                )}

              </>
          )}

          {(stateType && stateType === "posetit") && (
              <>
                <StyledFormItem
                    key={id + "_pointIds"}
                    label={"Точки"}
                    name={"pointIds"}
                    rules={[
                      {
                        message: "Выберите точки!",
                        required: true
                      }
                    ]}
                >
                  <Select
                      mode={"multiple"}
                      options={pointOptions}
                      placeholder={"Выберите категорию"} disabled={false}
                  />
                </StyledFormItem>
                <Button
                    type="dashed"
                    onClick={selectAllPoints}
                    style={{ marginBottom: "20px" }}
                >
                  выбрать все точки
                </Button>
              </>

          )}

          {stateType && (
              <StyledFormItem
                  key={id + "_count"}
                  label={"Количество"}
                  name={"count"}
                  rules={[
                    {
                      message: "Введите количество",
                      required: true
                    }
                  ]}
              >
                <InputNumber
                    min={0}
                    style={{
                      width: "100%",
                    }}
                />
              </StyledFormItem>
          )}

          <Divider orientation="left">Настройки</Divider>

          <StyledFormItem
              key={id + "_isActive"}
              label={"Включен"}
              name={"isActive"}
              initialValue={form.isActive ? form.isActive : true}
              rules={[
                {
                  required: true,
                  message: "Выберите вариант",
                },
              ]}
          >
            <Radio.Group
                buttonStyle="solid"
                value={form.isActive ? form.isActive : true}
            >
              <Radio.Button value={true}>Да</Radio.Button>
              <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
          </StyledFormItem>

          <StyledFormItem
              key={id + "_isAlways"}
              label={"Всегда работает"}
              name={"isAlways"}
              initialValue={stateIsAlways}
              rules={[
                {
                  required: true,
                  message: "Please select!",
                },
              ]}
          >
            <Radio.Group
                buttonStyle="solid"
                value={stateIsAlways}
                onChange={handleChangeIsAlways}
            >
              <Radio.Button value={true}>Да</Radio.Button>
              <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
          </StyledFormItem>

          {!stateIsAlways && (
              <>
                <StyledFormItem
                    key={id + "_startAt"}
                    label={"Активность (от)"}
                    name={"startAt"}s
                    rules={[
                      {
                        required: true,
                        message: "Выберите дату",
                      },
                    ]}
                >
                  <DatePicker
                      style={{
                        width: "100%",
                      }}
                      format={DATE_FORMAT}
                      placeholder={"Выберите дату"}
                  />
                </StyledFormItem>
                <StyledFormItem
                    key={id + "endAt"}
                    label={"Активность (до)"}
                    help={helpActiveTo}
                    name={"endAt"}
                    validateStatus={validateActiveTo}
                    rules={[
                      {
                        required: true,
                        message: "Выберите дату",
                      },
                    ]}
                >
                  <DatePicker
                      onChange={onChangeActiveTo}
                      style={{
                        width: "100%",
                      }}
                      format={DATE_FORMAT}
                      placeholder={"Выберите дату"}
                  />
                </StyledFormItem>
              </>
          )}

          <StyledFormItem
              label={"Ccылка"}
              key={id + "_link"}
              name={"link"}
          >
            <LinkBuilder
                value={initialValues?.url ?? null}
                placeholder={"Ссылка"}
                onSetValue={handleSetValue}
            />
          </StyledFormItem>

          <StyledFormItem label={"Текст для ссылки"}>
            <TranslatableInput name={"linkText"} id={id} required={false}></TranslatableInput>
          </StyledFormItem>

          <Divider orientation="left">Ограничение пользователей</Divider>


          <StyledFormItem
              label={"Задание для всех"}
              key={id + "_isForAll"}
              name={"isForAll"}
          >
            <Switch
                onChange={handleChangeIsLimited}
                checked={isForAll}
                disabled={id !== "new"}
            />
          </StyledFormItem>

          {!initialValues && !isForAll && (
              <StyledFormItem
                  name={"file"}
                  label={"Файл"}
                  key={id + "_file"}
              >
                <Upload
                    accept={".csv"}
                    beforeUpload={() => false}
                    maxCount={1}
                >
                  <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
                </Upload>
              </StyledFormItem>
          )}

          {isForAll && (
              <StyledFormItem
                  key={id + "_levels"}
                  label={"Уровень(и)"}
                  name={"levels"}
                  rules={[
                    {
                      message: "Выберите уровни!",
                      required: true
                    }
                  ]}
              >
                <Select mode={"multiple"} options={userStatusOptions} placeholder={"Выберите уровень"} disabled={false} />
              </StyledFormItem>
          )}

          <Divider orientation="left">Награды</Divider>
          <StyledFormItem
              label={"Награды"}
              key={id + "_rewardIds"}
              name={"rewardIds"}
              rules={[
                {
                  message: "Выберите награды!",
                  required: true
                }
              ]}
          >
            <Select
                labelInValue
                mode="multiple"
                showSearch={true}
                filterOption={false}
                onSearch={loadRewardOptions}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={options}
            />
          </StyledFormItem>

          <ButtonRow>
            <ButtonCol>
              <Button
                  danger
                  onClick={() => {
                    if (initialValues) {
                      form.resetFields(["image"])
                      form.setFieldsValue(getInitialValues(initialValues))
                      return
                    }
                    form.resetFields()
                  }}
                  icon={<RotateClockwiseSvg />}
              >
                Вернуть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button onClick={handleClose} icon={<CloseSvg />}>
                Закрыть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                Сохранить
              </Button>
            </ButtonCol>
          </ButtonRow>
        </FormEdit>
      </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledTabs = styled(Tabs)`
  overflow: visible;

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: none;
    user-select: none;
  }

  &.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 3px 7px 6px;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    bottom: -3px;
    margin-bottom: 0;

    &::before {
      border-bottom: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

const StyledImage = styled(Image)`
  display: none;
`
