import React from "react";
import { Table as AntTable } from "antd";
import { useGetProducts, useManageItems } from "src/pages-lib/products/model";
import { ExtraButtons } from "src/components/extra-buttons";
import { useUpdateHeaderTitle } from "src/hooks/use-update-header-title";
import { Header } from "src/components/layout/header";
import { EditDrawer } from "src/pages-lib/products/edit-drawer"
import { VisibilityProvider } from "src/components/visibility-provider"
import { getFormattedItems } from "src/pages-lib/products/model/use-get-products"
import { getColumns } from "src/pages-lib/products/table-columns"
import styled from "styled-components"

export default function Products() {
    const [editDrawerState, setEditDrawerState] = React.useState(false)
    const [createDrawerState, setCreateDrawerState] = React.useState(false)

    const { items, refetch: getItems, isLoading, pagination, options, handleTableChange } = useGetProducts()

    const { editData, onSaveEdit, onEditItem, onCreate } = useManageItems(
        items,
        getItems,
        onSuccessEdit
    )

    const columns = getColumns(onEditItem)

    const headerTitle = useUpdateHeaderTitle({
        items,
        defaultTitle: "0 Товаров",
        arrType: ["Товаров", "Товара", "Товаров"],
    })



    function onSuccessEdit() {
        setEditDrawerState(!editDrawerState)
    }

    return (
        <>
            <Header title={headerTitle} extra={<ExtraButtons
                onCreate={() => setCreateDrawerState(true)}
                // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                titles={{ onCreate: "Добавить товар" }} />}
            />

            <StyledTable
                dataSource={getFormattedItems(items)}
                columns={columns}
                pagination={pagination}
                loading={isLoading}
                onChange={handleTableChange}
            />

            <VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    onSave={onCreate}
                    onClose={() => setCreateDrawerState(false)}
                    title={"Создание продукта"}
                    typesOptions={options.productTypesOptions}
                    categoryOptions={options.categoryOptions}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    onSave={onSaveEdit}
                    onClose={() => setEditDrawerState(false)}
                    title={"Редактирование продукта"}
                    initialValues={editData}
                    typesOptions={options.productTypesOptions}
                    categoryOptions={options.categoryOptions}
                />
            </VisibilityProvider>
        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
  max-width: 100%;
  overflow: hidden;
`
