import React from "react"
import api from "src/api"
import {getPoints} from "../../../utils";
import moment from "moment/moment";

export function useGetDeliverySetting() {

  const [systemPoints, setSystemItems] = React.useState([])
  const [pointOptions, setPointOptions] = React.useState([])
  const [dateItems, setDateItems] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  async function getSystemPoints() {
    try {
      const { data } = await api.get("points/system-point")
      let newItems = data.points.reduce(
          (items, item) => [
            ...items,
            {
              key: item.cityId,
              isLoading: false,
              cityName: item.city.name,
              isDelivery: item.isDelivery,
            },
          ],
          []
      );

      setSystemItems(newItems)
    } catch (error) {
      throw error
    }
  }

  async function getDateItems(page = 1, countOnPage = 20) {

    setIsLoading(true)
    try {
      const { data } = await api.get(`blocked-date?page=${page}&countOnPage=${countOnPage}`)
      setDateItems(data.items)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  async function getPointsOptions() {
    const data = await getPoints()

    const options = data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    setPointOptions(options)
  }

  function handleTableChange(pagination) {
    getDateItems(pagination.current, pagination.pageSize)
  }

  function setSystemItemHandled(array) {
    setSystemItems(array)
  }


  React.useEffect(() => {
    getPointsOptions()
    getSystemPoints()
    getDateItems()


  }, [])

  return {
    systemPoints,
    setSystemItemHandled,
    options: { pointOptions },
    isLoading,
    dateItems,
    refetch: () => getDateItems(),
    handleTableChange
  }
}

export function getFormattedItems(items, pointOptions) {
  let newItems = [];
  if(items.length > 0 && pointOptions.length > 0) {
    newItems = items.map((item) => {
      const pointsNames = []
      if(items.length)
        item.pointIds.forEach((id) => {
          pointsNames.push(pointOptions.find((el) => el.value === id).label)
        })
      return {
        key: item.id,
        id: item.id,
        date: moment(item.date).format("DD.MM.YYYY"),
        points: pointsNames.join(", "),
      }
    })
  }


  return newItems
}


