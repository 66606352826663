import { Button as AntButton } from "antd"
import styled, { css } from "styled-components"

export function Button(props) {
  const { color, children, danger, withSpace = false, ...buttonProps } = props

  return (
    <AntStyledButton
      $danger={danger}
      $withSpace={withSpace}
      $color={color}
      {...buttonProps}
    >
      {children}
    </AntStyledButton>
  )
}

const AntStyledButton = styled(AntButton)`
  & > .anticon {
    line-height: 0;
  }

  display: inline-flex;
  align-items: center;
  margin: ${({ $withSpace }) => ($withSpace ? "0.4em" : "0")};
  margin-left: 0;

  ${({ $danger }) =>
    $danger &&
    css`
      color: ${({ type }) =>
        type === "primary" ? "#fff" : "rgb(246, 47, 77, 0.85)"};
      border-color: rgb(246, 47, 77, 0.85);
      background: ${({ type }) =>
        type === "primary" ? "rgb(246, 47, 77, 0.85)" : "0 0"};

      &:hover,
      &:focus {
        color: ${({ type }) => (type === "primary" ? "#fff" : "#f86077")};
        border-color: #f86077;
        background: ${({ type }) => (type === "primary" ? "#f86077" : "0 0")};
      }
    `}

  ${({ $color }) =>
    $color &&
    css`
      color: $color;
    `};
`
