import React from "react";
import {DatePicker, Form, Input} from "antd";
import styled from "styled-components";
import {RotateClockwiseSvg} from "../../components/Svg";
import {Button} from "../../components/actions/button";
import {SearchOutlined} from "@ant-design/icons";

export function CertificateFilters({handleSearch}) {

    const [form] = Form.useForm()

    function onSearch(values){
        console.log(values)
        let data = {
            certificateCode: values.certificateCode?.trim() ?? null,
            email: values.email?.trim() ?? null,
            dateFrom: values.dateFrom ? values.dateFrom.toISOString() : null,
            dateTo: values.dateTo ? values.dateTo.toISOString() : null,
        }
        handleSearch(data)
    }

    function onClear() {
        form.resetFields()
        handleSearch([])
    }

    return (
        <>
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSearch(values)}>
                <StyledFormItem
                    key={ "sort_certificate_code" }
                    label={"По коду сертификата"}
                    name={"certificateCode"}
                >
                    <Input placeholder="Код сертификата" />
                </StyledFormItem>

                <StyledFormItem
                    key={ "sort_email" }
                    label={"По email для отправки"}
                    name={"email"}
                >
                    <Input placeholder="Email для отправки"/>
                </StyledFormItem>

                <StyledFormItem
                    key={ "sort_date_from" }
                    label={"Заказан (от)"}
                    name={"dateFrom"}
                >
                    <DatePicker />
                </StyledFormItem>

                <StyledFormItem
                    key={"sort_date_to"}
                    label={"Заказан (до)"}
                    name={"dateTo"}
                >
                    <DatePicker />
                </StyledFormItem>
                <Button
                    danger
                    onClick={onClear}
                    icon={<RotateClockwiseSvg />}
                >
                    Сбросить
                </Button>
                <Button icon={<SearchOutlined />}  type="primary" htmlType="submit">
                    Искать
                </Button>
            </FormEdit>
        </>
    )
}

const FormEdit = styled(Form)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`

const StyledFormItem = styled(Form.Item)`
  margin-right: 25px;
`