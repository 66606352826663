import Icon from "@ant-design/icons"

function SvgIcon({ stroke = "currentColor", strokeWidth = 1.5, size = 20 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-news"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      stroke={stroke}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11" />
      <line x1="8" y1="8" x2="12" y2="8" />
      <line x1="8" y1="12" x2="12" y2="12" />
      <line x1="8" y1="16" x2="12" y2="16" />
    </svg>
  )
}

export function NewsSvg(props) {
  return <Icon component={SvgIcon} {...props} />
}
