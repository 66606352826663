import React from "react"
import Icon from "@ant-design/icons"

function SvgIcon({ stroke = "currentColor", strokeWidth = 1.5, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      stroke={stroke}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M4.05 11a8 8 0 1 1 .5 4m-.5 5v-5h5" />
    </svg>
  )
}

export function RotateClockwiseSvg(props) {
  return <Icon component={SvgIcon} {...props} />
}
