import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import React from "react";
import {useGetData, useManageItems} from "../pages-lib/user-statuses/model";
import styled from "styled-components";
import { Table as AntTable } from "antd";
import {getColumns} from "../pages-lib/user-statuses/table-columns";
import {getFormattedItems} from "../pages-lib/user-statuses/model/use-get-data";
import {VisibilityProvider} from "../components/visibility-provider";
import {EditDrawer} from "../pages-lib/user-statuses/edit-drawer";

export default function UserStatuses() {

    const [editDrawerState, setEditDrawerState] = React.useState(false)

    const { dataItems, isLoading, refetch: getItems } = useGetData();
    const { editData, onEditItem, onSaveEdit } = useManageItems(dataItems, getItems, onSuccessEdit);

    const headerTitle = useUpdateHeaderTitle({
        items: dataItems,
        defaultTitle: "0 Статусов",
        arrType: ["Статус", "Статуса", "Статусов"],
    });

    function onSuccessEdit() {
        setEditDrawerState(!editDrawerState)
    }

    const columns = getColumns(onEditItem)

    return (
        <>
            <Header title={headerTitle} extra={<ExtraButtons />} />

            <StyledTable
                dataSource={getFormattedItems(dataItems)}
                columns={columns}
                loading={isLoading}
                pagination={false}
            />

            <VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    title={"Редактировать к статус"}
                    initialValues={editData}
                    onClose={() => setEditDrawerState(false)}
                    onSave={onSaveEdit}
                />
            </VisibilityProvider>
        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`