import React from "react"
import { Select } from "antd"


export function SearchBox({handleSearch, onChange, options, ...props}) {


    return (
        <>
            <Select
                showSearch
                placeholder={props.placeholder}
                defaultActiveFirstOption={false}
                onSearch={handleSearch}
                options={options}
                showArrow={false}
                filterOption={false}
                value={props.value}
                onChange={onChange}
            />
        </>
    );
}