import React from "react"
import { notification } from "antd"
import api from "src/api"

export function useManageItems(items, getItems, onSuccessEdit, onSuccessCreate) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id)
    const data = formmatedEditData(item)
    setEditData(data)
    onSuccessEdit()
  }

  async function onCreate(values) {
    try {
      await api.post("loyalty-faq", values)

      notification.success({ message: "Блок добавлен" })
      onSuccessCreate()
      getItems()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }

  async function onSaveEdit(values, id) {
    try {
      await api.post(`loyalty-faq/${id}`, values)

      notification.success({ message: "Блок изменен" })
      getItems()
      resetData()
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  async function onDelete(id) {
    try {
      await api.delete(`loyalty-faq/${id}`)

      notification.success({ message: "Блок удален" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  function resetData() {
    setEditData(null)
  }

  function formmatedEditData(item) {
    console.log(item.linkText)
    return {
      ...item,
      loyaltyFaqTypeId: item.type.id
    }
  }

  return { editData, resetData, onSaveEdit, onCreate, onDelete, onEditItem }
}
