import React from "react"
import { getWordDeclension } from "src/utils/helpers"

export function useUpdateHeaderTitle({ items, defaultTitle, arrType }) {
  const [headerTitle, setHeaderTitle] = React.useState(defaultTitle)

  function updateTitle(count) {
    setHeaderTitle(getWordDeclension(count, arrType))
  }

  React.useEffect(() => {
    updateTitle(items?.length ?? 0)
  }, [items, arrType])

  return headerTitle
}
