import { Popconfirm, Space, Tag, Tooltip } from "antd"
import moment from "moment"
import { Button } from "src/components/actions/button"
import { PencilSvg, TrashSvg } from "src/components/Svg"
import styled from "styled-components"

export function getColumns(onEdit, onDelete) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
    },
    {
      title: "Название",
      dataIndex: "promocode",
      key: "promocode",
      sorter: (a, b) => a.promocode - b.promocode,
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description - b.description,
    },
    {
      title: "Минимальная сумма корзины",
      dataIndex: "minPrice",
      key: "minPrice",
    },
    {
      title: "Код",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      render: (type) => <p>{type.name}</p>,
    },
    {
      title: "Скидка",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <StyledTag color="default">{text}</StyledTag>,
    },
    {
      title: "Включен",
      dataIndex: "isActive",
      key: "isActive",
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },
    {
      title: "Показывать на сайте",
      dataIndex: "isPublic",
      key: "isPublic",
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },
    {
      title: "Активен от",
      dataIndex: "activeFrom",
      key: "activeFrom",
      sorter: (a, b) => a.activeFrom - b.activeFrom,
      render: (activeFrom) => moment(activeFrom).format("DD.MM.YYYY"),
    },
    {
      title: "Активен до",
      dataIndex: "activeTo",
      key: "activeTo",
      sorter: (a, b) => a.activeTo - b.activeTo,
      render: (activeTo) => moment(activeTo).format("DD.MM.YYYY"),
    },
    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
            <Popconfirm
              placement="left"
              title="Действительно хотите удалить?"
              onConfirm={() => onDelete(record.id)}
              onCancel={() => stopPropagation}
            >
              <Button size="small" type="link" onClick={stopPropagation}>
                <TrashSvg />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]
}

const stopPropagation = (e) => {
  e?.stopPropagation()
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`
