import api from "src/api/index";
import {STATE_MEDIA} from "src/config";

export async function getProductGroups(){
  try {
    const response =  await api.get(STATE_MEDIA + "api/dashboard/group-product-categories")
    return response.data?.items ?? []
  } catch (error) {
    console.error("ошибка загрузки групп", error?.message)
  }
}
