import React from "react"
import api from "src/api"

export function useGetLiveWorkItems() {
  const [pagination, setPagination] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [items, setItems] = React.useState([])

  async function getItems(page = 1, countOnPage = 20) {
    setIsLoading(true)
    try {
      const response = await api.get(`live-love-work?page=${page}&countOnPage=${countOnPage}`)
      setPagination({
        current: response.data.pagination.currentPage,
        pageSize: response.data.pagination.countOnPage,
        total: response.data.pagination.total,
      })
      setItems(response.data.items)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }
  function handleTableChange(pagination) {
    getItems(pagination.current, pagination.pageSize)
  }

  React.useEffect(() => {
    getItems()
  }, [])

  return { items, pagination, isLoading, handleTableChange,  refetch: () => getItems() }
}