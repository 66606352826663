import React from "react"
import { notification } from "antd"
import api from "src/api"
import { getOldImage } from "src/utils/helpers"

export function useManageItems(items, getItems, onSuccessEdit) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id)
    const formattedItem = formattedEditData(item)
    setEditData(formattedItem)
    onSuccessEdit()
  }

  async function onSaveEdit(data, id) {
    try {
      await api.post(`user-statuses/${id}`, data)

      notification.success({ message: "Cтатус изменен" })
      onSuccessEdit()
      setEditData(false)
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    } finally {
      getItems()
    }
  }

  function clearEditData() {
    setEditData(null)
  }

  function formattedEditData(item) {
    const rewards = item.rewards.map((element) => ({
      label: element.name.find((el) => el.language === "ru").content,
      value: element.id,
    }))
    const newItem = {
      key: item.id,
      id: item.id,
      image: item.image,
      ruName: item.name.find((el) => el.language === "ru").content,
      rewardIds: rewards
    }
    return newItem
  }


  return { editData, onEditItem, onSaveEdit }
}
