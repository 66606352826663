import React from "react"
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons"
import {
  Col, Divider,
  Drawer,
  Form,
  Image,
  Input, InputNumber,
  Radio,
  Row,
  Select,
  Tabs,
  Upload,
} from "antd"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import {
  TranslatableInput,
  TranslatableTextarea,
  TranslatableWysiwyg,
} from "src/components/Translatable"
import { useImage } from "src/hooks/use-image"
import { useAppData } from "src/store/app-data"
import {
  getFormattedLangData,
  getFormData,
  getInitialValues,
  getNestedFormData,
  getOldImage, whiteSpaceValidation,
} from "src/utils/helpers"
import styled from "styled-components"
import {LinkBuilder} from "../../components/link-builder/link-builder";

export function EditDrawer({
                             title,
                             initialValues,
                             onClose,
                             onSave
                           }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)


  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  const [form] = Form.useForm()

  function prepareData(values) {
    const id = initialValues?.id

    const data = {
      ...(id && { id, _method: "PUT" }),
      settings: values.settings
    }

    return data
  }

  async function onSubmit(values) {
    if (values.settings) {
      let data = {};
      values.settings.forEach((item) => {
        let key = item["key"]
        data[key] = item["value"]
      })
      values.settings = data
    }
    const baseData = prepareData(values)


    await onSave(baseData, initialValues?.id)
    onClose()
    form.resetFields()
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }

  const id = initialValues?.id ?? "new"

  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "80%"}
          title={title}
          onClose={handleClose}
          open
      >
        <FormEdit
            form={form}
            layout={"vertical"}
            onFinish={(values) => onSubmit(values)}
        >

          <StyledFormItem
              label={"Название"}
              key={id + "_name"}
              name={"name"}
          >
            <Input disabled={true}/>
          </StyledFormItem>

          <StyledFormItem
              label={"Ситемное название"}
              key={id + "_slug"}
              name={"slug"}
          >
            <Input disabled={true}/>
          </StyledFormItem>

          <StyledFormItem
              key={id + "_number"}
              label={"Номер группы"}
              name={"number"}
          >
            <InputNumber
                disabled={true}
                style={{
                  width: "100%",
                }}
            />
          </StyledFormItem>

          <Divider orientation="left">Настройки группы</Divider>

          <Form.List name="settings">
            {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...field }, index) => (
                      <Form.Item required={false} key={key}>
                        <Button
                            danger
                            style={{
                              marginLeft: "12px",
                              position: "absolute",
                              right: 0,
                              top: 0,
                              zIndex: 40
                            }}
                            onClick={() => {
                              remove(name)
                            }}
                        >
                          Удалить
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                          />
                        </Button>

                        <RowFlex>
                          <StyledFormItem
                              {...field}
                              key={id + "_key"}
                              label={"Ключ"}
                              name={[name, "key"]}
                              style={{ width: "100%" }}
                              rules={[
                                (formInstance) => ({
                                  message: "Введите название!",
                                  required:
                                  formInstance.getFieldValue("additionalData")?.[key]?.key,
                                }),
                                {
                                  validator: whiteSpaceValidation,
                                },
                              ]}
                          >
                            <Input style={{ width: "100%" }} type={"text"} placeholder={"Ключ"} />
                          </StyledFormItem>
                          <div style={{ margin: "10px 0 0 0" }}>:</div>
                          <StyledFormItem
                              style={{ width: "100%" }}
                              {...field}
                              key={id + "_value"}
                              label={"Значение"}
                              name={[name, "value"]}
                              rules={[
                                (formInstance) => ({
                                  message: "Введите значение!",
                                  required:
                                  formInstance.getFieldValue("additionalData")?.[key]?.key,
                                }),
                                {
                                  validator: whiteSpaceValidation,
                                },
                              ]}
                          >
                            <Input type={"text"} maxLength={255} style={{ width: "100%" }} placeholder={"Значение"} />
                          </StyledFormItem>
                        </RowFlex>
                      </Form.Item>
                  ))}

                  <Row gutter={28}>
                    <Col className="gutter-row" span={12}>
                      <Form.Item>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                        >
                          Добавить поля
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </>
            )}
          </Form.List>

          <ButtonRow>
            <ButtonCol>
              <Button
                  danger
                  onClick={() => {
                    if (initialValues) {
                      form.resetFields(["image"])
                      form.setFieldsValue(getInitialValues(initialValues))
                      return
                    }
                    form.resetFields()
                  }}
                  icon={<RotateClockwiseSvg />}
              >
                Вернуть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button onClick={handleClose} icon={<CloseSvg />}>
                Закрыть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                Сохранить
              </Button>
            </ButtonCol>
          </ButtonRow>
        </FormEdit>
      </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const RowFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
  padding-top: 15px;
`