import api from "src/api/index";
import {STATE_MEDIA} from "src/config";

export async function getProductCategoryHints(){
    try {
        const response =  await api.get(STATE_MEDIA + "api/dashboard/hints-product-categories")
        return response.data?.items ?? []
    } catch (error) {
        console.error("ошибка загрузки категорий", error?.message)
    }
}

