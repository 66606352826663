import React from "react"
import {MinusCircleOutlined, PlusOutlined, SwitcherFilled} from "@ant-design/icons"
import {
  Col,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space, Switch,
  TimePicker,
} from "antd"
import moment from "moment"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { TranslatableInput } from "src/components/Translatable"
import { useAppData } from "src/store/app-data"
import {formatPhoneValidation, getInitialValues} from "src/utils/helpers"
import styled from "styled-components"

export function EditDrawer({
  title,
  initialValues,
  onClose,
  onSave,
  pointsFeatures,
  deliveryTypes,
  paymentTypes,
  paymentAccounts
}) {
  const layoutConfig = useAppData((state) => state.layoutConfig)
  const [day, setDay] = React.useState(null)
  const [switchHurryUp, setSwitchHurryUp] = React.useState(true)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (initialValues) {
      setSwitchHurryUp(moment(initialValues.timeForHurryUp).format("HH:mm:ss") !== "00:00:00")
      initialValues.paymentAccountId = initialValues.paymentAccount?.id ?? null
      const data = getInitialValues(initialValues)
      const deliveries = deliveryTypes.map((deliveryType) => {
        const paymentsInfo = data.deliveryPaymentTypes.filter(
          (deliveryPaymentType) => deliveryPaymentType.deliveryId === deliveryType.id
        )
        const newPaymentsInfo = paymentTypes.reduce((acc, item) => {
          const isExist = paymentsInfo.find(
            (payment) => payment.paymentTypeId === item.id
          )

          if (isExist) {
            return [...acc, { ...isExist, nameRu: item.nameRu }]
          } else {
            return [
              ...acc,
              {
                paymentTypeId: item.id,
                isActive: false,
                nameRu: item.nameRu,
              },
            ]
          }
        }, [])
        return { ...deliveryType, paymentsInfo: newPaymentsInfo }
      })
      form.setFieldsValue({ ...data, deliveryPaymentTypes: deliveries })
    }
  }, [initialValues, paymentTypes, deliveryTypes])

  async function onSubmit(values) {
    const newDeliveryPaymentTypes = values.deliveryPaymentTypes.reduce(
      (acc, item) => {
        const items = item.paymentsInfo.map((item) => ({
          deliveryTypeId: item.deliveryId,
          paymentTypeId: item.paymentTypeId,
          isActive: item.isActive,
        }))
        return [...acc, ...items]
      },
      []
    )
    const reqData = {
      deliveryPaymentTypes: newDeliveryPaymentTypes,
      timeForHurryUp: switchHurryUp ? moment(values.timeForHurryUp).format("HH:mm:ss") : "00:00:00",
      timeStartForDelivery: moment(values.timeStartForDelivery).format("HH:mm:ss"),
      timeEndForDelivery: moment(values.timeEndForDelivery).format("HH:mm:ss"),
      stepTimeDelivery: moment(values.stepTimeDelivery).format("HH:mm:ss"),
      preorderIntervals: values.preorderIntervals.map((item) => ({
        start: moment(item.start).format("HH:mm"),
        end: moment(item.end).format("HH:mm"),
      })),
      schedule: values.schedule.map((item) => ({
        ...item,
        endTime: moment(item.endTime).format("HH:mm"),
        lunchTimeEnd: item.lunchTimeEnd
          ? moment(item.lunchTimeEnd).format("HH:mm")
          : null,
        lunchTimeStart: item.lunchTimeEnd
          ? moment(item.lunchTimeStart).format("HH:mm")
          : null,
        startTime: moment(item.startTime).format("HH:mm"),
      })),
      features: values.features,
      isActive: values.isActive,
      isPickup: values.isPickup,
      isDelivery: values.isDelivery,
      isShow: values.isShow,
      phonesList: values.phonesList,
      paymentAccountId: values.paymentAccountId
    }
    console.log(switchHurryUp);

    await onSave(reqData, initialValues.id)
    onClose()
    form.resetFields()
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }

  const id = initialValues?.id ?? "new"

  function changeHurryUpSwitch(e) {
    setSwitchHurryUp(e)
    if (e) {
      form.setFieldValue("timeForHurryUp", moment("00:50:00", "HH:mm:ss"))
    } else {
      form.setFieldValue("timeForHurryUp", moment("00:00:00", "HH:mm:ss"))
    }
  }

  function onAddScheduleItem(add) {
    const dayInfo = daysOptions.find((option) => day === option.value)
    if (!dayInfo) {
      return
    }
    add({
      dayOfWeek: dayInfo.value,
      shortDayOfWeek: dayInfo.shortDayOfWeek,
    })
    setDay(null)
  }

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={title}
      onClose={handleClose}
      open
    >
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <Row gutter={24} style={{ marginBottom: "24px" }}>
          {initialValues.photos?.map((photo) => (
            <Col className="gutter-row" span={3} key={photo.id}>
              <Image key={photo.id} width={200} src={photo.link} />
            </Col>
          ))}
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={3}>
            <StyledFormItem
              key={id + "_isActive"}
              label={"Включен"}
              name={"isActive"}
              initialValue={form.isActive || true}
              rules={[
                {
                  required: true,
                  message: "Выберите вариант!",
                },
              ]}
            >
              <Radio.Group buttonStyle="solid" value={form.isActive || true}>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
              </Radio.Group>
            </StyledFormItem>
          </Col>
          <Col className="gutter-row" span={3}>
            <StyledFormItem
              key={id + "_isShow"}
              label={"Видимость"}
              name={"isShow"}
              initialValue={form.isShow || true}
              rules={[
                {
                  required: true,
                  message: "Выберите вариант!",
                },
              ]}
            >
              <Radio.Group
                buttonStyle="solid"
                value={form.isShow ? form.isShow : true}
              >
                <Radio.Button value={true}>Виден</Radio.Button>
                <Radio.Button value={false}>Не виден</Radio.Button>
              </Radio.Group>
            </StyledFormItem>
          </Col>
          <Col className="gutter-row" span={3}>
            <StyledFormItem
              key={id + "_isDelivery"}
              label={"Доставка"}
              name={"isDelivery"}
              initialValue={form.isDelivery || true}
              rules={[
                {
                  required: true,
                  message: "Выберите вариант!",
                },
              ]}
            >
              <Radio.Group
                buttonStyle="solid"
                value={form.isDelivery ? form.isDelivery : true}
              >
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
              </Radio.Group>
            </StyledFormItem>
          </Col>
          <Col className="gutter-row" span={3}>
            <StyledFormItem
              key={id + "_isPickup"}
              label={"Пункт выдачи"}
              name={"isPickup"}
              initialValue={form.isPickup || true}
              rules={[
                {
                  required: true,
                  message: "Выберите вариант!",
                },
              ]}
            >
              <Radio.Group
                buttonStyle="solid"
                value={form.isPickup ? form.isPickup : true}
              >
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
              </Radio.Group>
            </StyledFormItem>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col className="gutter-row" span={12}>
            <StyledFormItem
              label={"Название"}
              key={id + "_name"}
              rules={[
                {
                  required: true,
                  message: "Введите название!",
                },
              ]}
            >
              <TranslatableInput disabled name={"name"} id={id}></TranslatableInput>
            </StyledFormItem>
          </Col>
          <Col className="gutter-row" span={12}>
            <StyledFormItem
              label={"Сокращенное название"}
              key={id + "_shortName"}
              rules={[
                {
                  required: true,
                  message: "Введите название!",
                },
              ]}
            >
              <TranslatableInput
                disabled
                name={"shortName"}
                id={id}
              ></TranslatableInput>
            </StyledFormItem>
          </Col>
        </Row>
        <StyledFormItem
          label={"Адрес"}
          key={id + "addressString"}
          name={"addressString"}
          rules={[
            {
              required: true,
              message: "Введите адрес!",
            },
          ]}
        >
          <Input disabled />
        </StyledFormItem>

        <Divider orientation="left">Номера телефонов</Divider>

        <Form.List name="phonesList">
          {(fields, { add, remove }) => (
              <>
                <Row gutter={24} style={{ marginBottom: "16px", display: "flex", flexDirection: "column", gap: "20px" }}>
                  {fields.map(({ key, name, ...field }, index) => (
                      <Col
                          className="gutter-row"
                          key={`phonesList_${index}_${key}`}
                          span={6}
                      >
                        <FlexRow>
                          <Space.Compact style={{ width: "100%" }}>
                            <StyledFormItem
                                label={"Номер телефона"}
                                key={`phone_${index}_${key}`}
                                name={[name, "phone"]}
                                {...field}
                                style={{ width: "80%" }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Введите номер телефона!",
                                  },
                                ]}
                            >
                              <Input placeholder={"79*********"}  />
                            </StyledFormItem>
                            <StyledFormItem
                                label={"Код"}
                                key={`code_${index}_${key}`}
                                name={[name, "code"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Введите",
                                  },

                                ]}
                                {...field}
                                style={{ width: "40%" }}
                            >
                              <InputNumber max={999999} />
                            </StyledFormItem>
                          </Space.Compact>
                          <StyledCol className="gutter-row" span={6}>
                            {fields.length > 0 ? (
                                <Button
                                    danger
                                    onClick={() => remove(name)}
                                    style={{ width: "35px", height: "35px", display: "flex", align: "center", justifyContent: "center"}}
                                    icon={<MinusCircleOutlined />}
                                >
                                </Button>
                            ) : null}
                          </StyledCol>
                        </FlexRow>
                      </Col>
                  ))}
                </Row>

                <Row gutter={28}>
                  <Col className="gutter-row" span={12}>
                    <Button
                        type="primary"
                        onClick={() => add()}
                        style={{ width: "50%", marginBottom: "24px" }}
                        icon={<PlusOutlined />}
                    >
                      Добавить телефон
                    </Button>
                  </Col>
                </Row>
              </>
          )}
        </Form.List>

        <Divider orientation="left">Интервалы для презаказа</Divider>
        <Form.List name="preorderIntervals">
          {(fields, { add, remove }) => (
            <>
              <Row gutter={[24, 16]} style={{ marginBottom: "16px" }}>
                {fields.map(({ key, name, ...field }, index) => (
                  <Col
                    className="gutter-row"
                    key={`preorderIntervals_${index}`}
                    span={2}
                  >
                    <StyledFormItem
                      label={"Начало"}
                      key={`preorderIntervalsStart_${index}`}
                      name={[name, "start"]}
                      {...field}
                    >
                      <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                    </StyledFormItem>
                    <StyledFormItem
                      label={"Конец"}
                      key={`preorderIntervalsEnd_${index}`}
                      name={[name, "end"]}
                      {...field}
                    >
                      <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                    </StyledFormItem>
                    {fields.length > 1 ? (
                      <Button
                        danger
                        onClick={() => remove(name)}
                        style={{ width: "100%" }}
                        icon={<MinusCircleOutlined />}
                      >
                        Удалить
                      </Button>
                    ) : null}
                  </Col>
                ))}
              </Row>
              <StyledFormItem>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "100%" }}
                  icon={<PlusOutlined />}
                >
                  Добавить интервал
                </Button>
              </StyledFormItem>
            </>
          )}
        </Form.List>

        <Divider orientation="left">Интервалы доставки</Divider>
        <Row gutter={24}>
          <Col className="gutter-row" span={6}>
            <StyledFormItem
              label={"Интревал для 'поскорее'"}
              key={id + "timeForHurryUp"}
              name={"timeForHurryUp"}
              rules={[
                {
                  required: true,
                  message: "Выберите время!",
                },
              ]}
            >
              <TimePicker disabled={!switchHurryUp} style={{ width: "100%" }} format="HH:mm:ss" />
            </StyledFormItem>
            <div>
              <strong>Доставка поскорее:</strong>
              <Switch
                  onChange={changeHurryUpSwitch}
                  style={{ marginLeft: "20px" }}
                  checked={switchHurryUp}
              />
            </div>

          </Col>

          <Col className="gutter-row" span={6}>
            <StyledFormItem
              label={"Время начала работы доставки"}
              key={id + "timeStartForDelivery"}
              name={"timeStartForDelivery"}
              rules={[
                {
                  required: true,
                  message: "Выберите время!",
                },
              ]}
            >
              <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
            </StyledFormItem>
          </Col>

          <Col className="gutter-row" span={6}>
            <StyledFormItem
              label={"Время конца приёма заказов"}
              key={id + "timeEndForDelivery"}
              name={"timeEndForDelivery"}
              rules={[
                {
                  required: true,
                  message: "Выберите время!",
                },
              ]}
            >
              <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
            </StyledFormItem>
          </Col>

          <Col className="gutter-row" span={6}>
            <StyledFormItem
              label={"Интервал доставки"}
              key={id + "stepTimeDelivery"}
              name={"stepTimeDelivery"}
              rules={[
                {
                  required: true,
                  message: "Выберите время!",
                },
              ]}
            >
              <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
            </StyledFormItem>
          </Col>
        </Row>

        <Divider orientation="left">График работы</Divider>
        <Form.List name="schedule">
          {(fields, { add, remove }) => (
            <>
              <Row gutter={[24, 16]} style={{ marginBottom: "16px" }}>
                <>
                  {fields.map(({ name, key, ...field }, index) => (
                    <Col
                      className="gutter-row"
                      key={`preorderIntervals_${index}`}
                      span={3}
                    >
                      <DisabledFormItem
                        key={`dayOfWeek_${index}`}
                        name={[name, "dayOfWeek"]}
                        {...field}
                      >
                        <Input bordered={false} disabled />
                      </DisabledFormItem>
                      <StyledFormItem
                        label={"Начало работы"}
                        key={`startTime_${index}_${key}`}
                        name={[name, "startTime"]}
                        {...field}
                        rules={[
                          {
                            required: true,
                            message: "Введите время!",
                          },
                        ]}
                      >
                        <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                      </StyledFormItem>
                      <StyledFormItem
                        label={"Конец работы"}
                        key={`endTime_${index}_${key}`}
                        name={[name, "endTime"]}
                        {...field}
                        rules={[
                          {
                            required: true,
                            message: "Введите время!",
                          },
                        ]}
                      >
                        <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                      </StyledFormItem>
                      <StyledFormItem
                        label={"Начало обеда"}
                        key={`lunchTimeStart_${index}_${key}`}
                        name={[name, "lunchTimeStart"]}
                        {...field}
                      >
                        <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                      </StyledFormItem>
                      <StyledFormItem
                        label={"Конец обеда"}
                        key={`lunchTimeEnd_${index}_${key}`}
                        name={[name, "lunchTimeEnd"]}
                        {...field}
                      >
                        <TimePicker style={{ width: "100%" }} format="HH:mm:ss" />
                      </StyledFormItem>
                      {fields.length > 1 ? (
                        <Button
                          danger
                          onClick={() => remove(name)}
                          style={{ width: "100%" }}
                          icon={<MinusCircleOutlined />}
                        >
                          Удалить
                        </Button>
                      ) : null}
                    </Col>
                  ))}
                </>
              </Row>
              <Row gutter={[24, 16]} style={{ marginBottom: "16px" }}>
                <Col className="gutter-row" span={6} style={{ marginTop: "30px" }}>
                  <Space.Compact block>
                    <Select
                      style={{ width: "50%" }}
                      value={day}
                      onChange={(value) => setDay(value)}
                      options={getFormattedDays(
                        daysOptions,
                        form.getFieldValue("schedule")
                      )}
                    />
                    <Button
                      type="primary"
                      onClick={() => onAddScheduleItem(add)}
                      style={{ width: "50%" }}
                      icon={<PlusOutlined />}
                      disabled={!day}
                    >
                      Добавить график работы
                    </Button>
                  </Space.Compact>
                </Col>
              </Row>
            </>
          )}
        </Form.List>

        <Divider orientation="left">Особенности точки</Divider>
        <div style={{ marginTop: "24px" }}>
          <Form.List name="features">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ name, key, ...field }) => (
                  <Row gutter={28} align="bottom" key={id + "_features_" + key}>
                    <Col className="gutter-row" span={6}>
                      <StyledFormItem
                        key={id + "_featuresId_" + key}
                        label={"Выберите особенность"}
                        name={[name, "id"]}
                        rules={[
                          {
                            required: true,
                            message: "Выберите особенность!",
                          },
                        ]}
                        {...field}
                      >
                        <Select options={pointsFeatures} />
                      </StyledFormItem>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <StyledFormItem
                        key={id + "_featuresText_" + key}
                        name={[name, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Заполните!",
                          },
                        ]}
                        {...field}
                      >
                        <Input />
                      </StyledFormItem>
                    </Col>
                    <StyledCol className="gutter-row" span={6}>
                      {fields.length > 0 ? (
                        <Button
                          danger
                          onClick={() => remove(name)}
                          icon={<MinusCircleOutlined />}
                        >
                          Удалить
                        </Button>
                      ) : null}
                    </StyledCol>
                  </Row>
                ))}
                <Row gutter={28}>
                  <Col className="gutter-row" span={12}>
                    <Button
                      type="primary"
                      onClick={() => add()}
                      style={{ width: "55%", marginBottom: "24px" }}
                      icon={<PlusOutlined />}
                    >
                      Добавить особенность
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
        </div>

        <Divider orientation="left">Способ оплаты</Divider>

        <Form.List name="deliveryPaymentTypes">
          {(fields) => (
            <>
              <Row gutter={[24, 16]} style={{ marginBottom: "16px" }}>
                <>
                  {fields.map(({ name, key, ...field }, index) => (
                    <Col
                      className="gutter-row"
                      key={`deliveryPaymentTypes_${index}`}
                      span={3}
                    >
                      <DisabledFormItem
                        key={`nameRu_${index}_${key}`}
                        name={[name, "nameRu"]}
                        {...field}
                      >
                        <Input bordered={false} disabled />
                      </DisabledFormItem>
                      <Form.List name={[name, "paymentsInfo"]}>
                        {(fields) => (
                          <>
                            {fields.map(({ name, key, ...field }, index) => (
                              <>
                                <DisabledFormItem
                                  key={`nameRu_${index}_${key}`}
                                  name={[name, "nameRu"]}
                                  {...field}
                                >
                                  <Input bordered={false} disabled />
                                </DisabledFormItem>
                                <StyledFormItem
                                  key={id + "_isActive" + `_${key}`}
                                  name={[name, "isActive"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Выберите вариант!",
                                    },
                                  ]}
                                  {...field}
                                >
                                  <Radio.Group buttonStyle="solid">
                                    <Radio.Button value={true}>Да</Radio.Button>
                                    <Radio.Button value={false}>Нет</Radio.Button>
                                  </Radio.Group>
                                </StyledFormItem>
                              </>
                            ))}
                          </>
                        )}
                      </Form.List>
                    </Col>
                  ))}
                </>
              </Row>
            </>
          )}
        </Form.List>

        <StyledFormItem
            key={id + "_paymentAccountId"}
            label={"Платёжный аккаунт"}
            name={"paymentAccountId"}
        >
          <Select options={paymentAccounts} />
        </StyledFormItem>

        <Row gutter={28}>
          <Col className="gutter-row" span={12}>
            <StyledFormItem
              label={"externalGuid"}
              key={id + "_externalGuid"}
              name={"externalGuid"}
              rules={[
                {
                  required: true,
                  message: "Введите externalGuid!",
                },
              ]}
            >
              <Input disabled />
            </StyledFormItem>
          </Col>

          <Col className="gutter-row" span={12}>
            <StyledFormItem
              label={"mpid"}
              key={id + "_mpid"}
              name={"mpid"}
              rules={[
                {
                  required: true,
                  message: "Введите mpid!",
                },
              ]}
            >
              <Input disabled />
            </StyledFormItem>
          </Col>
        </Row>
        <ButtonRow>
          <ButtonCol>
            <Button
              danger
              onClick={() => {
                if (initialValues) {
                  form.setFieldsValue(getInitialValues(initialValues))
                  return
                }
                form.resetFields()
              }}
              icon={<RotateClockwiseSvg />}
            >
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button icon={<SaveSvg />} type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>
    </DrawerEdit>
  )
}

function getFormattedDays(options, schedule) {
  return options.map((option) => {
    const isExist = schedule?.find((item) => item.dayOfWeek === option.value)
    return {
      ...option,
      disabled: isExist,
    }
  })
}

const daysOptions = [
  {
    value: "Понедельник",
    label: "Понедельник",
    dayOfWeek: "Понедельник",
    shortDayOfWeek: "Пн",
  },
  {
    value: "Вторник",
    label: "Вторник",
    dayOfWeek: "Вторник",
    shortDayOfWeek: "Вт",
  },
  {
    value: "Среда",
    label: "Среда",
    dayOfWeek: "Среда",
    shortDayOfWeek: "Ср",
  },
  {
    value: "Четверг",
    label: "Четверг",
    dayOfWeek: "Четверг",
    shortDayOfWeek: "Чт",
  },
  {
    value: "Пятница",
    label: "Пятница",
    dayOfWeek: "Пятница",
    shortDayOfWeek: "Пт",
  },
  {
    value: "Суббота",
    label: "Суббота",
    dayOfWeek: "Суббота",
    shortDayOfWeek: "Сб",
  },
  {
    value: "Воскресенье",
    label: "Воскресенье",
    dayOfWeek: "Воскресенье",
    shortDayOfWeek: "Вс",
  },
]

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const DisabledFormItem = styled(Form.Item)`
  margin-bottom: 4px;

  input:disabled {
    font-weight: 600;
    padding: 0;
    color: rgba(44, 53, 68, 0.85);
  }
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const StyledCol = styled(Col)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`
const FlexRow = styled("div")`
  width: 500px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 20px;
`
const StyledImage = styled(Image)`
  display: none;
`
