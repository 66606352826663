import { useNavigate } from "react-router-dom"
import { Button, Card, Col, Form, Input, notification, Row } from "antd"
import api from "src/api/index"
import { Bushe } from "src/components/Svg"
import { useTitle } from "src/hooks/use-title"
import { appLocalStorage } from "src/libs"
import { BASIC_PRIVATE_PATH } from "src/routing/paths"
import { useAppData } from "src/store/app-data"
import { useAuthStore } from "src/store/auth"
import styled from "styled-components"

import "src/styles/app.less"
import React from "react";

export default function Login() {
  const navigate = useNavigate()
  useTitle("Авторизация")
  const setAuthed = useAuthStore((state) => state.setAuthed)
  const setUser = useAppData((state) => state.setUser)

  const [isLoading, setIsLoading] = React.useState(false)

  notification.config({
    placement: "topLeft",
  })

  async function onFinish(values) {
    setIsLoading(true)
    try {

      const data = await api.post("auth", values)
      notification.success({ message: "Вы успешно авторизовались" })

      appLocalStorage.token = data.data.token

      appLocalStorage.setData("user", {
        email: data.data.user.email,
        name: data.data.user.name ? data.data.user.name : "Админ Буше",
        role: data.data.role,
        two_letters: data.data.user.name ? data.data.user.name : "АБ"
      }) 


      setAuthed(true)
      setIsLoading(false)
      navigate(BASIC_PRIVATE_PATH)

    } catch (error) {
      setIsLoading(false)
      notification.error({ message: "Ошибка авторизации" })
      console.error("Ошибка авторизации", error)
    }
  }

  return (
    <>
      <Wrapper>
        <Auth xs={24} sm={18} md={10} lg={8} xl={6}>
          <AuthCard>
            <LogoRow>
              <Bushe width={130} height={40} />
            </LogoRow>
            <Form layout={"vertical"} onFinish={onFinish}>
              <Form.Item key={0 + "_email"} label={"E-Mail"} name={"email"}>
                <Input value={""} type={"text"} placeholder={"E-Mail"} />
              </Form.Item>
              <Form.Item key={1 + "_password"} label={"Пароль"} name={"password"}>
                <Input.Password value={""} type={"Пароль"} placeholder={"Пароль"} />
              </Form.Item>

              <Form.Item>
                <SubmitButton loading={isLoading} type="primary" block={true} htmlType="submit">
                  Войти
                </SubmitButton>
              </Form.Item>
            </Form>
          </AuthCard>
        </Auth>
      </Wrapper>
    </>
  )
}

const Wrapper = styled(Row)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-width: 100%;
`

const Auth = styled(Col)`
  position: relative;
  z-index: 5;
`

const AuthCard = styled(Card)`
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
`

const LogoRow = styled(Col)`
  margin: 30px;
  text-align: center;
`

const SubmitButton = styled(Button)`
  margin-top: 2em;
  display: flex;
  width: 100%;
  justify-content: start;
`
