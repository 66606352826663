import api from "src/api/index";
import {STATE_MEDIA} from "src/config";

export async function getPromotionCategories () {
  try {
    const response =   await api.get(STATE_MEDIA + "api/dashboard/promotion-categories")
    return response.data?.items ?? []
  } catch (error) {
    console.error("ошибка загрузки городов", error?.message)
  }
}