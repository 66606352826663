import React from "react";
import api from "src/api";
import {getProductType} from "../../../utils/getProductType";
import {getProductCategories} from "../../../utils";

export function useGetProducts() {
    const [pagination, setPagination] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [productTypesOptions, setProductTypesOptions] = React.useState([]);
    const [categoryOptions, setCategoryOptions] = React.useState([]);

    async function getItems(page = 1, countOnPage = 20) {
        setIsLoading(true);
        try {
            const response = await api.get(`product?page=${page}&countOnPage=${countOnPage}`);

            setPagination({
                current: response.data.pagination.currentPage,
                pageSize: response.data.pagination.countOnPage,
                total: response.data.pagination.total
            });
            setItems(response.data.items);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    async function getProductTypesOptions() {
        let data = getProductType()
        setProductTypesOptions(data)
    }

    async function getCategoryOptions() {
        const data = await getProductCategories();

        const options = data.reduce((acc, item) => {
            const searchName = item?.name?.find((el) => el.language === "ru");
            return [
                ...acc,
                {
                    label: searchName.content,
                    value: item.id,
                    disabled: !item.isActive,
                },
            ];
        }, []);
        setCategoryOptions(options);
    }


    const handleTableChange = (pagination) => {
        getItems(pagination.current, pagination.pageSize )
    };

    React.useEffect(() => {
        getItems();
        getProductTypesOptions();
        getCategoryOptions()
    }, []);

    return {
        items,
        pagination,
        isLoading,
        refetch: () => getItems(),
        handleTableChange,
        options: { productTypesOptions, categoryOptions }
    };
}


export function getFormattedItems(items) {
    const data = items.map((item) => ({
        key: item.id,
        id: item.id,
        name: item.name,
        nameRu: item.name?.find((el) => el.language === "ru")?.content,
        shortNameRu: item.short_name?.find((el) => el.language === "ru")?.content,
        photo: item.photo,
        sortWeight: item.sortWeight,
        price: item.price,
        isActive: item.isActive,
        categories: item.dashboardProductCategories.map((item) => {
            return {
                label: item?.name.find((el) => el.language === "ru")?.content,
                disabled: item?.disabled,
            };
        }),
    }))

    return data
}