import React from "react"
import api from "src/api"
import { getTaxSystem } from "src/utils";

export function useGetAccounts() {
  const [items, setItems] = React.useState([])
  const [systemOptions, setSystemOptions] = React.useState([])

  async function getItems() {
    try {
      const { data } = await api.get("payment-accounts")
      updateItems(data.items)
    } catch (error) {
      console.error("Ошибка получения данных", error)
    }
  }

  React.useEffect(() => {
    getItems()
    getSystemState()
  }, [])

  function getSystemState() {
    const systems = getTaxSystem()
    setSystemOptions(systems)
  }

  function updateItems(items) {
    const newData = getFormattedItems(items)
    setItems(newData)
  }

  function getFormattedItems(items) {
    const systems = getTaxSystem()
    const data = items.map((item) => ({
      key: item.id,
      id: item.id,
      name: item.name,
      nameRu: item.name?.find((el) => el.language === "ru")?.content,
      slug: item.slug,
      login: item.login,
      password: item.password,
      token: item.token ?? null,
      isDefault: item.isDefault,
      isHaveVat: item.isHaveVat,
      taxSystem: item.taxSystem,
      taxSystemName: systems.find((el) => el.value === item.taxSystem)?.label
    }))

    return data
  }

  return { items, refetch: () => getItems(), options: {systemOptions} }
}
