import React from "react";
import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import {useGetItems, useManageItems} from "../pages-lib/order-hold/model";
import styled from "styled-components";
import {Table as AntTable} from "antd";
import {getColumns} from "../pages-lib/order-hold/table-columns";
export default function OrdersHoldPayment() {

    const {
        items: dataItems,
        refetch: getItems,
        isLoading,
        pagination,
        handleTableChange
    } = useGetItems()


    const { onConfirmPayment, onCanceledPayment } = useManageItems(
        pagination,
        getItems,
    )

    const headerTitle = useUpdateHeaderTitle({
        items: dataItems,
        defaultTitle: "0 Записей",
        arrType: ["Запись", "Записи", "Записей"],
    })

    const columns = getColumns(onConfirmPayment, onCanceledPayment)

    return (
        <>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        //onCreate={() => setCreateDrawerState(true)}
                        // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                        // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                        //titles={{ onCreate: "Добавить аккаунт" }}
                    />
                }
            />

            <StyledTable
                dataSource={dataItems}
                columns={columns}
                pagination={pagination}
                loading={isLoading}
                onChange={handleTableChange}
            />

        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
