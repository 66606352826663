import React from "react"

export function useImage() {
  const [previewVisible, setPreviewVisible] = React.useState(false)
  const [previewImage, setPreviewImage] = React.useState(null)
  const [imageUpdated, setImageUpdated] = React.useState(false)

  async function onPreview(file) {
    const preview = file.originFileObj ?? file
    if (preview instanceof File) {
      preview.url = await URL.createObjectURL(preview)
    }
    setPreviewImage(preview)
    setPreviewVisible(true)
  }

  function onChange(e) {
    setImageUpdated(true)
    return e
  }

  function getImage(files) {
    const file = files?.file ?? files?.fileList?.[0] ?? files[0] ?? files

    if (file instanceof File) {
      file.url = URL.createObjectURL(file)
    }
    return file
  }

  return {
    onChange,
    onPreview,
    getImage,
    preview: {
      visible: previewVisible,
      onVisibleChange: () => setPreviewVisible(!previewVisible),
      previewImage,
    },
    imageUpdated,
  }
}
