import React from "react";
import {Form, Input, } from "antd";
import styled from "styled-components";
import {RotateClockwiseSvg, } from "../../components/Svg";
import {Button} from "../../components/actions/button";
import {SearchOutlined} from "@ant-design/icons";

export function ProductPointFilers({handleSearch}) {

    const [form] = Form.useForm()

    function onSearch(values){
        let data = {
            nameProduct: values.nameProduct?.trim() ?? null,
            namePoint: values.namePoint?.trim() ?? null
        }
        handleSearch(data)
    }

    function onClear() {
        form.resetFields()
        handleSearch([])

    }

    return (
        <>
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSearch(values)}>
                <StyledFormItem
                    key={ "sort_nameProduct" }
                    label={"По названию продукта"}
                    name={"nameProduct"}
                >
                    <Input placeholder="Название продукта" maxLength={200} />
                </StyledFormItem>

                <StyledFormItem
                    key={ "sort_namePoint" }
                    label={"По названию точк"}
                    name={"namePoint"}
                >
                    <Input placeholder="Сокрощённое название" maxLength={200} />
                </StyledFormItem>

                <Button
                    danger
                    onClick={onClear}
                    icon={<RotateClockwiseSvg />}
                >
                    Сбросить
                </Button>
                <Button icon={<SearchOutlined />}  type="primary" htmlType="submit">
                    Искать
                </Button>
            </FormEdit>
        </>
    )
}

const FormEdit = styled(Form)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`

const StyledFormItem = styled(Form.Item)`
  margin-right: 25px;
`