import { Space, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import { PencilSvg } from "src/components/Svg"
import styled from "styled-components"
import {SolutionOutlined} from "@ant-design/icons";

export function getColumns(onEdit) {
  return [
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (_, record) => (
          <span>{record.dashboardUser?.phone ?? "не заполнено"}</span>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_, record) => (
          <span>{record.dashboardUser?.email ?? "не заполнено"}</span>
      )
    },
    {
      title: "Guid",
      dataIndex: "guid",
      key: "guid",
      render: (_, record) => (
          <span>{record.dashboardUser?.dashboardUserInfo?.externalGuid}</span>
      )
    },
    {
      title: "Отправлен",
      dataIndex: "isSent",
      key: "isSent",
      render: (text) => (
          <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
            {text ? "ДА" : "НЕТ"}
          </StyledTag>
      ),
    }
  ]
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`