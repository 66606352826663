import React from "react"
import styled from "styled-components"

export function LayoutFooter() {
  return (
    <Copyright>
      Разработка -{" "}
      <a href={"https://kotelov.com"} target="_blank" rel="noreferrer">
        KOTELOV
      </a>
    </Copyright>
  )
}

const Copyright = styled.div`
  text-align: center;
`
