import React from "react";
import { Table as AntTable } from "antd";
import { ExtraButtons } from "src/components/extra-buttons";
import { Header } from "src/components/layout/header";
import { VisibilityProvider } from "src/components/visibility-provider";
import { useUpdateHeaderTitle } from "src/hooks/use-update-header-title";
import { EditDrawer } from "src/pages-lib/products-groups/edit-drawer";
import { useGetCategories, useManageItems } from "src/pages-lib/products-groups/model";
import { getFormattedItems } from "src/pages-lib/products-groups/model/use-get-categories";
import { getColumns } from "src/pages-lib/products-groups/table-columns";
import styled from "styled-components";

export default function ProductsGroups() {
  const [createDrawerState, setCreateDrawerState] = React.useState(false);
  const [editDrawerState, setEditDrawerState] = React.useState(false);

  // const [filterDrawerState, setFilterDrawerState] = React.useState(false)
  // const [sortableDrawerState, setSortableDrawerState] = React.useState(false)

  const { items: dataCategories, refetch: getItems, pagination, options } = useGetCategories();
  const { editData, onSaveEdit, onCreate, onDelete, onEditItem } = useManageItems(
    dataCategories,
    getItems,
    onSuccessEdit,
  );

  const headerTitle = useUpdateHeaderTitle({
    items: dataCategories,
    defaultTitle: "0 Групп",
    arrType: ["Группа", "Группы", "Групп"],
  });

  const [tableParams] = React.useState({
    pagination: pagination ?? {
      current: 1,
      pageSize: 30,
    },
  });

  const columns = getColumns(onEditItem, onDelete);

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState);
  }

  return (
    <>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            //onCreate={() => setCreateDrawerState(!createDrawerState)}
            // onFilter={() => setFilterDrawerState(!filterDrawerState)}
            // onSort={() => setSortableDrawerState(!sortableDrawerState)}
            //titles={{ onCreate: "Добавить группу" }}
          />
        }
      />
      <StyledTable
        dataSource={getFormattedItems(dataCategories, options.categoryOptions)}
        columns={columns}
        pagination={tableParams.pagination}
      />

      <VisibilityProvider visible={createDrawerState}>
        <EditDrawer
          onSave={onCreate}
          onClose={() => setCreateDrawerState(!createDrawerState)}
          title={"Создание группы"}
          categoryOptions={options.categoryOptions}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onSave={onSaveEdit}
          onClose={() => setEditDrawerState(!editDrawerState)}
          initialValues={editData}
          title={"Редактирование группы"}
          categoryOptions={options.categoryOptions}
        />
      </VisibilityProvider>
    </>
  );
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`;
