export function BusheSvg({width, height}: IBusheProps): JSX.Element {
    return (
        <svg width={width} height={height} viewBox="0 0 140 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.455 0H24.1531C24.1531 0.903225 23.7408 1.62581 22.9749 2.16774C22.1502 2.76989 20.972 3.01075 19.4403 3.01075H17.673C11.8409 3.01075 7.42267 4.87742 4.47716 8.55054C1.47275 12.2839 0 18.1849 0 26.314C0 32.3355 1.29602 37.0323 4.00588 40.2839C6.71575 43.5355 10.486 45.1613 15.3166 45.1613C19.3814 45.1613 22.8571 43.7161 25.7437 40.7656C28.5714 37.8753 30.0441 34.3226 30.0441 30.1075C30.0441 25.8323 28.7481 22.2796 26.1561 19.3892C23.564 16.4989 20.2061 15.0538 16.2003 15.0538C14.6097 15.0538 13.1369 15.2946 11.7231 15.7763C9.54343 16.5591 7.83504 17.7634 6.53902 19.3892C6.06774 19.9312 5.59645 20.714 5.00735 21.6774C5.59645 17.0409 6.77466 13.6688 8.60087 11.5613C10.3682 9.51398 13.0191 8.43011 16.4948 8.43011H18.8512C25.9204 8.43011 29.455 5.66021 29.455 0ZM5.89101 30.1075C5.89101 27.4581 6.77466 25.2301 8.65978 23.3032C10.486 21.4366 12.7246 20.4731 15.3166 20.4731C17.9087 20.4731 20.0883 21.4366 21.9735 23.3032C23.7997 25.2301 24.7422 27.4581 24.7422 30.1075C24.7422 32.757 23.7997 35.0452 21.9735 36.9118C20.0883 38.8387 17.9087 39.7419 15.3166 39.7419C12.6068 39.7419 10.3093 38.8989 8.54196 37.0925C6.77466 35.286 5.89101 32.9978 5.89101 30.1075Z" fill="#0E0E0E"/>
            <path d="M62.4258 13.8495H56.5937L47.7572 32.8172L37.2712 13.8495H31.2035L44.9884 38.7785L36.9766 56H42.8087L62.4258 13.8495Z" fill="#0E0E0E"/>
            <path d="M68.3656 43.957H103.712V13.8495H98.4097V38.5376H88.6895V13.8495H83.3876V38.5376H73.6675V13.8495H68.3656V43.957Z" fill="#0E0E0E"/>
            <path d="M139.984 28.9634C140.161 24.5075 138.865 20.714 136.096 17.4624C133.268 14.271 129.557 12.6452 125.021 12.6452C120.485 12.6452 116.774 14.2108 113.887 17.2817C111 20.4129 109.587 24.2667 109.587 28.9634C109.587 33.6602 111 37.514 113.946 40.5849C116.891 43.6559 120.721 45.1613 125.492 45.1613C128.379 45.1613 130.971 44.4989 133.209 43.1742C135.448 41.8495 137.215 40.043 138.511 37.6946L135.153 35.828L134.211 35.286C133.681 36.4301 132.679 37.514 131.265 38.4774C129.793 39.4409 127.849 39.9226 125.492 39.9226C122.547 39.9226 120.131 39.0796 118.364 37.3935C116.538 35.7075 115.419 33.6 114.947 30.9505H139.984V28.9634ZM115.183 25.7118C115.536 23.6645 116.597 21.7978 118.482 20.2323C120.308 18.6667 122.488 17.8237 125.021 17.8237C127.318 17.8237 129.38 18.5462 131.206 19.9312C132.974 21.3763 134.093 23.3032 134.447 25.7118H115.183Z" fill="#0E0E0E"/>
        </svg>
    );
}


interface IBusheProps {
    width: number,
    height: number
}