import React from "react"
import api from "src/api"

export function useGetCategories() {
  const [pagination, setPagination] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [items, setItems] = React.useState([])

  async function getItems() {
    setIsLoading(true)
    try {
      const response = await api.get("article-categories")
      setPagination(response.data.pagination)
      setItems(getFormattedItems(response.data.items))

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    getItems()
  }, [])

  function getFormattedItems(items) {
    const data = items.map((item) => ({
      id: item.id,
      name: item.name,
      nameRu: item.name?.find((el) => el.language === "ru")?.content,
      isActive: item.isActive,
      sortWeight: item.sortWeight,
    }))

    return data
  }

  return { items, pagination, isLoading, refetch: () => getItems() }
}
