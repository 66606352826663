import { Layout as AntLayout } from "antd"
import { LayoutFooter } from "src/components/layout/layout-footer"
import { NavSider } from "src/components/menu/nav-sider"
import { useAppData } from "src/store/app-data"
import styled from "styled-components"

const { Footer, Content } = AntLayout
export function BasicLayout({ children }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)
  return (
    <AntLayoutWrapper
      isMobile={layoutConfig.isMobile}
      siderWidth={layoutConfig.siderWidth}
    >
      <StyledAntLayout hasSider={layoutConfig.isMobile}>
        <NavSider />
        <FooterWrapper>
          <StyledContent>{children}</StyledContent>
          <Footer>
            <LayoutFooter />
          </Footer>
        </FooterWrapper>
      </StyledAntLayout>
    </AntLayoutWrapper>
  )
}

const FooterWrapper = styled(AntLayout)`
  min-height: initial;
`

const StyledAntLayout = styled(AntLayout)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`

const AntLayoutWrapper = styled.div`
  padding-left: ${(layoutConfig) =>
    layoutConfig.isMobile ? 0 : layoutConfig.siderWidth}px;
  position: relative;
`

const StyledContent = styled(Content)`
  padding: 10px 20px;
`
