import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks"
import { EditDrawer } from "src/pages-lib/events/edit-drawer"
import { useGetData, useManageItems } from "src/pages-lib/events/model"
import { getColumns } from "src/pages-lib/events/table-columns"
import styled from "styled-components"

export default function Events() {
  const [createDrawerState, setCreateDrawerState] = React.useState(false)
  const [editDrawerState, setEditDrawerState] = React.useState(false)

  // const [filterDrawerState, setFilterDrawerState] = useState(false)
  // const [sortableDrawerState, setSortableDrawerState] = useState(false)

  const {
    dataEvents,
    dataEventsColumns,
    pagination,
    isLoading,
    handleTableChange,
    refetch: getItems,
    options,
  } = useGetData()

  const { editData, resetData, onSaveEdit, onCreate, onDelete, onEditItem } =
    useManageItems(dataEvents, getItems, onSuccessEdit, onSuccessCreate)

  const headerTitle = useUpdateHeaderTitle({
    items: dataEvents,
    defaultTitle: "0 Мероприятий",
    arrType: ["Мероприятий", "Мероприятия", "Мероприятий"],
  })

  const columns = getColumns(onEditItem, onDelete)

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  function onSuccessCreate() {
    setCreateDrawerState(!createDrawerState)
  }

  return (
    <>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            onCreate={() => setCreateDrawerState(true)}
            // onFilter={() => setFilterDrawerState(!filterDrawerState)}
            // onSort={() => setSortableDrawerState(!sortableDrawerState)}
            titles={{ onCreate: "Добавить мероприятие" }}
          />
        }
      />
      <StyledTable
        dataSource={dataEventsColumns}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={pagination}
      />

      <VisibilityProvider visible={createDrawerState}>
        <EditDrawer
          onSave={onCreate}
          onClose={() => setCreateDrawerState(false)}
          title={"Создание мероприятия"}
          cityOptions={options.cityOptions}
          categoryOptions={options.categoryOptions}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onSave={onSaveEdit}
          onClose={() => {
            setEditDrawerState(false)
            resetData()
          }}
          initialValues={editData}
          title={"Редактирование мероприятия"}
          cityOptions={options.cityOptions}
          categoryOptions={options.categoryOptions}
          userStatusOptions={options.userStatusOptions}
        />
      </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
