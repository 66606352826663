import React from "react";
import {

    Drawer, Form, Table as AntTable,
} from "antd";
import {useAppData} from "../../store/app-data";
import styled from "styled-components";
import {getNotificationColumns} from "./notification-columns";

export function NotificationsDrawer({
                            title,
                            onClose,
                            clearData,
                            items,
                            pagination,
                            onChange,
                            isLoad
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);

    const columns = getNotificationColumns();
    const handleClose = () => {
        onClose();
        clearData();
    }

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={handleClose}
            open
        >
            <StyledTable
                dataSource={items}
                columns={columns}
                pagination={pagination}
                onChange={onChange}
                loading={isLoad}
            />
        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
  .ant-table-row{
    background: #ffffff;
  }
  max-width: 100%;
  overflow: scroll;
`;