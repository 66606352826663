import api from "src/api/index";
import {STATE_MEDIA} from "src/config";

export async function getPaymentTypes() {
  try {
    const response =  await api.get(STATE_MEDIA + "api/dashboard/payment-types")
    return response.data.items
  } catch (error) {
    console.error("ошибка загрузки типов платежей", error?.message)
  }
}