import React from "react"
import { Form, Tabs } from "antd"
import { TinyMCE } from "src/components/Wysiwyg/TinyMCE"
import { getLanguages } from "src/utils"
import styled from "styled-components"

export function TranslatableWysiwyg(props) {
  const { value, name } = props

  const languages = getLanguages()

  const defaultLang = languages?.[0]?.value
  const [activeTabKey, setActiveTabKey] = React.useState(defaultLang)
  const onTabChange = React.useCallback((key) => {
    setActiveTabKey(key)
  }, [])

  if (!languages?.length) {
    console.error("Translatable component: languages list not found in shared data")
    return null
  }

  let items = languages.map((language, index) => ({
    key: language.value,
    label: language.label,
    forceRender: true,
    children: (
      <Form.Item
        key={`${name}_${language.value}`}
        name={[name, language.value]}
        rules={
          index === 0
            ? [
                {
                  required: true,
                  message: language.textErrors.required,
                },
              ]
            : undefined
        }
      >
        <TinyMCE value={value}></TinyMCE>
      </Form.Item>
    ),
  }))

  return (
    <StyledTabs
      items={items}
      activeKey={activeTabKey}
      onChange={onTabChange}
      type="card"
      size="small"
    ></StyledTabs>
  )
}

const StyledTabs = styled(Tabs)`
  overflow: visible;

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: none;
    user-select: none;
  }

  &.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 3px 7px 6px;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    bottom: -3px;
    margin-bottom: 0;

    &::before {
      border-bottom: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`
