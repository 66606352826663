import React from "react"
import api from "src/api"
import { getCities, getEventCategories } from "src/utils"

export function useGetData() {
  const [dataEvents, setDataEvents] = React.useState([])
  const [dataEventsColumns, setDataEventsColumns] = React.useState([])

  const [pagination, setPagination] = React.useState(null)

  const [cityOptions, setCityOptions] = React.useState([])
  const [categoryOptions, setCategoryOptions] = React.useState([])
  const [userStatusOptions, setUserStatusOptions] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    getUserStatuses()
    getCityOptions()
    getCategoryOptions()
    getItems()
  }, [])

  async function getCityOptions() {
    const data = await getCities()

    const options = data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    setCityOptions(options)
  }

  async function getCategoryOptions() {
    const data = await getEventCategories()

    const options = data.reduce((acc, item) => {
      if (!item.isActive) {
        return acc
      }

      const searchName = item?.name?.find((el) => el.language === "ru")
      return [
        ...acc,
        {
          label: searchName.content,
          value: item.id,
        },
      ]
    }, [])
    setCategoryOptions(options)
  }

  async function getUserStatuses() {
    api.get("user-statuses-hints").then(({ data }) => {
      const options = []
      data.items.forEach((item) => {
        options.push({
          label: `${item.number} - ${item.name.find((el) => el.language === "ru").content}`,
          value: item.id,
        })
      })
      setUserStatusOptions(options)
    })
  }

  async function getItems(page = 1, countOnPage = 20) {
    setIsLoading(true)
    try {
      const { data } = await api.get(`events?page=${page}&countOnPage=${countOnPage}`)

      setPagination({
        current: data.pagination.currentPage,
        pageSize: data.pagination.countOnPage,
        total: data.pagination.total,
      })
      updateItems(data.items)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  function updateItems(items) {
    const newData = getFormattedItems(items)

    setDataEventsColumns(newData)
    setDataEvents(items)
  }

  function handleTableChange(pagination) {
    getItems(pagination.current, pagination.pageSize)
  }

  function getFormattedItems(items) {
    const newItems = items.map((item) => {
      const category = item.eventCategory.name
      const name = item.name
      const cityNames = item.cities?.map((point) => point.name)

      return {
        key: item.id,
        id: item.id,
        isActive: item.isActive,
        category: category.find((el) => el.language === "ru").content,
        name: name.find((el) => el.language === "ru").content,
        description: item.description.length > 0 ? item.description.find((el) => el.language === "ru").content : null,
        cities: cityNames.join(", "),
      }
    })
    return newItems
  }

  return {
    dataEvents,
    dataEventsColumns,
    pagination,
    isLoading,
    handleTableChange,
    refetch: () => getItems(),
    options: { cityOptions, categoryOptions, userStatusOptions },
  }
}
