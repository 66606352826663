export function getUserTasks() {
    return getItems();
}

function getItems() {
    return [
        {
            value: "updateHistory",
            label: "Обновить историю"
        },
        {
            value: "checkCompletedQuests",
            label: "Проверить выполнение квеcтов"
        },
        {
            value: "checkLoyaltySystem",
            label: "Проверить систему лояльности"
        }
    ];
}