import React from 'react'
import Icon from '@ant-design/icons'

const SvgIcon = ({ stroke = 'currentColor', strokeWidth = 1.5, size = 20 }) => {
    return <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        strokeWidth={strokeWidth}
        stroke={stroke}
        fill='none' strokeLinecap='round' strokeLinejoin='round'
    >
        <path stroke="none" d="M0 0h24v24H0z"/>
        <circle cx="7" cy="17" r="2"/>
        <circle cx="17" cy="17" r="2"/>
        <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"/>
    </svg>
}

export function TruckSvg(props) {
    return <Icon
        component={SvgIcon}
        {...props}
    />
}
