import React from "react"
import { notification } from "antd"
import api from "src/api"

export function useManageItems(items, getItems, onSuccessEdit) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    let item = items.find((item) => item.id === id)

    setEditData(item)
    onSuccessEdit()
  }

  async function onDelete(id) {
    try {
      await api.delete(`live-love-work/${id}`)

      notification.success({ message: "Объект удалён" })
      getItems()
    } catch (error) {
      console.error("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  function resetEditData() {
    setEditData(null)
  }

  return { editData, onDelete, onEditItem, resetEditData }
}
