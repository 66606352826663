import React from "react";
import {DatePicker, Form, Input, Row} from "antd";
import styled from "styled-components";
import {RotateClockwiseSvg, SaveSvg} from "../../components/Svg";
import {Button} from "../../components/actions/button";
import {SearchOutlined} from "@ant-design/icons";

export function PointFilers({handleSearch}) {

    const [form] = Form.useForm()

    function onSearch(values){
        let data = {
            name: values.name?.trim() ?? null,
            short_name: values.short_name?.trim() ?? null
        }
        handleSearch(data)
    }

    function onClear() {
        form.resetFields()
        handleSearch([])

    }

    return (
        <>
           <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSearch(values)}>
                   <StyledFormItem
                       key={ "name" }
                       label={"По полному названию"}
                       name={"name"}
                   >
                       <Input placeholder="Название" maxLength={200} />
                   </StyledFormItem>

                   <StyledFormItem
                       key={ "short_name" }
                       label={"По сокращённому названию"}
                       name={"short_name"}
                   >
                       <Input placeholder="Сокрощённое название" maxLength={200} />
                   </StyledFormItem>

                   <Button
                       danger
                       onClick={onClear}
                       icon={<RotateClockwiseSvg />}
                   >
                       Сбросить
                   </Button>
                   <Button icon={<SearchOutlined />}  type="primary" htmlType="submit">
                       Искать
                   </Button>
           </FormEdit>
        </>
    )
}

const FormEdit = styled(Form)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`

const StyledFormItem = styled(Form.Item)`
  margin-right: 25px;
`