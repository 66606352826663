import React from "react";
import api from "src/api";
import { getPoints, getProductGroups } from "src/utils";

export function useGetCategories() {
  const [pagination, setPagination] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [cityOptions, setCityOptions] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  async function getItems() {
    setIsLoading(true);
    try {
      const response = await api.get("product-categories");
      setPagination(response.data.pagination);
      setItems(response.data.items);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    getItems();
    getPointsOptions();
    getCategoryOptions();
  }, []);

  async function getPointsOptions() {
    const data = await getPoints();

    const options = data.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    setCityOptions(options);
  }

  async function getCategoryOptions() {
    const data = await getProductGroups();

    const options = data.reduce((acc, item) => {
      const searchName = item?.name?.find((el) => el.language === "ru");
      return [
        ...acc,
        {
          label: searchName.content,
          value: item.id,
          disabled: !item.isActive,
        },
      ];
    }, []);
    setCategoryOptions(options);
  }

  return {
    items,
    pagination,
    isLoading,
    refetch: () => getItems(),
    options: { cityOptions, categoryOptions },
  };
}

export function getFormattedItems(items, categoryOptions) {
  const data = items.map((item) => ({
    id: item.id,
    name: item.name,
    nameRu: item.name?.find((el) => el.language === "ru")?.content,
    isActive: item.isActive,
    isShowToMain: item.isShowToMain,
    sortWeight: item.sortWeight,
    groups: item.groupProductCategoryIds.map((id) => {
      const item = categoryOptions?.find((option) => option.value === id);
      return {
        label: item.label,
        disabled: item.disabled,
      };
    }),
  }));

  return data;
}
