import React from "react"
import {Col, Drawer, Form, Input, InputNumber, Radio, Row, Tabs} from "antd"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import { getLanguages } from "src/utils"
import { getFormattedLangData, getInitialValues } from "src/utils/helpers"
import styled from "styled-components"
import {TranslatableInput} from "../../components/Translatable";

export function EditDrawer({ title, initialValues, onClose, onSave }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)
  const languages = getLanguages()

  const { TabPane } = Tabs

  const defaultLang = languages?.[0]?.value
  const [activeTabKey, setActiveTabKey] = React.useState(defaultLang)

  const onTabChange = React.useCallback((key) => {
    setActiveTabKey(key)
  }, [])

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  async function onSubmit(values) {
    const data = getFormattedLangData(values)
    await onSave(data)
    onClose()
    form.resetFields()
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }

  const id = initialValues?.id ?? "new"

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={title}
      onClose={handleClose}
      open
    >
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <StyledFormItem
          key={id + "_isActive"}
          label={"Включен"}
          name={"isActive"}
          initialValue={form.isActive ? form.isActive : true}
          rules={[
            {
              required: true,
              message: "Выберите вариант!",
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            value={form.isActive ? form.isActive : true}
          >
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>

        <StyledFormItem label={"Название"}>
          <TranslatableInput name={"name"} id={id}></TranslatableInput>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_sortWeight"}
            label={"Позиция в списке"}
            name={"sortWeight"}
            rules={[
              {
                required: true,
                message: "Введите позицию!",
              },
            ]}
        >
          <InputNumber
              value={form.sortWeight}
              min={0}
              style={{
                width: "100%",
              }}
              placeholder={"Позиция"}
          />
        </StyledFormItem>

        <ButtonRow>
          <ButtonCol>
            <Button
              danger
              onClick={() => {
                if (initialValues) {
                  form.setFieldsValue(getInitialValues(initialValues))
                  return
                }
                form.resetFields()
              }}
              icon={<RotateClockwiseSvg />}
            >
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button icon={<SaveSvg />} type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>
    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledTabs = styled(Tabs)`
  overflow: visible;

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: none;
    user-select: none;
  }

  &.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 3px 7px 6px;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    bottom: -3px;
    margin-bottom: 0;

    &::before {
      border-bottom: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`
