import {Navigate, NavLink} from "react-router-dom"
import { Avatar, List, Popover } from "antd"
import { LogoutSvg } from "src/components/Svg"
import styled from "styled-components"
import {appLocalStorage} from "../../libs";
import {useAppData} from "../../store/app-data";
import {useEffect, useState} from "react";

import {UNAUTH_MAIN_PAGE} from "../../routing/paths";

export function UserMenu({ isMobile, collapsed }) {

  const [user, setUser] = useState({})

  useEffect(() => {
    if (appLocalStorage.getData("user") && appLocalStorage.getData("user") !== null) {
      setUser(appLocalStorage.getData("user"))
    } else {
      appLocalStorage.clear()
      window.location.reload()
    }
  }, [])

  const { name, email, two_letters } = user

  async function onLogout(e) {
    e.preventDefault()
    e.stopPropagation()
    appLocalStorage.clear()
    window.location.reload()
  }

  const userMenuLinks = [
    <NavLink onClick={onLogout} to={"/dashboard/logout"} key="logout">
      <LogoutSvg /> <LinkLabel>Выход</LinkLabel>
    </NavLink>,
  ]

  const UserMenuContent = (
    <List
      size="small"
      split={false}
      dataSource={userMenuLinks}
      renderItem={(item) => <List.Item>{item}</List.Item>}
    />
  )

  return (
    <Popover
      placement={isMobile ? "top" : "right"}
      content={UserMenuContent}
      trigger="click"
    >
      <UserMenuWrapper>
        <UserAvatar size={36}>{two_letters}</UserAvatar>
        <UserNameWrapper collapsed={collapsed}>
          <UserName>{name}</UserName>
          <UserNameDescription>{email}</UserNameDescription>
        </UserNameWrapper>
      </UserMenuWrapper>
    </Popover>
  )
}

const UserAvatar = styled(Avatar)`
  min-width: 36px;
  background-color: #a0d911;
  color: #fff;
`

const UserMenuWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  white-space: nowrap;
`

const UserNameWrapper = styled.div`
  padding-left: 8px;
  display: ${(props) => (props.collapsed ? "none" : "block")};
`

const UserName = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  word-break: break-word;
`

const UserNameDescription = styled.div`
  word-break: break-all;
`

const LinkLabel = styled.span`
  margin-left: 6px;
`
