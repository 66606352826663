import { Space, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import { PencilSvg } from "src/components/Svg"
import styled from "styled-components"
import {SolutionOutlined} from "@ant-design/icons";
import moment from "moment/moment";

export function getColumns(onEdit) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
    },
    {
      title: "Дата и время отправки",
      dataIndex: "availableAt",
      key: "availableAt",
      render: (text) => (
          <span>{ moment(text).format("YYYY-MM-DD  HH:mm:ss")}</span>
      ),
    },

    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Тело сообщения",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "Пользователей",
      dataIndex: "countUserMailingNotifications",
      key: "countUserMailingNotifications",
      render: (text) => (
          <StyledTag>{text}</StyledTag>
      ),
    },
    {
      title: "Доставили",
      dataIndex: "countUserMailingNotificationsDelivered",
      key: "countUserMailingNotificationsDelivered",
      render: (text) => (
          <StyledTag>{text}</StyledTag>
      ),
    },

    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
          <Space size="small">
            <Tooltip placement="top" title="Подробнее" mouseLeaveDelay={0}>
              <Button size="small" type="link" onClick={() => onEdit(record.id)}>
                <SolutionOutlined style={{ fontSize: "16px" }} />
              </Button>
            </Tooltip>
          </Space>
      ),
    },
  ]
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`