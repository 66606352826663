import {Drawer, Form, Select, Spin} from "antd";
import {useAppData} from "../../store/app-data";
import React from "react";
import {getProductCategoryHints, getEventHints, getLlwHints, getArticlesHints, getPromotionsHints, getProductHintsWithGroup} from "src/utils/index";
import {debounce} from "lodash";
import styled from "styled-components";


export function LinkBuilderDrawer({title, onClose, onBuild}) {
    const layoutConfig = useAppData((state) => state.layoutConfig)

    const [finalLink, setFinalLink] = React.useState("")
    const [categoryState, setCategoryState] = React.useState(false)
    const [productState, setProductState] = React.useState(false)
    const [articlesState, setArticlesState] = React.useState(false)
    const [promotionsState, setPromotionsState] = React.useState(false)
    const [llwState, setLlwState] = React.useState(false)
    const [eventsState, setEvensProductState] = React.useState(false)
    const [smallOptions, setSmallOptions] = React.useState([])
    const [fetching, setFetching] = React.useState(false)
    const [options, setOptions] = React.useState([])
    const fetchRef = React.useRef(0)


    const [form] = Form.useForm()

    const firstOptions = [
                {
                    label: "В каталог",
                    value: "catalogMain"
                },
                {
                    label: "В категорию в каталоге",
                    value: "catalogCategory"
                },
                {
                    label: "В карточку продукта",
                    value: "catalogProduct"
                },
                {
                    label: "В акции",
                    value: "promotionsMain"
                },
                {
                    label: "В новости",
                    value: "articlesMain"
                },
                {
                    label: "В карточку акции",
                    value: "promotionsItem"
                },
                {
                    label: "В карточку новости",
                    value: "articlesItem"
                },
                {
                    label: "В статью",
                    value: "llwItem"
                },
                {
                    label: "В карточку мероприятия",
                    value: "eventsItem"
                },
            ];

    function onSuccessBuild(value) {
        onBuild(value)
        clearState();
    }

    const loadOptions = (value) => {
        fetchRef.current += 1
        const fetchId = fetchRef.current
        setOptions([])
        setFetching(true)
        getProductHintsWithGroup(value).then((newOptions) => {
            const result = []
            newOptions.data.items.forEach((product) => {
                result.push({
                    label: product.name,
                    value: product.id,
                    groupId: product.groupId,
                    categoryId: product.categoryId
                })
            })
            if (fetchId !== fetchRef.current) {
                // for fetch callback order
                return
            }
            setOptions(result)
            setFetching(false)
        })
    }

    function handleFirstSelect(item) {
        clearState()
        if(item.includes("Main")) {
            if(item.includes("catalog")) {
                onSuccessBuild("bushe://catalog")
            } else {
                onSuccessBuild(`bushe://feed/${item.replace("Main", "")}`)
            }
        } else {
            if(item.includes("catalog")) {
                setFinalLink("bushe://catalog/")
                if(item.includes("Category")) {
                    getProductCategoryHints()
                        .then((response) => {
                            let data = [];
                            response.forEach((category) => {
                                data.push({
                                    label: category.name,
                                    value: category.id,
                                    groupId: category.groupProductCategoryIds[0],
                                })
                            })
                            setSmallOptions(data)
                        })
                    setCategoryState(true)
                } else if(item.includes("Product")) {
                    setProductState(true)
                }
            } else {
                setFinalLink(`bushe://feed/${item.replace("Item", "")}/`)
                switch (item.replace("Item", "")) {
                    case "promotions":
                        getPromotionsHints()
                            .then((response) => {
                                let data = [];
                                response.forEach((category) => {
                                    data.push({
                                        label: category.name,
                                        value: category.id,
                                    })
                                })
                                setSmallOptions(data)
                            })
                        setPromotionsState(true)
                        break;
                    case "articles":
                        getArticlesHints()
                            .then((response) => {
                                let data = [];
                                response.forEach((category) => {
                                    data.push({
                                        label: category.name,
                                        value: category.id,
                                    })
                                })
                                setSmallOptions(data)
                            })
                        setArticlesState(true)
                        break;
                    case "llw":
                        getLlwHints()
                            .then((response) => {
                                let data = [];
                                response.forEach((category) => {
                                    data.push({
                                        label: category.name,
                                        value: category.id,
                                    })
                                })
                                setSmallOptions(data)
                            })
                        setLlwState(true)
                        break;
                    case "events":
                        getEventHints()
                            .then((response) => {
                                let data = [];
                                response.forEach((category) => {
                                    data.push({
                                        label: category.name,
                                        value: category.id,
                                    })
                                })
                                setSmallOptions(data)
                            })
                        setEvensProductState(true)
                        break;
                }
            }
        }
    }

    function clearState() {
        setFinalLink("")
        setCategoryState(false)
        setProductState(false)
        setArticlesState(false)
        setPromotionsState(false)
        setLlwState(false)
        setEvensProductState(false)
        setSmallOptions([])
        setOptions([])
    }

    function handleSecondSelect(item) {
        if (categoryState) {
            let findItem = smallOptions.find((element) => element.value === item )
            onSuccessBuild(`${finalLink}${findItem.groupId}/${item}`)
            clearState()
        } else if(productState) {
            let findItem = options.find((element) => element.value === item.value)
            onSuccessBuild(`${finalLink}${findItem.groupId}/${findItem.categoryId}/${item.value}`)
            clearState()
        } else {
            onSuccessBuild(`${finalLink}${item}`)
            clearState()
        }
    }

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={onClose}
            open
        >
            <FormEdit
                form={form}
                layout={"vertical"}
                onFinish={false}
            >
                <StyledFormItem
                    key={"first_link"}
                    label={"Куда переход"}
                >
                    <Select options={firstOptions} onSelect={handleFirstSelect}/>
                </StyledFormItem>

                {categoryState && smallOptions.length > 0 && (
                    <StyledFormItem
                        key={"second_select"}
                        label={"Категория"}
                    >
                        <Select options={smallOptions} onSelect={handleSecondSelect}/>
                    </StyledFormItem>
                )}

                {productState && (
                    <StyledFormItem
                        key={"second_select"}
                        label={"Продукт"}
                    >
                        <Select
                            showSearch={true}
                            labelInValue
                            filterOption={false}
                            onSearch={loadOptions}
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            options={options}
                            onSelect={handleSecondSelect}
                        />
                    </StyledFormItem>
                )}

                {articlesState && smallOptions.length > 0 &&  (
                    <StyledFormItem
                        key={"second_select"}
                        label={"Новость"}
                    >
                        <Select
                            showSearch={true}
                            options={smallOptions}
                            onSelect={handleSecondSelect}
                        />
                    </StyledFormItem>
                )}

                {promotionsState && smallOptions.length > 0 && (
                    <StyledFormItem
                        key={"second_select"}
                        label={"Акции"}
                    >
                        <Select
                            showSearch={true}
                            options={smallOptions}
                            onSelect={handleSecondSelect}
                        />
                    </StyledFormItem>
                )}

                {llwState && smallOptions.length > 0 && (
                    <StyledFormItem
                        key={"second_select"}
                        label={"Статьи"}
                    >
                        <Select
                            showSearch={true}
                            options={smallOptions}
                            onSelect={handleSecondSelect}
                        />
                    </StyledFormItem>
                )}

                {eventsState && smallOptions.length > 0 && (
                    <StyledFormItem
                        key={"second_select"}
                        label={"Мероприятия"}
                    >
                        <Select
                            showSearch={true}
                            options={smallOptions}
                            onSelect={handleSecondSelect}
                        />
                    </StyledFormItem>
                )}

            </FormEdit>

        </DrawerEdit>
    )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`
