import React from "react";
import { Table as AntTable } from "antd";
import { useGetProductConstructor, useManageItems } from "src/pages-lib/product-constructor/model";
import { ExtraButtons } from "src/components/extra-buttons";
import { useUpdateHeaderTitle } from "src/hooks/use-update-header-title";
import { Header } from "src/components/layout/header";
import { EditDrawer } from "src/pages-lib/product-constructor/edit-drawer"
import { VisibilityProvider } from "src/components/visibility-provider"
import { getFormattedItems } from "src/pages-lib/product-constructor/model/use-get-product-constructor"
import { getColumns } from "src/pages-lib/product-constructor/table-columns"
import styled from "styled-components"

export default function ProductConstructor() {
    const [editDrawerState, setEditDrawerState] = React.useState(false)
    /*const [createDrawerState, setCreateDrawerState] = React.useState(false)*/

    const { items, refetch: getItems, isLoading, pagination, handleTableChange } = useGetProductConstructor()

    const { editData, onSaveEdit, onEditItem, onDelete } = useManageItems(
        items,
        getItems,
        onSuccessEdit
    )

    const columns = getColumns(onEditItem, onDelete)


    const headerTitle = useUpdateHeaderTitle({
        items,
        defaultTitle: "0 Записей",
        arrType: ["Записей", "Записей", "Записей"],
    })


    function onSuccessEdit() {
        setEditDrawerState(!editDrawerState)
    }

    return (
        <>
            <Header title={headerTitle} extra={<ExtraButtons
                //onCreate={() => setCreateDrawerState(true)}
                // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                //titles={{ onCreate: "Добавить запись" }}
              />}
            />

            <StyledTable
                dataSource={getFormattedItems(items)}
                columns={columns}
                pagination={pagination}
                loading={isLoading}
                onChange={handleTableChange}
            />

            {/*<VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    onSave={onCreate}
                    onClose={() => setCreateDrawerState(false)}
                    title={"Создание записи"}
                    pointOptions={options.pointOptions}
                    pointProductStatusOptions={options.productPointStatusOptions}
                />
            </VisibilityProvider>*/}

            <VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    onSave={onSaveEdit}
                    onClose={() => setEditDrawerState(false)}
                    title={"Редактирование записи"}
                    initialValues={editData}
                />
            </VisibilityProvider>
        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
  max-width: 100%;
  overflow: hidden;
`
