import React from "react";
import api from "src/api";
import {notification} from "antd";

export function useManageItems(items, getItems, onSuccessEdit) {
    const [editData, setEditData] = React.useState(null);

    function onEditItem(id) {
        const item = items.find((item) => item.id === id);
        const newItem = {
            ...item,
            image: item.image !== "null" ? item.image : null,
        };
        setEditData(item);
        onSuccessEdit();
    }

    async function onCreate(data) {
        try {
            await api.post("users/create-client", data);
            notification.success({ message: "Клиент добавлен" });
        } catch (error) {
            console.log("Ошибка создания", error);
            notification.error({ message: "Ошибка создания" });
            throw error;
        } finally {
            getItems();
        }
    }

    async function onSaveEdit(values) {
        try {
            await api.post(`users/${editData.id}`, values);

            notification.success({ message: "Клиент изменён" });

            onSuccessEdit();
            setEditData(false);
        } catch (error) {
            console.log("Ошибка изменения", error);
            notification.error({ message: "Ошибка изменения" });
            throw error;
        } finally {
            getItems();
        }
    }

    async function onDelete(id) {
        try {
            await api.delete(`users/${id}`);
            notification.success({ message: "Клиент удалён" });
        } catch (error) {
            console.log("Ошибка удаления", error);
            notification.error({ message: "Ошибка удаления" });
            throw error;
        } finally {
            getItems();
        }
    }

    async function handleClearDelete(id) {
        try {
            await api.delete(`user-delete/${id}`);
            notification.success({ message: "Клиент полностью удалён" });
        } catch (error) {
            console.log("Ошибка полного удаления", error);
            notification.error({ message: "Ошибка полного удаления" });
            throw error;
        } finally {
            getItems();
        }
    }

    async function handleUserTasksUpdate(id, values) {
        try {
            await api.post(`user-task-update/${id}`, values);
            notification.success({ message: "Задача добавлена" });
        } catch (error) {
            console.log("Ошибка постановки задачи", error);
            notification.error({ message: "Ошибка постановки задачи" });
            throw error;
        } finally {
            getItems();
        }
    }


    return { editData, onEditItem, onCreate, onSaveEdit, onDelete, handleClearDelete, handleUserTasksUpdate };
}

export function getFormattedItems(items) {
    const data = items.map((item) => ({
        key: item.id,
        id: item.id,
        name: item.dashboardUserInfo?.name ?? null,
        surname: item.dashboardUserInfo?.surname ?? null,
        patronymic: item.dashboardUserInfo?.patronymic ?? null,
        phone: item.phone ?? null,
        email: item.email ?? null,
        birthdayDate: item.dashboardUserInfo?.birthdayDate ?? null,
        sex: item.dashboardUserInfo?.sex ?? null,
        nickname: item.dashboardUserInfo?.nickname ?? null,
        amount: item.dashboardUserInfo?.amount ?? 0,
        bonuses: item.dashboardUserInfo?.bonuses ?? 0,
        isBlocked: item.dashboardUserInfo?.isBlocked ?? false,
        createdAt: item.createdAt,
        deletedAt: item.deletedAt,
    }));

    return data;
}