import {Avatar, Image, Popconfirm, Space, Tag, Tooltip} from "antd";
import { Button } from "src/components/actions/button";
import { PencilSvg, TrashSvg } from "src/components/Svg";
import styled from "styled-components";

export function getColumns(onEdit, onDelete) {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 50,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "ascend",
        },
        {
            title: "Картинка",
            dataIndex: "photo",
            key: "photo",
            width: 50,
            render: (text) => text && (
                    <>
                        <StyledImage
                            src={"https://830204.selcdn.ru/CRM/" + text}
                        />
                    </>
            )
        },
        {
            title: "Название",
            dataIndex: "nameRu",
            key: "nameRu",
            sorter: (a, b) => a.nameRu - b.nameRu,
        },
        {
            title: "Порядок",
            dataIndex: "sortWeight",
            key: "sortWeight",
            sorter: (a, b) => a.sortWeight - b.sortWeight,
        },
        {
            title: "Категории",
            dataIndex: "categories",
            key: "categories",
            render: (data) =>
                data.map((item, i) => (
                    <StyledTag
                        key={i}
                        color={item.disabled ? "default" : "purple"}
                        style={{ opacity: item.disabled ? "50%" : "100%" }}
                    >
                        {item.label}
                    </StyledTag>
                )),
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "price",
            sorter: (a, b) => a.price - b.price,
        },
        {
            title: "Включен",
            dataIndex: "isActive",
            key: "isActive",
            sorter: (a, b) => a.isActive - b.isActive,
            render: (text) => (
                <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
                    {text ? "ДА" : "НЕТ"}
                </StyledTag>
            ),
        },
        {
            title: "Действие",
            key: "action",
            width: 120,
            fixed: "right",
            render: (_, record) => (
                <Space size="small">
                     <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
                        <Button size="small" type="link" onClick={() => onEdit(record.id)}>
                            <PencilSvg />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
                        <Popconfirm
                            placement="left"
                            title="Действительно хотите удалить?"
                            onConfirm={() => onDelete(record.id)}
                            onCancel={() => stopPropagation}
                        >
                            <Button size="small" type="link" onClick={stopPropagation}>
                                <TrashSvg />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];
}

const stopPropagation = (e) => {
    e?.stopPropagation();
};

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`;
const StyledImage = styled(Image)`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
