import {Popconfirm, Space, Tag, Tooltip} from "antd";
import { Button } from "src/components/actions/button";
import { PencilSvg, TrashSvg } from "src/components/Svg";
import styled from "styled-components";

export function getColumns(onEdit, onDelete) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
    },
    {
      title: "Название",
      dataIndex: "nameRu",
      key: "nameRu",
      sorter: (a, b) => a.nameRu - b.nameRu,
    },
    {
      title: "Описание",
      dataIndex: "descriptionRu",
      key: "descriptionRu",
    },
    {
      title: "Количество шагов",
      dataIndex: "stepsCount",
      key: "stepsCount",
      sorter: (a, b) => a.stepsCount - b.stepsCount,
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Версия IOs",
      dataIndex: "versionIOs",
      key: "versionIOs",
      width: 80,
      render: (text) => (
          <StyledTag>{text}</StyledTag>
      ),
    },
    {
      title: "Версия Android",
      dataIndex: "versionAndroid",
      key: "versionAndroid",
      width: 80,
      render: (text) => (
          <StyledTag>{text}</StyledTag>
      ),
    },
    {
      title: "Включен",
      dataIndex: "isActive",
      key: "isActive",
      width: 50,
      render: (text) => (
          <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
            {text ? "ДА" : "НЕТ"}
          </StyledTag>
      ),
    },
    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
            <Popconfirm
              placement="left"
              title="Действительно хотите удалить?"
              onConfirm={() => onDelete(record.id)}
              onCancel={() => stopPropagation}
            >
              <Button size="small" type="link" onClick={stopPropagation}>
                <TrashSvg />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]
}

const stopPropagation = (e) => {
  e?.stopPropagation()
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`

