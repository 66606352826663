import React from "react";
import {DatePicker, Form, Input, Row} from "antd";
import styled from "styled-components";
import {RotateClockwiseSvg, SaveSvg} from "../../components/Svg";
import {Button} from "../../components/actions/button";
import {SearchOutlined} from "@ant-design/icons";

export function PromocodeFilters({handleSearch}) {

    const [form] = Form.useForm()

    function onSearch(values){
        let data = {
            promocode: values.promocode?.trim() ?? null,
            code: values.code?.trim() ?? null,
            activeFrom: values.activeFrom ? values.activeFrom.toISOString() : null,
            activeTo: values.activeTo ? values.activeTo.toISOString() : null
        }
        handleSearch(data)
    }

    function onClear() {
        form.resetFields()
        handleSearch([])

    }

    return (
        <>
           <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSearch(values)}>
                   <StyledFormItem
                       key={ "sort_name" }
                       label={"По названию"}
                       name={"promocode"}
                   >
                       <Input placeholder="Название" />
                   </StyledFormItem>
                   <StyledFormItem
                       key={ "sort_code" }
                       label={"По Коду"}
                       name={"code"}
                   >
                       <Input placeholder="Код"/>
                   </StyledFormItem>
                   <StyledFormItem
                       key={ "sort_active_from" }
                       label={"По активности (от)"}
                       name={"activeFrom"}
                   >
                       <DatePicker />
                   </StyledFormItem>
                   <StyledFormItem
                       key={"sort_active_to"}
                       label={"По активности (до)"}
                       name={"activeTo"}
                   >
                       <DatePicker />
                   </StyledFormItem>
                   <Button
                       danger
                       onClick={onClear}
                       icon={<RotateClockwiseSvg />}
                   >
                       Сбросить
                   </Button>
                   <Button icon={<SearchOutlined />}  type="primary" htmlType="submit">
                       Искать
                   </Button>
           </FormEdit>
        </>
    )
}

const FormEdit = styled(Form)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`

const StyledFormItem = styled(Form.Item)`
  margin-right: 25px;
`