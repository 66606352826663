import {Popconfirm, Space, Table, Tooltip} from "antd";
import {Button} from "../../components/actions/button";
import {CloseSquareOutlined} from "@ant-design/icons";
import styled from "styled-components";
import React from "react";

export function GetExpandedRow({data, clearDelete}) {

    console.log(data)
    return (
        <FlexWrap>
            <Space size="small">
                <Tooltip placement="top" title="Полное удаление" mouseLeaveDelay={0}>
                    <Popconfirm
                        placement="left"
                        title="Действительно хотите удалить?"
                        onConfirm={() => clearDelete(data.id)}
                        onCancel={() => stopPropagation}
                    >
                        <Button size="small" type="link" onClick={() => stopPropagation}>
                            <CloseSquareOutlined style={{ fontSize: "16px" }}/>
                        </Button>
                    </Popconfirm>
                </Tooltip>
            </Space>
            <p style={{ marginBottom: 0}}>Вы собираетесь полностью удалить пользователя! Вернуть пользователя будет не возможно!!! </p>
        </FlexWrap>
    );
}

const FlexWrap = styled("div")`
    padding: 0 0 0 50px;
    display: flex;
    gap: 30px;
    align-items: center;
`

const stopPropagation = (e) => {
    e?.stopPropagation();
};

