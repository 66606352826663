import { Link } from "react-router-dom"
import { Bushe } from "src/components/Svg"
import styled from "styled-components"

export function Logotype() {
  //   let layoutConfig = {
  //     collapsed: false,
  //     siderWidth: 200,
  //     isMobile: false,
  //   }
  return (
    <Logo>
      <StyledLink to={"/dashboard/clients"}>
        <Bushe width={80} height={60} />
      </StyledLink>
    </Logo>
  )
}

const Logo = styled.div`
  position: relative;
  display: flex;
  padding: 5px 5px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s;

  > a {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 64px;
  }

  img {
    display: inline-flex;
    align-self: center;
    height: 32px;
  }

  h1 {
    display: inline-flex;
    margin: 0 12px 0 12px;
    color: @primary-color;
    font-weight: 600;
    font-size: 20px;
    align-self: center;
  }
`

const StyledLink = styled(Link)`
  justify-content: "flex-start";
`

export default Logotype
