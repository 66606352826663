import React from "react";
import { notification } from "antd";
import api from "src/api";
import { getOldImage } from "src/utils/helpers";

export function useManageItems(items, getItems, onSuccessEdit) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id);
    const newItem = formattedEditData(item);
    setEditData(newItem);
    onSuccessEdit();
  }

  async function onSaveEdit(data, id) {
    try {
      await api.post(`user-status-groups/${id}`, data)

      notification.success({ message: "Группа изменена" })
      getItems()
      onSuccessEdit()
      setEditData(false)
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  function formattedEditData(item) {
    const settings = [];

    if(item.settings) {
      Object.entries(JSON.parse(item.settings)).forEach(([key, value], index) => {
        settings.push({
          key: key,
          value:value
        })
      })
    }

    return {
      id: item.id,
      number: item.number,
      name: item.name.find((el) => el.language === "ru")?.content ?? "Нет названия",
      slug: item.slug,
      settings: settings
    }
  }


  return { editData, onEditItem, onSaveEdit }
}
