import React from "react";
import {
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    Row, Select, Upload,
} from "antd";
import { Button } from "src/components/actions/button";
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg";
import { useAppData } from "src/store/app-data";
import {
    getFormData,
    whiteSpaceValidation
} from "src/utils/helpers";
import styled from "styled-components";
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import moment from "moment";
import {useImage} from "../../hooks";

export function GeoUpdateDrawer({
                               title,
                               onClose,
                               onSave,
                               cityOptions
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);

    const [form] = Form.useForm();

    const { getImage } = useImage();

    function prepareData(values) {
        const file = values.geo ? getImage(values.geo) : null;

        const data = {
            cityId: values.cityId,
            ...(file && {geo: file})
        };

        return data;
    }

    async function onSubmit(values) {

        const baseData = prepareData(values)

        const formData = getFormData({
            values: baseData,
            baseData: baseData.file
        })

        await onSave(formData);
        onClose();
        form.resetFields();
    }

    function handleClose() {
        onClose();
        form.resetFields();
    }

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={handleClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>

                <StyledFormItem
                    key={"cityId"}
                    label={"Город"}
                    name={"cityId"}
                    rules={[
                        {
                            required: true,
                            message: "Выберите город!",
                        },
                    ]}
                >
                    <Select options={cityOptions} />
                </StyledFormItem>

                <StyledFormItem
                    name={"geo"}
                    label={"Файл"}
                    key={"geo"}
                >
                    <Upload
                        accept={".geojson"}
                        beforeUpload={() => false}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
                    </Upload>
                </StyledFormItem>

                <ButtonRow>
                    <ButtonCol>
                        <Button
                            danger
                            onClick={() => {
                                form.resetFields();
                            }}
                            icon={<RotateClockwiseSvg />}
                        >
                            Вернуть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button onClick={handleClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                            Отправить
                        </Button>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>
        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`;

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`;

const RowFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`

