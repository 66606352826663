import {useGetCertificates} from "../pages-lib/certificate/model";
import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import React from "react";
import styled from "styled-components";
import {getFormattedItems, useManageItems} from "../pages-lib/certificate/model/use-manage-items";
import CertificateTable from "../pages-lib/certificate/certificate-table";
import {CertificateFilters} from "../pages-lib/certificate/certificate-filters";
import {VisibilityProvider} from "../components/visibility-provider";
import {ResendAnotherEmailDrawer} from "../pages-lib/certificate/another-email-drawer";


export default function Certificates() {
     const [certificateId, setCertificateId] = React.useState(false)
     const [resendDrawerState, setResendDrawerState] = React.useState(false)

    const { items, pagination, isLoading, refetch: getItems, handleTableChange, handleSearchTable} = useGetCertificates();

    const { resendEmail } = useManageItems();

    const headerTitle = useUpdateHeaderTitle({
        items: items,
        defaultTitle: "0 Сертификатов",
        arrType: ["Сертификат", "Сертификата", "Сертификатов"],
    });

    function openResendToEmail(id) {
        setCertificateId(id)
        setResendDrawerState(true)
    }

    function closeResendDrawer() {
        setResendDrawerState(false)
        setCertificateId(false)
    }

    return (
        <>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        //onCreate={() => setCreateDrawerState(!createDrawerState)}
                        // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                        // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                    />
                }
            />

            <CertificateFilters handleSearch={handleSearchTable} />

            <CertificateTable
                dataSource={getFormattedItems(items)}
                onResend={resendEmail}
                openResendToEmail={openResendToEmail}
                pagination={pagination}
                loading={isLoading}
                onChange={handleTableChange}
            />

            <VisibilityProvider visible={resendDrawerState}>
                <ResendAnotherEmailDrawer
                    title={"Отправка на другой Email"}
                    onClose={closeResendDrawer}
                    certificateId={certificateId}
                    resendEmail={resendEmail}
                />
            </VisibilityProvider>
        </>
    )
}
