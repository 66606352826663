import React from "react"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import {
  Col,

  Divider,
  Drawer,
  Form,
  Image, Input,
  Radio,
  Row,
  Typography,
  Upload,
} from "antd"
import moment from "moment"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import {getFormattedLangData, maxFileSizeValidation, whiteSpaceValidation} from "src/utils/helpers"
import styled from "styled-components"
import {StoriesLinkBuilder} from "src/pages-lib/stories/stories-link-builder";
import {VisibilityProvider} from "../../components/visibility-provider";
import {TranslatableInput, TranslatableTextarea} from "../../components/Translatable";

const { Title } = Typography
const { TextArea } = Input

export function EditOnboarding({ title, state, initialValues, onClose, onSave }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)
  const [previewImage, setPreviewImage] = React.useState(null)
  const [imageUpdated, setImageUpdated] = React.useState(false)
  const [updatedImagesIds, setUpdatedImagesIds] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorLength, setErrorLength] = React.useState(false)
  const [linkBuildState, setLinkBuildState] = React.useState(false)

  const [form] = Form.useForm()

  function getImage(files) {
    const file = files?.file ?? files?.fileList?.[0] ?? files[0]
    if (file instanceof File) {
      file.url = URL.createObjectURL(file)
    }
    return file
  }

  function getSteps(steps) {
    const newSteps = steps.map((step) => ({
      ...step,
      image: getImage(step.image),
      imageUpdated: step.id ? (updatedImagesIds.includes(step.id) ? 1 : 0) : 1,
    }))

    if (!initialValues) {
      return newSteps
    }

    const deleted = initialValues.steps
        .filter((step) => !newSteps.find((newStep) => newStep.id === step.id))
        .map((step) => ({ ...step, isDeleted: 1 }))

    return [...newSteps, ...deleted]
  }

  async function onSubmit(values) {
    if (values.steps?.length > 0) {
      setErrorLength(false)
      const id = initialValues?.id

      const langData = getFormattedLangData(values)

      const data = {
        ...(id && { id, _method: "PUT" }),
        name: langData.name,
        description: langData.description,
        ...(id && { id }),
        isActive: values.isActive,
        versionIOs: values.versionIOs,
        versionAndroid: values.versionAndroid,
      }

      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => {

        if (key === "name" || key === "description") {
          formData.append(`${key}[0][language]`, "ru")
          formData.append(`${key}[0][content]`, value)
        } else {
          formData.append(key, value)
        }

      })

      const newSteps = getSteps(values.steps)
      newSteps.forEach((step, index) => {
        Object.entries(step).forEach(([key, value]) => {
          if(key === "name" || key === "description") {
            formData.append(`steps[${index}][${key}][0][language]`, "ru")
            formData.append(`steps[${index}][${key}][0][content]`, value)
          } else {
            formData.append(`steps[${index}][${key}]`, value)
          }

        })
      })

      const isPut = data._method === "PUT" && data.id

      setIsLoading(true)

      try {
        await onSave(formData, data.id, isPut)
        form.resetFields()
        handleClose()
      } finally {
        setIsLoading(false)
      }
    } else {
      setErrorLength(true)
    }

  }

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues)
    }
  }, [initialValues])

  const id = initialValues?.id ?? "new"

  if (!state) {
    return null
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }

  async function onPreview(file) {
    if (file instanceof File) {
      file.url = await URL.createObjectURL(file)
    }
    setPreviewImage(file)
  }

  function onChange(e) {
    setImageUpdated(true)
    return e
  }

  function onChangeImage(e, key) {
    const id = form.getFieldValue("steps")?.[key]?.id
    if (id) {
      setUpdatedImagesIds([...updatedImagesIds, id])
    }
    return e
  }

  async function onReset() {
    if (initialValues) {
      await form.resetFields()
      await form.setFieldsValue(initialValues)
      return
    }
    form.resetFields()
  }


  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "50%"}
          title={title}
          onClose={handleClose}
          visible={state}
      >
        <StyledImage
            alt={previewImage?.name || "Превью"}
            preview={{
              visible: previewImage,
              onVisibleChange: () => setPreviewImage(null),
            }}
            src={previewImage?.url || previewImage?.thumbUrl}
        />
        <FormEdit
            form={form}
            layout={"vertical"}
            onFinish={(values) => onSubmit(values)}
        >
          <p><strong>Вы можете загрузить изображения не превышающие в сумме 15мб, и не больше 5мб каждое</strong></p>
          <Divider orientation="left">Данные онбординга</Divider>
            <StyledFormItem
                key={id + "_name"}
                label={"Название"}
                name={"name"}
                rules={[
                  {
                    required: true,
                    message: "Введите название!",
                  },
                  {
                    validator: whiteSpaceValidation,
                  },
                ]}
            >
              <Input maxLength={255}/>
            </StyledFormItem>

            <StyledFormItem
                key={id + "_description"}
                label={"Описание"}
                name={"description"}
                rules={[
                  {
                    required: true,
                    message: "Введите описание!",
                  },
                  {
                    validator: whiteSpaceValidation,
                  },
                ]}
            >
              <TextArea
                  autoSize={{
                    minRows: 3,
                    maxRows: 5,
                  }}
                  maxLength={1050}
                  type={"text"}
                  placeholder={"Описание"}
              />
            </StyledFormItem>

            <StyledFormItem
                key={id + "_isActive"}
                label={"Флаг активности"}
                name={"isActive"}
                initialValue={form.isActive ? form.isActive : true}
                rules={[
                  {
                    required: true,
                    message: "Please select!",
                  },
                ]}
            >
              <Radio.Group buttonStyle="solid" value={form.isActive}>
                <Radio.Button value={true}>Да</Radio.Button>
                <Radio.Button value={false}>Нет</Radio.Button>
              </Radio.Group>
            </StyledFormItem>

            <StyledFormItem
                key={id + "_versionAndroid"}
                label={"Версия Android"}
                name={"versionAndroid"}
                rules={[
                  {
                    required: true,
                    message: "Введите версию Android!",
                  },
                  {
                    validator: whiteSpaceValidation,
                  },
                ]}
            >
              <Input
                  value={form.versionAndroid}
                  type={"text"}
                  placeholder={"Версия Android"}
              />
            </StyledFormItem>

            <StyledFormItem
                key={id + "_versionIOs"}
                label={"Версия IOs"}
                name={"versionIOs"}
                rules={[
                  {
                    required: true,
                    message: "Введите версию IOs!",
                  },
                  {
                    validator: whiteSpaceValidation,
                  },
                ]}
            >
              <Input
                  value={form.versionIOs}
                  type={"text"}
                  placeholder={"Версия IOs"}
              />
            </StyledFormItem>




          <Divider orientation="left">Шаги</Divider>
          { errorLength && (<>
            <div style={{ color: "red" }}>Добавьте хотя-бы 1-ин шаг!</div>
          </>)}
          <Form.List name="steps">
            {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...field }, index) => (
                      <React.Fragment key={`RowTitle_${index}`}>
                        <RowTitle level={4}>
                          Шаг {key + 1}{" "}
                          {fields.length > 0 ? (
                              <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  style={{ marginLeft: "12px" }}
                                  onClick={() => {
                                    remove(name)
                                  }}
                              />
                          ) : null}
                        </RowTitle>
                        <Form.Item required={false} key={key}>
                          <StyledFormItem
                              name={[name, "image"]}
                              rules={[
                                {
                                  required: initialValues?.steps?.[key]?.id ? updatedImagesIds.includes(initialValues?.steps?.[key]?.id) : true,
                                  message: "Выберите изображение!",
                                },
                                {
                                  validator: maxFileSizeValidation
                                }
                              ]}
                          >
                            <Upload
                                listType="picture-card"
                                maxCount={1}
                                onPreview={onPreview}
                                accept="image/jpeg,image/png,image/jpg"
                                maxSize={4000}
                                status="done"
                                beforeUpload={() => false}
                                onChange={(e) => onChangeImage(e, key)}
                                defaultFileList={initialValues?.steps?.[key]?.image}
                            >
                              <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Загрузить изображение</div>
                              </div>
                            </Upload>
                          </StyledFormItem>
                          <StyledFormItem
                              {...field}
                              key={id + "_name"}
                              label={"Название шага"}
                              name={[name, "name"]}
                              rules={[
                                {
                                  message: "Введите название!",
                                  required: true
                                },
                                {
                                  validator: whiteSpaceValidation,
                                },
                              ]}
                          >
                            <Input
                                type={"text"}
                                placeholder={"Название"}
                                maxLength={255}
                            />
                          </StyledFormItem>
                          <StyledFormItem
                              {...field}
                              key={id + "_description"}
                              label={"Описание шага"}
                              name={[name, "description"]}
                              rules={[
                                {
                                  message: "Введите название!",
                                  required: true
                                },
                                {
                                  validator: whiteSpaceValidation,
                                },
                              ]}
                          >
                            <TextArea
                                autoSize={{
                                  minRows: 3,
                                  maxRows: 5,
                                }}
                                maxLength={1050}
                                type={"text"}
                                placeholder={"Описание"}
                            />
                          </StyledFormItem>

                        </Form.Item>
                      </React.Fragment>
                  ))}

                  {fields.length < 6 && (
                      <Row gutter={28}>
                        <Col className="gutter-row" span={12}>
                          <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: "100%" }}
                                icon={<PlusOutlined />}
                            >
                              Добавить
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                  )}

                </>
            )}
          </Form.List>

          <ButtonRow>
            <ButtonCol>
              <Button danger onClick={onReset} icon={<RotateClockwiseSvg />}>
                Вернуть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button onClick={handleClose} icon={<CloseSvg />}>
                Закрыть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button
                  icon={<SaveSvg />}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
              >
                Сохранить
              </Button>
            </ButtonCol>
          </ButtonRow>
        </FormEdit>

        <VisibilityProvider visible={linkBuildState}>
          <StoriesLinkBuilder
              title={"Новая ссылка"}
              onCloseDrawer={setLinkBuildState}
          />
        </VisibilityProvider>

      </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledImage = styled(Image)`
  display: none;
`

const RowFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`

const RowTitle = styled(Title)`
  margin-bottom: 20px;
`
