import React from "react";
import {
    Drawer, Table as AntTable, Table,
    Typography,
} from "antd";
import { useAppData } from "src/store/app-data";
import styled from "styled-components";
import {getColumns} from "./info-column";
import moment from "moment";


const { Title } = Typography
export function InfoDrawer({
                             dataItem,
                             title,
                             onClose,
                           }) {

  const layoutConfig = useAppData((state) => state.layoutConfig);

    const columns = getColumns()

  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "80%"}
          title={title}
          onClose={onClose}
          open
      >
          <FlexWrap style={{ margin: "20px 0"}}>
              <TitleText>
                  <div style={{ fontWeight: "600"}}>Заголовок:</div>
                  { " " + dataItem.title }
              </TitleText>
              <BodyText>
                  <div style={{ fontWeight: "600"}}>Текст:</div>
                  { " " + dataItem.body }
              </BodyText>
              <SendTime>
                  <div style={{ fontWeight: "600"}}>Отправить в:</div>
                  { moment(dataItem.availableAt).format("YYYY-MM-DD  HH:mm:ss")}</SendTime>
          </FlexWrap>
          <StyledTable
              dataSource={dataItem.userMailingNotifications}
              columns={columns}
              pagination={false}
          />

      </DrawerEdit>
  );
}

const DrawerEdit = styled(Drawer)``;

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
  .ant-table-row{
    background: #ffffff;
  }
  max-width: 100%;
  overflow: scroll;
`;

const FlexWrap = styled("div")`
    display: flex;
    gap: 30px;
`

const TitleText = styled("div")`
    width: 40%;
    font-size: 16px;
`
const BodyText = styled("div")`
    width: 40%;
    font-size: 16px;
`
const SendTime = styled("div")`
    width: 15%;
    font-size: 16px;
`


