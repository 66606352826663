import React from "react"
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons"
import {
  Col, Divider,
  Drawer,
  Form,
  Image,
  Input, InputNumber,
  Radio,
  Row,
  Select, Spin,
  Tabs,
  Upload,
} from "antd"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import {
  TranslatableInput,
  TranslatableTextarea,
  TranslatableWysiwyg,
} from "src/components/Translatable"
import { useImage } from "src/hooks/use-image"
import { useAppData } from "src/store/app-data"
import {
  getFormattedLangData,
  getFormData,
  getInitialValues,
  getNestedFormData,
  getOldImage, whiteSpaceValidation,
} from "src/utils/helpers"
import styled from "styled-components"
import {LinkBuilder} from "../../components/link-builder/link-builder";
import {getProductHintsWithGroup} from "../../utils";
import api from "../../api";

export function EditDrawer({
                             title,
                             initialValues,
                             onClose,
                             onSave
                           }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  const [options, setOptions] = React.useState([])
  const fetchRef = React.useRef(0)
  const [fetching, setFetching] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)


  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  const [form] = Form.useForm()

  const { getImage, imageUpdated, onPreview, onChange, preview } = useImage()

  function prepareData(values) {
    const file = getImage(values.image)
    const id = initialValues?.id
    const isNeedImage = !id || (id && imageUpdated)

    const data = {
      ...(id && { id, _method: "PUT" }),
      imageUpdated: id ? (imageUpdated ? 1 : 0) : 1,
      ...(isNeedImage && { image: file }),
    }

    return data
  }

  const loadRewardOptions = (value) => {
    fetchRef.current += 1
    const fetchId = fetchRef.current
    setOptions([])
    setFetching(true)
    const rewards = api.get(`loyalty-rewards-hints?query=${value}`)

    rewards.then((newOptions) => {
      const result = []
      newOptions.data.items.forEach((reward) => {
        result.push({
          label: reward.name.find((el) => el.language === "ru").content,
          value: reward.id
        })
      })
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return
      }
      setOptions(result)
      setFetching(false)
    })
  }

  async function onSubmit(values) {
    const baseData = prepareData(values)
    const formData = getFormData({
      values: baseData,
      image: baseData.imageUpdated && baseData.image,
    })

    const data = getFormattedLangData(values)

    const dataWithNestedItem = {
      rewardIds: data.rewardIds.map((item) => item.value)
    }

    const formDataWithNestedFormData = getNestedFormData(
        dataWithNestedItem,
        formData
    )

    await onSave(formDataWithNestedFormData, initialValues?.id)
    onClose()
    form.resetFields()
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }

  const id = initialValues?.id ?? "new"

  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "80%"}
          title={title}
          onClose={handleClose}
          open
      >
        <FormEdit
            form={form}
            layout={"vertical"}
            onFinish={(values) => onSubmit(values)}
        >

          <StyledFormItem
              name={"image"}
              label={"Обложка"}
              rules={[
                {
                  required: !imageUpdated,
                  message: "Выберите изображение!",
                },
              ]}
          >
            <Upload
                listType="picture-card"
                maxCount={1}
                onPreview={onPreview}
                accept="image/png"
                status="done"
                beforeUpload={() => false}
                onChange={onChange}
                defaultFileList={getOldImage(initialValues?.image)}
                dependencies={"image"}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Загрузить изображение</div>
              </div>
            </Upload>
          </StyledFormItem>

          <StyledImage
              alt={preview.previewImage?.name || "Превью"}
              preview={{
                visible: preview.visible,
                onVisibleChange: preview.onVisibleChange,
              }}
              src={preview.previewImage?.largePreview || preview.previewImage?.url}
          />

          <StyledFormItem
              label={"Награды"}
              key={id + "_rewardIds"}
              name={"rewardIds"}
          >
            <Select
                labelInValue
                mode="multiple"
                showSearch={true}
                filterOption={false}
                onSearch={loadRewardOptions}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                options={options}
            />
          </StyledFormItem>

          <ButtonRow>
            <ButtonCol>
              <Button
                  danger
                  onClick={() => {
                    if (initialValues) {
                      form.resetFields(["image"])
                      form.setFieldsValue(getInitialValues(initialValues))
                      return
                    }
                    form.resetFields()
                  }}
                  icon={<RotateClockwiseSvg />}
              >
                Вернуть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button onClick={handleClose} icon={<CloseSvg />}>
                Закрыть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button
                  icon={<SaveSvg />}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
              >
                Сохранить
              </Button>
            </ButtonCol>
          </ButtonRow>
        </FormEdit>
      </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`
const StyledImage = styled(Image)`
  display: none;
`