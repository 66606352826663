import React from "react";
import api from "src/api";

export function useGetClients() {
    const [pagination, setPagination] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);
    //const [cityOptions, setCityOptions] = React.useState([]);
    //const [categoryOptions, setCategoryOptions] = React.useState([]);

    async function getItems(page = 1, countOnPage = 20, filters = "") {
        setIsLoading(true);
        try {
            const response = await api.get(`users?page=${page}&countOnPage=${countOnPage}${filters}`);

            setPagination({
                current: response.data.pagination.currentPage,
                pageSize: response.data.pagination.countOnPage,
                total: response.data.pagination.total
            });
            setItems(response.data.items);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleTableChange = (pagination) => {
        getItems(pagination.current, pagination.pageSize )
    };

    function handleSearchTable(filters) {
        let filters_str = ""
        let count = 0;
        for (let [key, value] of Object.entries(filters)) {

            if(value) {
                switch (key) {
                    case "phone":
                        filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=phone&filters[${count}][v]=${value}`
                        break;
                    case "email":
                        filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=email&filters[${count}][v]=${value}`
                        break;
                    case "dateFrom":
                        filters_str += `&filters[${count}][t]=more&filters[${count}][f]=created_at&filters[${count}][v]=${value}`
                        break;
                    case "dateTo":
                        filters_str += `&filters[${count}][t]=less&filters[${count}][f]=created_at&filters[${count}][v]=${value}`
                        break;
                }
            }

            count++
        }
        getItems(1,  20, filters_str)
    }

    React.useEffect(() => {
        getItems();
    }, []);

    return {
        items,
        pagination,
        isLoading,
        refetch: () => getItems(),
        handleTableChange,
        handleSearchTable
    };
}