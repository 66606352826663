import Icon from '@ant-design/icons'

function SvgIcon ({ stroke = 'currentColor', strokeWidth = 1.5, size = 20 }) {
    return <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        strokeWidth={strokeWidth}
        stroke={stroke}
        fill='none' strokeLinecap='round' strokeLinejoin='round'
    >
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <path d='M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2' />
        <path d='M7 12h14l-3 -3m0 6l3 -3' />
    </svg>
}

export function LogoutSvg(props: any) {
    return <Icon
        component={SvgIcon}
        {...props}
    />
}
