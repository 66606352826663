import React from "react"
import { notification } from "antd"
import moment from "moment"
import api from "src/api"

export function useManageItems(items, getItems, onSuccessEdit,onSuccessGeoUpdate) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id)
    const newItem = {
      ...item,
      image: item.image !== "null" ? item.image : null,
      timeForHurryUp: moment(item.timeForHurryUp, "HH:mm:ss"),
      timeStartForDelivery: moment(item.timeStartForDelivery, "HH:mm:ss"),
      timeEndForDelivery: moment(item.timeEndForDelivery, "HH:mm:ss"),
      stepTimeDelivery: moment(item.stepTimeDelivery, "HH:mm:ss"),
      preorderIntervals: item.preorderIntervals.map((item) => ({
        start: moment(item.start, "HH:mm:ss"),
        end: moment(item.end, "HH:mm:ss"),
      })),
      schedule: item.schedule.map((item) => ({
        ...item,
        startTime: moment(item.startTime, "HH:mm"),
        endTime: moment(item.endTime, "HH:mm"),
        lunchTimeStart: item.lunchTimeStart
          ? moment(item.lunchTimeStart, "HH:mm")
          : null,
        lunchTimeEnd: item.lunchTimeEnd ? moment(item.lunchTimeEnd, "HH:mm") : null,
      })),
    }
    setEditData(newItem)
    onSuccessEdit()
  }

  async function onSaveEdit(values) {
    try {
      await api.put(`points/${editData.id}`, values)

      notification.success({ message: "Точка изменена" })

      onSuccessEdit()
      setEditData(false)
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    } finally {
      getItems()
    }
  }

  async function onUpdateGeo(values) {
    try {
      await api.post("delivery-area/geo-update", values)

      notification.success({ message: "Данные обновлены" })

      onSuccessGeoUpdate()
      setEditData(false)
    } catch (error) {
      console.log("Ошибка обновления", error)
      notification.error({ message: "Ошибка обновления" })
      throw error
    } finally {
      getItems()
    }
  }

  return { editData, onSaveEdit, onEditItem, onUpdateGeo }
}
