import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks/use-update-header-title"
import { EditDrawer } from "src/pages-lib/points/edit-drawer"
import { useGetPoints, useManageItems } from "src/pages-lib/points/model"
import { getFormattedItems } from "src/pages-lib/points/model/use-get-points"
import { getColumns } from "src/pages-lib/points/table-columns"
import styled from "styled-components"
import {CompassOutlined} from "@ant-design/icons";
import {Button} from "../components/actions/button";
import {GeoUpdateDrawer} from "../pages-lib/points/geo-update-drawer";
import {PointFilers} from "../pages-lib/points/filters";

export default function Points() {
  const [editDrawerState, setEditDrawerState] = React.useState(false)
  const [geoUpdateDrawerState, setGeoUpdateDrawerState] = React.useState(false)

  const { items, refetch: getItems, isLoading, pagination, options, handleTableChange, handleSearchTable } = useGetPoints()
  const { editData, onSaveEdit, onEditItem, onUpdateGeo } = useManageItems(
    items,
    getItems,
    onSuccessEdit,
    onSuccessGeoUpdate
  )

  const headerTitle = useUpdateHeaderTitle({
    items,
    defaultTitle: "0 Точек",
    arrType: ["Точка", "Точки", "Точек"],
  })

  const [tableParams] = React.useState({
    pagination: pagination ?? {
      current: 1,
      pageSize: 30,
    },
  })

  const columns = getColumns(onEditItem)

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

    function onSuccessGeoUpdate() {
        setGeoUpdateDrawerState(!geoUpdateDrawerState)
    }

  return (
    <>
      <Header title={headerTitle} extra={<ExtraButtons />} />

      <StyledButton color={"#808080"} onClick={() => setGeoUpdateDrawerState(true)} icon={
          <CompassOutlined style={{ fontSize: "16px"}}/>}>
        {"Обновить карту доставки" }
      </StyledButton>

      <PointFilers handleSearch={ handleSearchTable } />

      <StyledTable
        dataSource={getFormattedItems(items)}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onSave={onSaveEdit}
          onClose={() => setEditDrawerState(!editDrawerState)}
          initialValues={editData}
          title={"Редактирование точки"}
          pointsFeatures={options.pointsFeatures}
          deliveryTypes={options.deliveryTypes}
          paymentTypes={options.paymentTypes}
          paymentAccounts={options.paymentAccounts}
        />
      </VisibilityProvider>

        <VisibilityProvider visible={geoUpdateDrawerState}>
            <GeoUpdateDrawer
                onSave={onUpdateGeo}
                onClose={() => setGeoUpdateDrawerState(!geoUpdateDrawerState)}
                title={"Обновление данных по зонам доставки"}
                cityOptions={options.cityOptions}
            />
        </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`

const StyledButton = styled(Button)`
    margin: 10px 0 20px 0 ;
`;

