import React from "react"
import { notification } from "antd"
import api from "src/api"

export function useManageItems(items, getItems, onSuccessEdit, onSuccessCreate) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id)

    setEditData(item)
    onSuccessEdit()
  }

  async function onDelete(id) {
    try {
      await api.delete(`promo-codes/${id}`)

      notification.success({ message: "Промокод удалён" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  async function onCreate(values) {
    try {
      await api.post("promo-codes", values)

      notification.success({ message: "Промокод добавлен" })
      onSuccessCreate()
      getItems()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }

  async function onSaveEdit(values) {
    try {
      await api.put(`promo-codes/${editData.id}`, values)

      notification.success({ message: "Промокод изменён" })
      onSuccessEdit()
      getItems()
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  return { editData, onSaveEdit, onCreate, onDelete, onEditItem }
}
