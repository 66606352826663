import React from "react"
import api from "src/api"

export function useGetOnboardings() {

  const [pagination, setPagination] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState([])

  async function getItems(page = 1, countOnPage = 20) {
    setIsLoading(true);
      try {
        const { data } = await api.get(`onboarding?page=${page}&countOnPage=${countOnPage}`)
          setPagination({
            current: data.pagination.currentPage,
            pageSize: data.pagination.countOnPage,
            total: data.pagination.total
          });
        setItems(data.items)

        setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleTableChange = (pagination) => {
    getItems(pagination.current, pagination.pageSize )
  };

  React.useEffect(() => {
    getItems()
  }, [])

  return { items, pagination, refetch: () => getItems(), isLoading, handleTableChange }
}
