import {Avatar, Image, Popconfirm, Space, Tag, Tooltip} from "antd";
import { Button } from "src/components/actions/button";
import { PencilSvg, TrashSvg } from "src/components/Svg";
import styled from "styled-components";

export function getColumns(onEdit, onDelete) {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 50,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "ascend",
        },
        {
            title: "Товар",
            dataIndex: "productNameRu",
            key: "productNameRu",
            sorter: (a, b) => a.productNameRu - b.productNameRu,
        },
        {
            title: "Точка",
            dataIndex: "pointNameRu",
            key: "pointNameRu",
            sorter: (a, b) => a.pointNameRu - b.pointNameRu,
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (_, record) => (
                    <StyledTag
                        key={record.id + record.status}
                        color={"purple"}
                    >
                        {record.status}
                    </StyledTag>
            ),
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "price",
            sorter: (a, b) => a.price - b.sortWeight,
        },
        {
            title: "Цена на доставку",
            dataIndex: "price",
            key: "price",
            sorter: (a, b) => a.price - b.sortWeight,
        },
        {
            title: "Безлимитно",
            dataIndex: "isUnlimited",
            key: "isUnlimited",
            sorter: (a, b) => a.isUnlimited - b.isUnlimited,
            render: (text) => (
                <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
                    {text ? "ДА" : "НЕТ"}
                </StyledTag>
            ),
        },
        {
            title: "Остаток",
            dataIndex: "balanceOfGoods",
            key: "balanceOfGoods",
            sorter: (a, b) => a.price - b.sortWeight,
        },
        {
            title: "Действие",
            key: "action",
            width: 120,
            fixed: "right",
            render: (_, record) => (
                <Space size="small">
                     <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
                        <Button size="small" type="link" onClick={() => onEdit(record.id)}>
                            <PencilSvg />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
                        <Popconfirm
                            placement="left"
                            title="Действительно хотите удалить?"
                            onConfirm={() => onDelete(record.id)}
                            onCancel={() => stopPropagation}
                        >
                            <Button size="small" type="link" onClick={stopPropagation}>
                                <TrashSvg />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];
}

const stopPropagation = (e) => {
    e?.stopPropagation();
};

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`;
const StyledImage = styled(Image)`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
