import { Popconfirm, Space, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import { PencilSvg, TrashSvg } from "src/components/Svg"
import styled from "styled-components"

export function getColumns(onEdit) {
  return [
    {
      title: "Номер уровня",
      dataIndex: "number",
      key: "number",
      width: 100,
      sorter: (a, b) => a.number - b.number,
      defaultSortOrder: "ascend",
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Системное название",
      dataIndex: "slug",
      key: "slug"
    },
    {
      title: "Группа",
      dataIndex: "group",
      key: "group",
      render: (text) => (
          <StyledTag>{text}</StyledTag>
      ),
    },
    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ]
}


const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`