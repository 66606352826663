import { AppAuth } from "src/routing/app-auth-provider"
import { RouterProvider } from "src/routing/router-provider"

import "./App.less"

function App() {
  return (
    <AppAuth>
      <RouterProvider />
    </AppAuth>
  )
}

export default App
