import Icon from '@ant-design/icons'

function SvgIcon ({ stroke = 'currentColor', strokeWidth = 1.5, size = 20 }) {
    return <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        strokeWidth={strokeWidth}
        stroke={stroke}
        fill='none' strokeLinecap='round' strokeLinejoin='round'
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="9" y1="15" x2="15" y2="9" />
        <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />
        <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />
        <circle cx="12" cy="12" r="9" />
    </svg>
}

export function DiscountSvg(props: any) {
    return <Icon
        component={SvgIcon}
        {...props}
    />
}