import { Space, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import { PencilSvg } from "src/components/Svg"
import styled from "styled-components"

export function getColumns(onEdit) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
    },
    {
      title: "Название",
      dataIndex: "nameRu",
      key: "nameRu",
      sorter: (a, b) => a.nameRu - b.nameRu,
    },
    {
      title: "Сокращенное название",
      dataIndex: "shortNameRu",
      key: "shortNameRu",
      sorter: (a, b) => a.shortNameRu - b.shortNameRu,
    },
    {
      title: "Пункт выдачи",
      dataIndex: "isPickup",
      key: "isPickup",
      sorter: (a, b) => a.isPickup - b.isPickup,
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },
    {
      title: "Доставка",
      dataIndex: "isDelivery",
      key: "isDelivery",
      sorter: (a, b) => a.isDelivery - b.isDelivery,
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },
    {
      title: "Включен",
      dataIndex: "isActive",
      key: "isActive",
      sorter: (a, b) => a.isActive - b.isActive,
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },
    {
      title: "Видимость",
      dataIndex: "isShow",
      key: "isShow",
      sorter: (a, b) => a.isShow - b.isShow,
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "Виден" : "Не виден"}
        </StyledTag>
      ),
    },

    {
      title: "Действие",
      key: "action",
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ]
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`
