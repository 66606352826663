import React from "react";
import api from "src/api";
import {notification} from "antd";
import moment from "moment/moment";

export function useManageItems(items, getItems, onSuccessEdit) {
    const [editData, setEditData] = React.useState(null);

    function onEditItem(id) {
        const item = items.find((item) => item.id === id);
        const newItem = formmatedEditData(item)
        setEditData(newItem);
        onSuccessEdit();
    }

    const formmatedEditData = (item) => {
        const productId = {
                label: item.product.name?.find((el) => el.language === "ru")?.content,
                value: item.productId,
            }
        return {
            key: item.id,
            id: item.id,
            pointId: item.point.id,
            productId: productId,
            balanceOfGoods: item.balanceOfGoods,
            deliveryPrice: item.deliveryPrice,
            price: item.price,
            isUnlimited: item.isUnlimited,
            status: item.status
        }
    }
    async function onCreate(data) {
        try {
            await api.post("product-point", data);
            notification.success({ message: "Запись добавлена" });
        } catch (error) {
            console.log("Ошибка создания", error);
            notification.error({ message: "Ошибка создания" });
            throw error;
        } finally {
            getItems();
        }
    }

    async function onSaveEdit(values) {
        try {
            await api.post(`product-point/${editData.id}`, values);

            notification.success({ message: "Запись изменёна" });

            onSuccessEdit();
            setEditData(false);
        } catch (error) {
            console.log("Ошибка изменения", error);
            notification.error({ message: "Ошибка изменения" });
            throw error;
        } finally {
            getItems();
        }
    }

    async function onDelete(id) {
        try {
            await api.delete(`product-point/${id}`);
            notification.success({ message: "Запись удалёна" });
        } catch (error) {
            console.log("Ошибка удаления", error);
            notification.error({ message: "Ошибка удаления" });
            throw error;
        } finally {
            getItems();
        }
    }


    return { editData, onEditItem, onCreate, onSaveEdit, onDelete };

}