import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks"
import { EditPromocodes } from "src/pages-lib/promo-codes/edit-promocodes"
import { useGetPromoCodes, useManageItems } from "src/pages-lib/promo-codes/model"
import { getColumns } from "src/pages-lib/promo-codes/table-columns"
import styled from "styled-components"
import {PromocodeFilters} from "src/pages-lib/promo-codes/filters";

export default function PromoCodes() {
  const [createDrawerState, setCreateDrawerState] = React.useState(false)
  const [editDrawerState, setEditDrawerState] = React.useState(false)
  // const [filterDrawerState, setFilterDrawerState] = useState(false)
  // const [sortableDrawerState, setSortableDrawerState] = useState(false)

  const { items, refetch: getItems, pagination, isLoading, handleSearchTable, handleTableChange, options } = useGetPromoCodes()

  const { editData, onSaveEdit, onCreate, onDelete, onEditItem } = useManageItems(
    items,
    getItems,
    onSuccessEdit,
    onSuccessCreate
  )

  const headerTitle = useUpdateHeaderTitle({
    items,
    defaultTitle: "0 Промокодов",
    arrType: ["Промокод", "Промокода", "Промокодов"],
  })

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  function onSuccessCreate() {
    setCreateDrawerState(!createDrawerState)
  }

  const columns = getColumns(onEditItem, onDelete)

  return (
    <>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            onCreate={() => setCreateDrawerState(true)}
            // onFilter={() => setFilterDrawerState(!filterDrawerState)}
            // onSort={() => setSortableDrawerState(!sortableDrawerState)}
            titles={{ onCreate: "Добавить Промокод" }}
          />
        }
      />
      <PromocodeFilters handleSearch={ handleSearchTable } />
      <StyledTable
        dataSource={items}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />

      <VisibilityProvider visible={createDrawerState}>
        <EditPromocodes
          onSave={onCreate}
          onClose={() => setCreateDrawerState(false)}
          title={"Добавить промокод"}
          categoryOptions={options.categoryOptions}
          userStatusOptions={options.userStatusOptions}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={editDrawerState && editData}>
        <EditPromocodes
          onSave={onSaveEdit}
          onClose={() => setEditDrawerState(false)}
          initialValues={editData}
          title={"Редактирование промокода"}
          categoryOptions={options.categoryOptions}
          userStatusOptions={options.userStatusOptions}
        />
      </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
