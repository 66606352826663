import React from "react";
import {Col, Drawer, Form, Image, Input, InputNumber, Radio, Row, Select,} from "antd";
import { Button } from "src/components/actions/button";
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg";
import {TranslatableInput, TranslatableTextarea} from "src/components/Translatable";
import { useAppData } from "src/store/app-data";
import {getFormattedLangData, getFormData, getInitialValues, getNestedFormData, getOldImage} from "src/utils/helpers";
import styled from "styled-components";
/*import {PlusOutlined} from "@ant-design/icons";
import {useImage} from "../../hooks";*/

const { TextArea } = Input
export function EditDrawer({
                               title,
                               initialValues,
                               onClose,
                               onSave,
                               typesOptions,
                               categoryOptions
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);

    const [form] = Form.useForm();

    //const { getImage, imageUpdated, onPreview, onChange, preview } = useImage()

    React.useEffect(() => {
        if (initialValues) {
            let categoryIds = [];
            initialValues.dashboardProductCategories.forEach((item) => {
                categoryIds.push(item.id)
            })
            let values = {
                id: initialValues.id,
                name: initialValues.name ?? [],
                short_name: initialValues.short_name ?? [],
                description: initialValues.description ?? [],
                type: initialValues.type ?? null,
                dashboardProductCategoryIds: categoryIds,
                sortWeight: initialValues.sortWeight,
                price: initialValues.price,
                fat: initialValues.fat,
                proteins: initialValues.proteins,
                deliveryPrice: initialValues.deliveryPrice,
                calories: initialValues.calories,
                carbohydrates: initialValues.carbohydrates,
                composition: initialValues.composition,
                vat: initialValues.vat,
                size: initialValues.size,
                isActive: initialValues.isActive,
            }
            form.setFieldsValue(getInitialValues(values));
        }
    }, [initialValues]);

    function prepareData(values) {
        const id = initialValues?.id

        const data = {
            ...(id && { id }),
            name: values.name,
            short_name: values.short_name,
            description: values.description,
            type: values.type,
            dashboardProductCategoryIds: values.dashboardProductCategoryIds,
            sortWeight: values.sortWeight,
            price: values.price,
            fat: values.fat,
            proteins: values.proteins,
            deliveryPrice: values.deliveryPrice,
            calories: values.calories,
            carbohydrates: values.carbohydrates,
            composition: values.composition,
            vat: values.vat,
            size: values.size,
            isActive: values.isActive,
        };

        return data;
    }


    async function onSubmit(values) {

        const baseData = prepareData(values);

        const data = getFormattedLangData(baseData);


        await onSave(data, values?.id);
        onClose();
        form.resetFields();
    }

    function handleClose() {
        onClose();
        form.resetFields();
    }

    const id = initialValues?.id ?? "new";

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={handleClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>

                <StyledFormItem label={"Название"}>
                    <TranslatableInput
                        name={"name"}
                        id={id}
                    />
                </StyledFormItem>

                <StyledFormItem label={"Короткое название"}>
                    <TranslatableInput
                        name={"short_name"}
                        id={id}
                    />
                </StyledFormItem>

                <StyledFormItem label={"Описание"}>
                    <TranslatableTextarea
                        name={"description"}
                        id={id}
                    />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_type"}
                    label={"Тип продукта"}
                    name={"type"}
                    rules={[
                        {
                            required: true,
                            message: "Выберите тип!",
                        },
                    ]}
                >
                    <Select options={typesOptions} />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_dashboardProductCategoryIds"}
                    label={"Категории"}
                    name={"dashboardProductCategoryIds"}
                    rules={[
                        {
                            required: true,
                            message: "Выберите категории!",
                        },
                    ]}
                >
                    <Select mode="multiple" options={categoryOptions} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Цена"}
                    key={id + "_price"}
                    name={"price"}
                    rules={[
                        {
                            required: true,
                            message: "Введите число",
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        style={{
                        width: "100%",
                    }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Цена на доставку"}
                    key={id + "_deliveryPrice"}
                    name={"deliveryPrice"}
                    rules={[
                        {
                            required: true,
                            message: "Введите число",
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Калории"}
                    key={id + "_calories"}
                    name={"calories"}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Белки"}
                    key={id + "_proteins"}
                    name={"proteins"}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Жиры"}
                    key={id + "_fat"}
                    name={"fat"}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Углеводы"}
                    key={id + "_carbohydrates"}
                    name={"carbohydrates"}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Размер"}
                    key={id + "_size"}
                    name={"size"}
                    rules={[
                        {
                            required: true,
                            message: "Введите число",
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"НДС"}
                    key={id + "_vat"}
                    name={"vat"}
                    rules={[
                        {
                            required: true,
                            message: "Введите число",
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Порядок"}
                    key={id + "_sortWeight"}
                    name={"sortWeight"}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_isActive"}
                    label={"Включен"}
                    name={"isActive"}
                    initialValue={form.isActive || true}
                    rules={[
                        {
                            required: true,
                            message: "Выберите вариант!",
                        },
                    ]}
                >
                    <Radio.Group buttonStyle="solid" value={form.isActive || true}>
                        <Radio.Button value={true}>Да</Radio.Button>
                        <Radio.Button value={false}>Нет</Radio.Button>
                    </Radio.Group>
                </StyledFormItem>

                <StyledFormItem
                    label={"Состав"}
                    key={id + "_composition"}
                    name={"composition"}
                >
                    <TextArea  />
                </StyledFormItem>

                <ButtonRow>
                    <ButtonCol>
                        <Button
                            danger
                            onClick={() => {
                                if (initialValues) {
                                    form.setFieldsValue(getInitialValues(initialValues));
                                    return;
                                }
                                form.resetFields();
                            }}
                            icon={<RotateClockwiseSvg />}
                        >
                            Вернуть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button onClick={handleClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>
        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`;

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`;

const StyledImage = styled(Image)`
  display: none;
`

