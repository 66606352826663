import React from "react";
import { Col, Drawer, Form, Image, Input, InputNumber, Radio, Row, Select, Tabs } from "antd";
import { Button } from "src/components/actions/button";
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg";
import { TranslatableInput } from "src/components/Translatable";
import { useAppData } from "src/store/app-data";
import { getFormattedLangData, getInitialValues } from "src/utils/helpers";
import styled from "styled-components";

export function EditDrawer({
                             title,
                             initialValues,
                             onClose,
                             onSave,
                             cityOptions,
                             categoryOptions,
                           }) {
  const layoutConfig = useAppData((state) => state.layoutConfig);

    const [editDisabled, setEditDisabled] = React.useState(true)

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(getInitialValues(initialValues));
    }
  }, [initialValues]);

  function prepareData(values) {
    const id = initialValues?.id;

    const data = {
      ...(id && { id }),
      isActive: values.isActive,
      isShowToMain: values.isShowToMain,
      externalGuid: values.externalGuid,
      nmitMgrpID: values.nmitMgrpId,
      sortWeight: values.sortWeight ?? 1,
      cellCount: values.cellCount ?? 1,
      name: values.name,
      image: values.image,
      pointIds: values.pointsIds,
      groupProductCategoryIds: values.groupProductCategoryIds,
    };

    return data;
  }

  async function onSubmit(values) {
    const baseData = prepareData(values);

    const data = getFormattedLangData(baseData);

    await onSave(data, data.id);
    onClose();
    form.resetFields();
  }

  function handleClose() {
    onClose();
    form.resetFields();
  }

  const id = initialValues?.id ?? "new";

  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "80%"}
          title={title}
          onClose={handleClose}
          open
      >
        <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>
          <StyledFormItem
              key={id + "_isActive"}
              label={"Включен"}
              name={"isActive"}
              initialValue={form.isActive || true}
              rules={[
                {
                  required: true,
                  message: "Выберите вариант!",
                },
              ]}
          >
            <Radio.Group disabled={editDisabled} buttonStyle="solid" value={form.isActive || true}>
              <Radio.Button value={true}>Да</Radio.Button>
              <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
          </StyledFormItem>

          <StyledFormItem
              key={id + "_isShowToMain"}
              label={"Показывать на главной"}
              name={"isShowToMain"}
              initialValue={form.isShowToMain || true}
              rules={[
                {
                  required: true,
                  message: "Выберите вариант!",
                },
              ]}
          >
            <Radio.Group disabled={editDisabled}  buttonStyle="solid" value={form.isShowToMain ? form.isShowToMain : true}>
              <Radio.Button value={true}>Да</Radio.Button>
              <Radio.Button value={false}>Нет</Radio.Button>
            </Radio.Group>
          </StyledFormItem>

          <StyledFormItem
              label={"Название"}
              key={id + "_name"}
              rules={[
                {
                  required: true,
                  message: "Введите название!",
                },
              ]}
          >
            <TranslatableInput disabled={editDisabled}  name={"name"} id={id}></TranslatableInput>
          </StyledFormItem>

          <StyledFormItem
              key={id + "_pointsIds"}
              label={"Точки (несколько)"}
              name={"pointsIds"}
              rules={[
                {
                  required: true,
                  message: "Выберите точки!",
                },
              ]}
          >
            <Select disabled={editDisabled}  mode="multiple" options={cityOptions} />
          </StyledFormItem>

          <StyledFormItem
              key={id + "_groupProductCategoryIds"}
              label={"Группа категорий"}
              name={"groupProductCategoryIds"}
              rules={[
                {
                  required: true,
                  message: "Выберите группу!",
                },
              ]}
          >
            <Select disabled={editDisabled} mode="multiple" options={categoryOptions} />
          </StyledFormItem>

          <Row gutter={28}>
            <Col className="gutter-row" span={12}>
              <StyledFormItem
                  key={id + "_sortWeight"}
                  label={"Позиция в списке"}
                  name={"sortWeight"}
                  rules={[
                    {
                      required: true,
                      message: "Введите позицию!",
                    },
                  ]}
              >
                <InputNumber
                    value={form.sortWeight}
                    min={1}
                    style={{
                      width: "100%",
                    }}
                    placeholder={"Позиция"}
                />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" span={12}>
              <StyledFormItem
                  key={id + "_cellCount"}
                  label={"Размер ячейки"}
                  name={"cellCount"}
                  rules={[
                    {
                      required: true,
                      message: "Введите номер ячейки!",
                    },
                  ]}
              >
                <InputNumber
                    value={form.cellCount}
                    min={1}
                    style={{
                      width: "100%",
                    }}
                    placeholder={"Номер ячейки"}
                />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" span={12}>
              <StyledFormItem
                  label={"externalGuid"}
                  key={id + "_externalGuid"}
                  name={"externalGuid"}
                  rules={[
                    {
                      required: true,
                      message: "Введите externalGuid!",
                    },
                  ]}
              >
                <Input disabled={editDisabled}  />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" span={12}>
              <StyledFormItem
                  label={"nmitMgrpID"}
                  key={id + "_nmitMgrpID"}
                  name={"nmitMgrpId"}
                  rules={[
                    {
                      required: true,
                      message: "Введите nmitMgrpID!",
                    },
                  ]}
              >
                <Input disabled={editDisabled}  />
              </StyledFormItem>
            </Col>
          </Row>

          <ButtonRow>
            <ButtonCol>
              <Button
                  danger
                  onClick={() => {
                    if (initialValues) {
                      form.setFieldsValue(getInitialValues(initialValues));
                      return;
                    }
                    form.resetFields();
                  }}
                  icon={<RotateClockwiseSvg />}
              >
                Вернуть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button onClick={handleClose} icon={<CloseSvg />}>
                Закрыть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                Сохранить
              </Button>
            </ButtonCol>
          </ButtonRow>
        </FormEdit>
      </DrawerEdit>
  );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`;

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`;
