import React from "react";
import {
    Col,

    Drawer, Form, Input, Row, Select
} from "antd";
import {useAppData} from "../../store/app-data";
import styled from "styled-components";
import {Button} from "../../components/actions/button";
import {getInitialValues} from "../../utils/helpers";
import {CloseSvg, RotateClockwiseSvg, SaveSvg} from "../../components/Svg";

export function ResendAnotherEmailDrawer({
                            title,
                            onClose,
                            certificateId,
                            resendEmail,
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);

    const [form] = Form.useForm()

    console.log(certificateId)
    function onSubmit(values) {
        resendEmail(values.email, certificateId)
        onClose()
    }

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={onClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>
                <StyledFormItem
                    label="Email"
                    key={certificateId + "_email"}
                    name={"email"}
                    rules={[
                        {
                            required: true,
                            message: "Введите email!",
                        },
                    ]}
                >
                    <Input type={"email"} placeholder={"email"} />
                </StyledFormItem>
                <ButtonRow>
                    <ButtonCol>
                        <Button onClick={onClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>

        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`
