export function getTaxSystem() {
    return getSystems();
}

function getSystems() {
    return [
        {
            value: 0,
            label: "Общая"
        },
        {
            value: 1,
            label: "Упрощенная, доход"
        }
    ];
}