import React from "react";
import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import styled from "styled-components";
import {notification, Table as AntTable} from "antd";
import {getFormattedItems, useGetPush} from "src/pages-lib/push/model/use-get-push";
import {useManageItems} from "src/pages-lib/push/model";
import {getColumns} from "src/pages-lib/push/column-table";
/*import {getFormattedItems} from "src/pages-lib/push/model/use-manage-items";*/
import {VisibilityProvider} from "src/components/visibility-provider";
import {EditDrawer} from "src/pages-lib/push/edit-drawer"
import {Button} from "../components/actions/button";
import api from "../api";
import {InfoDrawer} from "../pages-lib/push/info-drawer";



export default function Push() {
    const [createDrawerState, setCreateDrawerState] = React.useState(false);
    const [editDrawerState, setEditDrawerState] = React.useState(false);


    const { items: dataItems, refetch: getItems, isLoading, pagination, handleTableChange, handleSearchTable } = useGetPush()
    const { onCreate, onEditItem, infoData } = useManageItems(
        dataItems,
        getItems,
        onSuccessEdit
    );

    const headerTitle = useUpdateHeaderTitle({
        items: dataItems,
        defaultTitle: "0 Уведомлений",
        arrType: ["Уведомление", "Уведомлений", "Уведомлений"],
    });

    const columns = getColumns(onEditItem);

    function onSuccessEdit() {
        setEditDrawerState(!editDrawerState);
    }

    return (
        <>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        onCreate={() => setCreateDrawerState(!createDrawerState)}
                        // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                        // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                        titles={{ onCreate: "Создать Пуш" }}
                    />
                }
            />

            <StyledTable
                dataSource={getFormattedItems(dataItems)}
                columns={columns}
                pagination={pagination}
                loading={isLoading}
                onChange={handleTableChange}
            />

            <VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    onSave={onCreate}
                    onClose={() => setCreateDrawerState(!createDrawerState)}
                    title={"Создание уведомления"}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={editDrawerState}>
                <InfoDrawer
                    dataItem={infoData}
                    onClose={() => setEditDrawerState(!editDrawerState)}
                    title={"Подробная информация"}
                />
            </VisibilityProvider>

            {/*<VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    onSave={onSaveEdit}
                    onClose={() => setEditDrawerState(!editDrawerState)}
                    initialValues={editData}
                    title={"Редактирование клиента"}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={pushDrawerState}>
                <PushDrawer
                    onSave={onSendPush}
                    onClose={() => setPushDrawerState(!pushDrawerState)}
                    title={"Отправить PUSH пользователю"}
                    idUser={pushUserState}
                    allUsers={false}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={pushAllDrawerState}>
                <PushDrawer
                    onSave={onSendPush}
                    onClose={() => setPushAllDrawerState(!pushAllDrawerState)}
                    title={"Отправить PUSH всем"}
                    allUsers={true}
                />
            </VisibilityProvider>*/}
        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
  .ant-table-row{
    background: #ffffff;
  }
  max-width: 100%;
  overflow: scroll;
`;

const StyledButton = styled(Button)`
  margin: 10px 0 20px 0 ;
`;


