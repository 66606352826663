import React from "react";
import {Col, Drawer, Form, Image, Input, InputNumber, Radio, Row, Select,} from "antd";
import { Button } from "src/components/actions/button";
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg";
import {TranslatableInput, TranslatableTextarea} from "src/components/Translatable";
import { useAppData } from "src/store/app-data";
import {getFormattedLangData, getFormData, getInitialValues, getNestedFormData, getOldImage} from "src/utils/helpers";
import styled from "styled-components";
/*import {PlusOutlined} from "@ant-design/icons";
import {useImage} from "../../hooks";*/

const { TextArea } = Input
export function EditDrawer({
                               title,
                               initialValues,
                               onClose,
                               onSave,
                               typesOptions,
                               categoryOptions
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);

    const [form] = Form.useForm();

    //const { getImage, imageUpdated, onPreview, onChange, preview } = useImage()

    React.useEffect(() => {
        if (initialValues) {
            form.setFieldsValue(getInitialValues(initialValues));
        }
    }, [initialValues]);

    function prepareData(values) {
        const id = initialValues?.id

        const data = {
            ...(id && { id, _method: "PUT" }),
            productId: values.productId,
            mainProductId: values.mainProductId,
            clothesSize: values.clothesSize,
            size: values.size,
            externalComponentGuid: values.externalComponentGuid,
            externalGuid: values.externalGuid,
            externalProductGuid: values.externalProductGuid,
            externalSizeGuid: values.externalSizeGuid,
        };

        return data;
    }


    async function onSubmit(values) {

        const baseData = prepareData(values)

        const formData = getFormData({
            values: baseData,
            image: baseData.imageUpdated && baseData.image,
        })

        const data = getFormattedLangData(values)

        const dataWithNestedItem = {
            name: data.name,
            nameComponent: data.nameComponent,
            nameType: data.nameType,
        }

        const formDataWithNestedFormData = getNestedFormData(
            dataWithNestedItem,
            formData
        )

        await onSave(formDataWithNestedFormData, initialValues?.id)
        onClose()
        form.resetFields()
    }

    function handleClose() {
        onClose();
        form.resetFields();
    }

    const id = initialValues?.id ?? "new";

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={handleClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>

                <StyledFormItem label={"Полное название"}>
                    <TranslatableInput
                        name={"name"}
                        id={id}
                    />
                </StyledFormItem>

                <StyledFormItem label={"Название продукта"}>
                    <TranslatableInput
                        name={"nameType"}
                        id={id}
                    />
                </StyledFormItem>

                <StyledFormItem label={"Название компонента"}>
                    <TranslatableInput
                        name={"nameComponent"}
                        id={id}
                    />
                </StyledFormItem>

                <StyledFormItem
                    label={"ID продукта"}
                    key={id + "_productId"}
                    name={"productId"}
                    rules={[
                        {
                            required: true,
                            message: "Введите число",
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Внешний ID"}
                    key={id + "_mainProductId"}
                    name={"mainProductId"}
                    rules={[
                        {
                            required: true,
                            message: "Введите число",
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Размер"}
                    key={id + "_size"}
                    name={"size"}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_clothesSize"}
                    label={"Размер одежды"}
                    name={"clothesSize"}
                >
                    <Input type={"text"}  />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_externalComponentGuid"}
                    label={"externalComponentGuid"}
                    name={"externalComponentGuid"}
                    rules={[
                        {
                            required: true,
                            message: "Введите GUID",
                        },
                    ]}
                >
                    <Input type={"text"}  />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_externalGuid"}
                    label={"externalGuid"}
                    name={"externalGuid"}
                    rules={[
                        {
                            required: true,
                            message: "Введите GUID",
                        },
                    ]}
                >
                    <Input type={"text"}  />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_externalProductGuid"}
                    label={"externalProductGuid"}
                    name={"externalProductGuid"}
                    rules={[
                        {
                            required: true,
                            message: "Введите GUID",
                        },
                    ]}
                >
                    <Input type={"text"}  />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_externalSizeGuid"}
                    label={"externalSizeGuid"}
                    name={"externalSizeGuid"}
                    rules={[
                        {
                            required: true,
                            message: "Введите GUID",
                        },
                    ]}
                >
                    <Input type={"text"}  />
                </StyledFormItem>


                <ButtonRow>
                    <ButtonCol>
                        <Button
                            danger
                            onClick={() => {
                                if (initialValues) {
                                    form.setFieldsValue(getInitialValues(initialValues));
                                    return;
                                }
                                form.resetFields();
                            }}
                            icon={<RotateClockwiseSvg />}
                        >
                            Вернуть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button onClick={handleClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>
        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`;

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`;

const StyledImage = styled(Image)`
  display: none;
`

