import React from "react";
import { notification } from "antd";
import api from "src/api";

export function useManageItems() {

  async function resendEmail(email, certificateId) {
    const form = new FormData
    form.append("email", email)
    form.append("id", certificateId)
    try {
        await api.post("resend-certificates", form);
        notification.success({ message: "Сообщение отправлено" });
    } catch (error) {
        notification.error({ message: "Ошибка отправления" });
        throw error;
    }
  }

  return { resendEmail };
}

export function getFormattedItems(items) {
    const data = items.map((item) => ({
        key: item.id,
        id: item.id,
        certificateCode: item.certificateCode,
        isBlock: item.isBlock,
        sum: item.sum,
        emailForCertificate: item.certificateOrder.emailForCertificate,
    }));

    return data;
}
