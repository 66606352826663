import moment from "moment";
export function getNotificationColumns() {

    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 50,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "ascend",
        },
        {
            title: "Заголовок",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Текст",
            dataIndex: "body",
            key: "body",
        },
        {
            title: "Отправлено в",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => (
                <span>{moment(text, "DD.MM.YYYY HH:mm").utcOffset(360).format("DD.MM.YYYY HH:mm")}</span>
            ),
        }

    ];
}