import { Col, Layout as AntLayout, Row } from "antd"
import { Logotype } from "src/components/layout/logotype"
import { CollapseTrigger } from "src/components/menu/colapsed-triger"
import { NavMenu } from "src/components/menu/nav-menu"
import { UserMenu } from "src/components/menu/user-menu"
import { useAppData } from "src/store/app-data"
import styled from "styled-components"

const { Sider } = AntLayout

export function NavSider() {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  return (
    <StyledSider
      trigger={null}
      collapsible
      collapsed={layoutConfig.collapsed}
      className="ant-base-sider"
      width={layoutConfig.siderWidth}
    >
      <ColumnRow>
        <Col>
          <Logotype />
        </Col>
        <NavigationCol>
          <NavMenu />
        </NavigationCol>
        <UserMenuCol>
          <UserMenu
            isMobile={layoutConfig.isMobile}
            collapsed={layoutConfig.collapsed}
          />
        </UserMenuCol>
        {!layoutConfig.isMobile && <CollapseTrigger />}
      </ColumnRow>
    </StyledSider>
  )
}

const ColumnRow = styled(Row)`
  min-height: 100%;
  flex-direction: column;
`

const NavigationCol = styled(Col)`
  margin-top: 4px;
`

const UserMenuCol = styled(Col)`
  margin-top: auto;
`

const StyledSider = styled(Sider)`
  .ant-menu {
    padding: 0 ${(props) => (!props.collapsed ? "10px" : 0)};
  }

  &.ant-base-sider {
    box-shadow: 4px 0 14px -8px rgba(0, 0, 0, 0.08), 9px 0 20px 0 rgba(0, 0, 0, 0.05);
  }
`
