import React, {useState} from "react";
import {Col, Drawer, Form, Image, Input, InputNumber, Radio, Row, Select,} from "antd";
import { Button } from "src/components/actions/button";
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg";
import {TranslatableInput, TranslatableTextarea} from "src/components/Translatable";
import { useAppData } from "src/store/app-data";
import {getFormattedLangData, getFormData, getInitialValues, getNestedFormData, getOldImage} from "src/utils/helpers";
import styled from "styled-components";
import {SearchBox} from "src/components/search-box";
import {SearchProducts} from "./model/use-get-point-products";
/*import {PlusOutlined} from "@ant-design/icons";
import {useImage} from "../../hooks";*/
export function EditDrawer({
                               title,
                               initialValues,
                               onClose,
                               onSave,
                               pointProductStatusOptions,
                               pointOptions
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);



    const [form] = Form.useForm();
    const [productOptions, setProductOptions] = useState([])
    const [productId, setProductId] = useState(null)

    //const { getImage, imageUpdated, onPreview, onChange, preview } = useImage()

    React.useEffect(() => {
        if (initialValues) {
            console.log(initialValues)
            let values = {
                id: initialValues.id,
                pointId: initialValues.pointId,
                productId: initialValues.productId,
                balanceOfGoods: initialValues.balanceOfGoods ?? null,
                price: initialValues.price ?? 0,
                deliveryPrice: initialValues.deliveryPrice ?? 0,
                isUnlimited: initialValues.isUnlimited ?? true,
                status: initialValues.status,
            }
            form.setFieldsValue(getInitialValues(values));
        }
    }, [initialValues]);

    function prepareData(values) {
        const id = initialValues?.id
        if(typeof values.productId !== "number") {
            values.productId = values.productId.value
        }
        const data = {
            ...(id && { id, _method: "PUT"  }),
            pointId: values.pointId,
            productId: values.productId,
            balanceOfGoods: values.balanceOfGoods ?? null,
            price: values.price ?? 0,
            deliveryPrice: values.deliveryPrice ?? 0,
            isUnlimited: values.isUnlimited ?? true,
            status: values.status,
        };

        return data;
    }

    async function onSubmit(values) {
        console.log(values)
        const baseData = prepareData(values);

        const data = getFormattedLangData(baseData);


        await onSave(data, values?.id);
        onClose();
        form.resetFields();
    }


    async function handleSearchProduct(name) {
        const options = await SearchProducts(name)
        setProductOptions(options)
    }
    const handleChangeProduct = (newValue) => {
        setProductId(newValue);
    };
    function handleClose() {
        onClose();
        form.resetFields();
    }

    const id = initialValues?.id ?? "new";

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={handleClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>

                <StyledFormItem
                    key={id + "_pointId"}
                    label={"Точка"}
                    name={"pointId"}
                    rules={[
                        {
                            required: true,
                            message: "Выберите точку!",
                        },
                    ]}
                >
                    <Select
                        showSearch
                        filterOption={(input, option) => (option?.label ?? "").includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={pointOptions} />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_productId"}
                    label={"Продукт"}
                    name={"productId"}
                    rules={[
                        {
                            required: true,
                            message: "Выберите продукт!",
                        },
                    ]}
                >
                    <SearchBox options={productOptions} handleSearch={handleSearchProduct} onChange={handleChangeProduct} value={productId ?? null}/>
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_status"}
                    label={"Статус"}
                    name={"status"}
                    rules={[
                        {
                            required: true,
                            message: "Выберите точку!",
                        },
                    ]}
                >
                    <Select
                        options={pointProductStatusOptions} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Цена"}
                    key={id + "_price"}
                    name={"price"}
                    rules={[
                        {
                            required: true,
                            message: "Введите число",
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    label={"Цена на доставку"}
                    key={id + "_deliveryPrice"}
                    name={"deliveryPrice"}
                    rules={[
                        {
                            required: true,
                            message: "Введите число",
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <StyledFormItem
                    key={id + "_isUnlimited"}
                    label={"Безлимитно"}
                    name={"isUnlimited"}
                    initialValue={form.isUnlimited || true}
                    rules={[
                        {
                            required: true,
                            message: "Выберите вариант!",
                        },
                    ]}
                >
                    <Radio.Group buttonStyle="solid" value={form.isUnlimited || true}>
                        <Radio.Button value={true}>Да</Radio.Button>
                        <Radio.Button value={false}>Нет</Radio.Button>
                    </Radio.Group>
                </StyledFormItem>

                <StyledFormItem
                    label={"Остаток"}
                    key={id + "_balanceOfGoods"}
                    name={"balanceOfGoods"}
                >
                    <InputNumber
                        min={0}
                        style={{
                            width: "100%",
                        }} />
                </StyledFormItem>

                <ButtonRow>
                    <ButtonCol>
                        <Button
                            danger
                            onClick={() => {
                                if (initialValues) {
                                    form.setFieldsValue(getInitialValues(initialValues));
                                    return;
                                }
                                form.resetFields();
                            }}
                            icon={<RotateClockwiseSvg />}
                        >
                            Вернуть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button onClick={handleClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>
        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`;

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`;

const StyledImage = styled(Image)`
  display: none;
`

