import React from "react"
import { Popconfirm, Space, Table as AntTable, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import { PencilSvg, TrashSvg } from "src/components/Svg"
import styled from "styled-components"

export function ViewTable({ dataItems, pagination, onEdit, onDelete }) {
  const [tableParams] = React.useState({
    pagination: pagination,
  })
  const [data, setData] = React.useState([])

  React.useEffect(() => {
    let newData = []
    dataItems.forEach((item) => {
      let newItem = {
        key: item.id,
        id: item.id,
        title: item.title,
        description: item.description,
        link: item.link,
        sortWeight: item.sortWeight,
        isActive: item.isActive,
        image: item.image,
      }
      newData.push(newItem)
    })
    setData(newData)
  })

  function handleEdit(id) {
    onEdit(id)
  }
  function handleDelete(id) {
    onDelete(id)
  }
  function stopPropagation(e) {
    e.stopPropagation()
  }
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      // specify the condition of filtering result
      // here is that finding the name started with `value`
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
    },
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.promocode - b.promocode,
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description - b.description,
    },
    {
      title: "Позиция в списке",
      dataIndex: "sortWeight",
      key: "sortWeight",
      width: 100
    },
    {
      title: "Флаг активности",
      dataIndex: "isActive",
      key: "isActive",

      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },

    {
      title: "Действие",
      key: "action",
      width: 100,
      fixed: "right",
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => handleEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
            <Popconfirm
              placement="left"
              title="Действительно хотите удалить?"
              onConfirm={() => handleDelete(record.id)}
              onCancel={() => stopPropagation}
            >
              <Button size="small" type="link" onClick={stopPropagation}>
                <TrashSvg />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <StyledTable
      dataSource={data}
      columns={columns}
      pagination={tableParams.pagination}
    />
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
  max-width: 100%;
  overflow: hidden;
`

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`
