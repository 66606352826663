import { Space, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import { PencilSvg } from "src/components/Svg"
import styled from "styled-components"

export function getColumns(onEdit) {
  return [
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      width: 50,
      sorter: (a, b) => a.phone - b.phone,
      defaultSortOrder: "ascend",
    },
    {
      title: "Номер заказа",
      dataIndex: "innerId",
      key: "innerId",
      sorter: (a, b) => a.innerId - b.innerId,
    },
    {
      title: "Внешний номер",
      dataIndex: "outNumber",
      key: "outNumber",
      sorter: (a, b) => a.outNumber - b.outNumber,
    },
    {
      title: "Нужно подтверждение",
      dataIndex: "isNeedAccept",
      key: "isNeedAccept",
      sorter: (a, b) => a.isNeedAccept - b.isNeedAccept,
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },
    {
      title: "Оплачен",
      dataIndex: "isPaid",
      key: "isPaid",
      sorter: (a, b) => a.isPaid - b.isPaid,
      render: (text) => (
        <StyledTag color={text ? "rgb(56, 142, 60)" : "rgb(211, 47, 47)"}>
          {text ? "ДА" : "НЕТ"}
        </StyledTag>
      ),
    },

    {
      title: "Действие",
      key: "action",
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ]
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`
