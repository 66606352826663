import React from "react";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import {useUpdateHeaderTitle} from "../hooks";
import {useGetOnboardings, useManageItems} from "../pages-lib/onboarding/model";
import styled from "styled-components";
import {Table as AntTable} from "antd";
import {getColumns} from "../pages-lib/onboarding/table-columns";
import {getFormattedItems} from "../pages-lib/onboarding/model/use-manage-items";
import moment from "moment/moment";
import {API_BASE_MEDIA_URL} from "../config";
import {EditOnboarding} from "../pages-lib/onboarding/edit-onboarding";

export default function OnboardingPage() {

    const { items, pagination, refetch: getItems, isLoading, handleTableChange } = useGetOnboardings()

    const {
        updatingStep,
        onSave,
        onDelete,
        onEditItem: onEdit,
        onCreateItem,
        resetItem,
    } = useManageItems(items, getItems)

    const headerTitle = useUpdateHeaderTitle({
        items: items,
        defaultTitle: "0 записей",
        arrType: ["запись", "записи", "записей"],
    })

    const columns = getColumns(onEdit, onDelete);

    const editTitle =
        updatingStep?.state === "edit" ? "Редактировать онбординг" : "Создать онбординг"

    return (
        <div>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        onCreate={onCreateItem}
                        titles={{ onCreate: "Создать онбординг" }}
                    />
                }
            />


            <StyledTable
                dataSource={getFormattedItems(items)}
                columns={columns}
                pagination={pagination}
                loading={isLoading}
                onChange={handleTableChange}
            />

            <EditOnboarding
                onSave={onSave}
                onClose={() => resetItem()}
                title={editTitle}
                initialValues={getData(updatingStep?.step)}
                state={updatingStep?.state}
            />
        </div>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`

function getData(item) {
    if (!item) {
        return null
    }

    return {
        ...item,
        name: item.name?.find((el) => el.language === "ru").content,
        description: item.description?.find((el) => el.language === "ru").content,
        steps: item.steps.map((step) => ({
            key: step.id,
            id: step.id,
            name: step.name?.find((el) => el.language === "ru").content,
            description: step.description?.find((el) => el.language === "ru").content,
            image: getImage(step.image),
        })),
    }
}

function getImage(image) {
    const file = {
        url: API_BASE_MEDIA_URL + image.path + "/" + image.systemName,
        uid: image.id,
        name: image.originalName,
        status: "done",
    }

    return [file]
}

