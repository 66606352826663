import React from "react"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks/use-update-header-title"
import { DrawerCreate } from "src/pages-lib/live-love-work/drawer-create"
import { DrawerEdit } from "src/pages-lib/live-love-work/drawer-edit"
import {
  useGetLiveWorkItems,
  useManageItems,
} from "src/pages-lib/live-love-work/model"
import { ViewTable } from "src/pages-lib/live-love-work/table"

export default function LiveLoveWork() {
  const [createDrawerState, setCreateDrawerState] = React.useState(false)
  const [editDrawerState, setEditDrawerState] = React.useState(false)

  const { items, refetch: getItems, pagination, isLoading, handleTableChange } = useGetLiveWorkItems()

  const headerTitle = useUpdateHeaderTitle({
    items,
    defaultTitle: "0 Объектов",
    arrType: ["Объект", "Объекта", "Объектов"],
  })

  const { editData, onDelete, onEditItem, resetEditData } = useManageItems(
    items,
    getItems,
    onSuccessEdit
  )

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  function closeDrawerCreate() {
    setCreateDrawerState(false)
    getItems()
  }

  function closeDrawerEdit() {
    setEditDrawerState(false)
    resetEditData()
    getItems()
  }

  return (
    <>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            onCreate={() => setCreateDrawerState(true)}
            titles={{ onCreate: "Добавить объект" }}
          />
        }
      />
      <ViewTable
        dataItems={items}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={pagination}
        onEdit={onEditItem}
        onDelete={onDelete}
      />
      <VisibilityProvider visible={createDrawerState}>
        <DrawerCreate onClose={closeDrawerCreate} />
      </VisibilityProvider>

      <VisibilityProvider visible={editDrawerState && editData}>
        <DrawerEdit onClose={closeDrawerEdit} initialData={editData} />
      </VisibilityProvider>
    </>
  )
}
