import React from "react"
import api from "src/api"

export function useGetItems() {
  const [pagination, setPagination] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [items, setItems] = React.useState([])

  async function getItems(page = 1, countOnPage = 20, filters = "") {
    setIsLoading(true)
    try {
      const { data } = await api.get(`orders/hold-payment?page=${page}&countOnPage=${countOnPage}${filters}`)
      setPagination({
        current: data.pagination.currentPage,
        pageSize: data.pagination.countOnPage,
        total: data.pagination.total,
      })
      const newItems = formattedItems(data.items)
      setItems(newItems)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  function formattedItems(items) {
    const newItems = []

    items.forEach((item) => {
      newItems.push({
        key: item.id,
        id: item.id,
        innerId: item.innerId,
        totalPriceWithDiscount: item.totalPriceWithDiscount,
        orderStatus: item.dashboardOrderStatus?.name?.find((el) => el.language === "ru").content,
      })
    })
    return newItems;
  }

  React.useEffect(() => {
    getItems()
  }, [])

  function handleTableChange(pagination) {
    getItems(pagination.current, pagination.pageSize)
  }

  return { items, refetch: () => getItems(), isLoading, pagination, handleTableChange }
}
