import React from "react"
import { notification } from "antd"
import api from "src/api"
import { getOldImage } from "src/utils/helpers"

export function useManageItems(items, getItems, onSuccessEdit, onSuccessCreate) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id)
    const data = formmatedEditData(item)
    setEditData(data)
    onSuccessEdit()
  }

  async function onCreate(values) {
    try {
      await api.post("articles", values)

      notification.success({ message: "Новость добавлена" })
      onSuccessCreate()
      getItems()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }

  async function onSaveEdit(values, id) {
    try {
      await api.post(`articles/${id}`, values)

      notification.success({ message: "Новость изменена" })
      getItems()
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  async function onDelete(id) {
    try {
      await api.delete(`articles/${id}`)

      notification.success({ message: "Новость удалена" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  function resetData() {
    setEditData(null)
  }

  function formmatedEditData(item) {
    return {
      ...item,
      cityIds: item.cities.map((element) => element.id),

      articleCategoryId: item.articleCategory.id,
    }
  }

  return { editData, resetData, onSaveEdit, onCreate, onDelete, onEditItem }
}
