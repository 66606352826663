import React from "react";
import api from "src/api";
export function useGetData() {
  const [dataItems, setDataItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    getItems()
  }, [])



  const getItems = () => {
    setIsLoading(true)
    api.get("user-statuses").then(({ data }) => {
      setDataItems(data.items)
    })

    setIsLoading(false)
  }

  return {
    dataItems,
    isLoading,
    refetch: () => getItems()
  }
}

export function getFormattedItems(items) {
  const newItems = []

  items.forEach((item) => {

    let newItem = {
      key: item.id,
      id: item.id,
      number: item.number,
      name: item.name.find((el) => el.language === "ru")?.content ?? "Нет названия",
      slug: item.slug,
      group: item.group?.name?.find((el) => el.language === "ru")?.content ?? "Нет группы",

    }
    newItems.push(newItem)
  })

  return newItems
}
