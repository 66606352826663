export function getProductPointStatus() {
    let data = [
        {
            label: "Везде",
            value:  "everywhere"
        },
        {
            label: "Только в кафе",
            value:  "only_cafe"
        },
        {
            label: "Не доступно",
            value:  "not_available"
        },
        {
            label: "Только на доставку",
            value:  "only_delivery"
        },
    ]
    return data;
}