import {useGetData} from "../pages-lib/rewards/model";
import {useUpdateHeaderTitle} from "../hooks";
import React from "react";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import {VisibilityProvider} from "../components/visibility-provider";
import {EditDrawer} from "../pages-lib/rewards/edit-drawer";
import {useManageItems} from "../pages-lib/rewards/model/use-manage-items";
import styled from "styled-components";
import {Table as AntTable} from "antd";
import {getColumns} from "../pages-lib/rewards/table-columns";

export default function Rewards() {

    const [createDrawerState, setCreateDrawerState] = React.useState(false)
    const [editDrawerState, setEditDrawerState] = React.useState(false)


    const {
        dataItems,
        dataItemsColumns,
        isLoading,
        pagination,
        handleTableChange,
        refetch: getItems,
        options
    } = useGetData();

    const {
        editData,
        onSaveEdit,
        onCreate,
        onDelete,
        onEditItem
    } = useManageItems(dataItems, getItems, onSuccessEdit, onSuccessCreate)

    const columns = getColumns(onEditItem, onDelete)
    function onSuccessEdit() {
        setEditDrawerState(!editDrawerState)
    }

    function onSuccessCreate() {
        setCreateDrawerState(!createDrawerState)
    }

    const headerTitle = useUpdateHeaderTitle({
        items: dataItems,
        defaultTitle: "0 Наград",
        arrType: ["Награда", "Награды", "Наград"],
    })
    return (
        <>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        onCreate={() => setCreateDrawerState(true)}
                        // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                        // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                        titles={{ onCreate: "Добавить награду" }}
                    />
                }
            />

            <StyledTable
                dataSource={dataItemsColumns}
                columns={columns}
                loading={isLoading}
                onChange={handleTableChange}
                pagination={pagination}
            />

            <VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    title={"Создать награду"}
                    initialValues={false}
                    onClose={() => setCreateDrawerState(false)}
                    onSave={onCreate}
                    rewardTypeOptions={options.rewardTypesOptions}
                    eventOptions={options.eventOptions}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    title={"Редактировать награду"}
                    initialValues={editData}
                    onClose={() => setEditDrawerState(false)}
                    onSave={onSaveEdit}
                    rewardTypeOptions={options.rewardTypesOptions}
                    eventOptions={options.eventOptions}
                />
            </VisibilityProvider>
        </>
    )
}


const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
