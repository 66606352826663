import { Route, Routes } from "react-router-dom"
import { AuthLayout } from "src/layouts/auth-layout"
import { UNAUTH_PATHS } from "src/routing/paths"

export default function UnauthorizedRouter() {
  return (
    <Routes>
      {UNAUTH_PATHS.map(({ path, component }) => {
        const Component = component
        return (
          <Route
            key={path}
            path={path}
            element={
              <AuthLayout>
                <Component />
              </AuthLayout>
            }
          />
        )
      })}
    </Routes>
  )
}
