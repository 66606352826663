import React from "react"
import { notification } from "antd"
import api from "src/api"
import moment from "moment";

export function useManageItems(
    systemPoints,
    setSystemItemHandled,
    getDateItems,
    dateItems,
    onSuccessCreate,
    onSuccessEdit
) {

  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = dateItems.find((el) => el.id === id)
    const newItem = {
      key: id,
      id: id,
      date: moment(item.date, "YYYY-MM-DD"),
      pointIds: item.pointIds
    }
    setEditData(newItem)
    onSuccessEdit()
  }
  async function onChangeSystemPoint(bool, key) {
    const newData = systemPoints.map((item) =>
        item.key === key ? {...item, isLoading:true } : item
    );
    setSystemItemHandled(newData);

    let finallyData = newData.map((item) =>
        item.key === key ? {...item, isLoading:false } : item
    );
    try {
      const form = new FormData;
      form.append("cityId", key)
      form.append("isDelivery", bool ? 1 : 0)
      await api.post("points/update-system-point", form)

      notification.success({ message: `Доставка во всех точках в г.${systemPoints.find((element) => element.key ===key).cityName} ${bool ? "включена" : "выключена"}` })

      finallyData = finallyData.map((item) =>
          item.key === key ? {...item, isDelivery:bool } : item
      );

    } catch (error) {
      notification.error({ message: "Ошибка выключения доставки" })
      throw error
    } finally {
      setSystemItemHandled(finallyData);
    }
  }


  async function onCreate(values) {
    try {
      await api.post("blocked-date", values)

      notification.success({ message: "Дата заблокирована" })
      onSuccessCreate()

    } catch (error) {
      notification.error({ message: "Ошибка записи даты" })
      throw error
    } finally {
      getDateItems()
    }
  }

  async function onEdit(values) {
    try {
      await api.post("blocked-date", values)

      notification.success({ message: "Дата заблокирована" })
      onSuccessEdit()
      setEditData(false)

    } catch (error) {
      notification.error({ message: "Ошибка записи даты" })
      throw error
    } finally {
      getDateItems()
    }
  }

  async function onDelete(id) {
    try {
      const form = new FormData;
      form.append("id", id)
      form.append("_method", "DELETE")
      await api.post("blocked-date", form)

      notification.success({ message: "Дата удалена" })
    } catch (error) {
      notification.error({ message: "Ошибка удаления" })
      throw error
    } finally {
      getDateItems()
    }
  }

  return { onChangeSystemPoint, onCreate, onDelete, onEditItem, editData, onEdit }
}
