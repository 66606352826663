import React from "react"
import { notification } from "antd"
import api from "src/api"
import moment from "moment/moment";
import {DATE_FORMAT} from "../../../config";

export function useManageItems(items, getItems, onSuccessEdit, onSuccessCreate) {
  const [editData, setEditData] = React.useState(null)

  async function onEditItem(id) {
    const { data } =  await api.get(`loyalty-rewards/${id}`)
    const formattedItem = formmatedEditData(data.reward)
    setEditData(formattedItem)
    onSuccessEdit()
  }

  async function onCreate(values) {
    try {
      await api.post("loyalty-rewards", values)

      notification.success({ message: "Запись добавлена" })
      onSuccessCreate()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    } finally {
      getItems()
    }
  }

  async function onSaveEdit(values, id) {
    try {
      await api.post(`loyalty-rewards/${id}`, values)

      notification.success({ message: "Награда изменена" })
      resetData()
      onSuccessEdit()
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    } finally {
      getItems()
    }
  }

  async function onDelete(id) {
    try {
      await api.delete(`loyalty-rewards/${id}`)

      notification.success({ message: "Награда удалена" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  function resetData() {
    setEditData(null)
  }

  function formmatedEditData(item) {
    return {
      image: item.image,
      additionalText: item.additionalText ?? null,
      description: item.description,
      name: item.name,
      id: item.id,
      isActive: item.isActive,
      link: item.link,
      linkText: item.linkText.length > 0 ? item.linkText : null,
      elements: item.elements.map((element) => {
        return {
          ...element,
          typeId: element.type.id,
          startAt: element.startAt !== null ? moment(element.startAt, DATE_FORMAT) : null,
          endAt: element.endAt !== null ? moment(element.endAt, DATE_FORMAT) : null
        }
      })
    }
  }

  return { editData, onSaveEdit, onCreate, onDelete, onEditItem }
}
