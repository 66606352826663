import styled from "styled-components";
import {Drawer} from "antd";
import {useAppData} from "../../store/app-data";
import React from "react";
import {LinkBuilder} from "../../components/link-builder/link-builder";

export function StoriesLinkBuilder({title, onCloseDrawer}) {

    const layoutConfig = useAppData((state) => state.layoutConfig)

    const [linkValue, setLinkValue] = React.useState("")

    function handleSetValue(value) {
        setLinkValue(value)
    }

    function handleCloseThisDrawer() {
        onCloseDrawer()
        setLinkValue("")
    }

    return (
        <DrawerEditBuilder
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={handleCloseThisDrawer}
            open
        >
            <div>
                <StyledLabel>
                    <div style={{ fontSize: "20px", marginTop: "20px" }}>После создания ссылки нужно скопировать текст ниже и вставить в поле ссылка!!!</div>
                    {`Итоговая ссылка: ${linkValue}`}
                </StyledLabel>

                <LinkBuilder
                    placeholder={"Итоговая ссылка"}
                    onSetValue={handleSetValue}
                />
            </div>
        </DrawerEditBuilder>
    )

}

const DrawerEditBuilder = styled(Drawer)``

const StyledLabel = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 32px;
`