import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks"
import { EditDrawer } from "src/pages-lib/payment-accounts/edit-drawer"
import {
  useGetAccounts,
  useManageItems,
} from "src/pages-lib/payment-accounts/model"
import { getColumns } from "src/pages-lib/payment-accounts/table-columns"
import styled from "styled-components"

export default function PaymentAccounts() {
  const [createDrawerState, setCreateDrawerState] = React.useState(false)
  const [editDrawerState, setEditDrawerState] = React.useState(false)

  // const [filterDrawerState, setFilterDrawerState] = React.useState(false)
  // const [sortableDrawerState, setSortableDrawerState] = React.useState(false)

  const {
    items: dataAccounts,
    refetch: getItems,
    options
  } = useGetAccounts()

  const { editData, onSaveEdit, onCreate, onDelete, onEditItem } = useManageItems(
    dataAccounts,
    getItems,
    onSuccessEdit
  )

  const headerTitle = useUpdateHeaderTitle({
    items: dataAccounts,
    defaultTitle: "0 Аккаунтов",
    arrType: ["Аккаунт", "Аккаунта", "Аккаунтов"],
  })

  const columns = getColumns(onEditItem, onDelete)

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  return (
    <>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            onCreate={() => setCreateDrawerState(true)}
            // onFilter={() => setFilterDrawerState(!filterDrawerState)}
            // onSort={() => setSortableDrawerState(!sortableDrawerState)}
            titles={{ onCreate: "Добавить аккаунт" }}
          />
        }
      />
      <StyledTable
        dataSource={dataAccounts}
        columns={columns}
        pagination={false}
      />

      <VisibilityProvider visible={createDrawerState}>
        <EditDrawer
          onSave={onCreate}
          onClose={() => setCreateDrawerState(false)}
          title={"Создание аккаунта"}
          systemOptions={options.systemOptions}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onSave={onSaveEdit}
          onClose={() => setEditDrawerState(false)}
          initialValues={editData}
          title={"Редактирование аккаунта"}
          visible={editDrawerState}
          systemOptions={options.systemOptions}
        />
      </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
