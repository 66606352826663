import { Space, Tooltip } from "antd";
import { Button } from "src/components/actions/button";
import { PencilSvg } from "src/components/Svg";

export function getColumns(onEdit) {
  return [
    {
      title: "Номер группы",
      dataIndex: "number",
      key: "number",
      width: 170,
      sorter: (a, b) => a.number - b.number,
      defaultSortOrder: "ascend",
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Системное название",
      dataIndex: "slug",
      key: "slug"
    },
    {
      title: "Действия",
      key: "action",
      width: 100,
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
  ]
}