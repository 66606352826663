import React from "react"
import { PlusOutlined } from "@ant-design/icons"
import {
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Tabs,
  Upload,
} from "antd"
import moment from "moment/moment"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import {
  TranslatableInput,
  TranslatableTextarea,
  TranslatableWysiwyg,
} from "src/components/Translatable"
import { useImage } from "src/hooks"
import { useAppData } from "src/store/app-data"
import {
  getFormattedLangData,
  getFormData,
  getInitialValues,
  getNestedFormData,
  getOldImage, isDefined,
} from "src/utils/helpers"
import styled from "styled-components"

export function EditDrawer({
  title,
  initialValues,
  onClose,
  onSave,
  cityOptions,
  categoryOptions,
  userStatusOptions
}) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  const [form] = Form.useForm()

  const { getImage, onPreview, preview, onChange, imageUpdated } = useImage()

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  function prepareData(values) {
    const file = getImage(values.image)
    const id = initialValues?.id
    const isNeedImage = !id || (id && imageUpdated)

    const data = {
      ...(id && { id, _method: "PUT"  }),
      imageUpdated: id ? imageUpdated : true,
      eventCategoryId: values.eventCategoryId,
      isActive: values.isActive ? 1 : 0,
      sortWeight: values.sortWeight ?? 0,
      ...(isNeedImage && { image: file }),
      outerLink: values.outerLink ?? null,
      buttonLink: values.buttonLink ?? null,
      buttonText: values.buttonText ?? null,
      eventDate: values.eventDate ?? null,
      startedAt: moment(values.startedAt, "Y-m-d\TH:i:sP").toISOString() ?? null,
      ...(values.finishedAt && {finishedAt: moment(values.finishedAt, "Y-m-d\TH:i:sP").toISOString()}),
      price: values.price ?? null,
    }
    return data
  }

  async function onSubmit(values) {
    const baseData = prepareData(values)

    const formData = getFormData({
      values: baseData,
      image: baseData.imageUpdated && baseData.image,
    })

    const data = getFormattedLangData(values)

    const dataWithNestedItem = {
      name: data.name,
      description: data.description,
      content: data.content,
      cities: data.cities,
      ...(isDefined(values.statuses) && values.statuses.length > 0 && { statuses: data.statuses})
    }

    const formDataWithNestedFormData = getNestedFormData(
      dataWithNestedItem,
      formData
    )

    await onSave(formDataWithNestedFormData, initialValues?.id)
    onClose()
    form.resetFields()
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }


  const id = initialValues?.id ?? "new"

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={title}
      onClose={handleClose}
      open
    >
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <StyledFormItem
          name={"image"}
          label={"Обложка"}
          rules={[
            {
              required: true,
              message: "Выберите изображение!",
            },
          ]}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            onPreview={onPreview}
            status="done"
            beforeUpload={() => false}
            onChange={onChange}
            defaultFileList={getOldImage(initialValues?.image)}
            dependencies={"image"}
            accept="image/jpeg,image/png,image/jpg"
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить изображение</div>
            </div>
          </Upload>
        </StyledFormItem>
        <StyledImage
          alt={preview.previewImage?.name || "Превью"}
          preview={{
            visible: preview.visible,
            onVisibleChange: preview.onVisibleChange,
          }}
          src={preview.previewImage?.largePreview || preview.previewImage?.url}
        />
        <StyledFormItem
          label={"Название"}
          key={id + "_name"}
          rules={[
            {
              required: true,
              message: "Введите название!",
            },
          ]}
        >
          <TranslatableInput name={"name"} id={id}></TranslatableInput>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_sortWeight"}
            label={"Позиция в списке"}
            name={"sortWeight"}
            rules={[
              {
                required: true,
                message: "Введите позицию!",
              },
            ]}
        >
          <InputNumber
              min={0}
              style={{
                width: "100%",
              }}
              placeholder={"Позиция"}
          />
        </StyledFormItem>

        <StyledFormItem
          key={id + "_eventCategoryId"}
          label={"Категория"}
          name={"eventCategoryId"}
          rules={[
            {
              required: true,
              message: "Выберите категорию!",
            },
          ]}
        >
          <Select options={categoryOptions} />
        </StyledFormItem>

        <StyledFormItem
          key={id + "_cities"}
          label={"Город (несколько)"}
          name={"cities"}
          rules={[
            {
              required: true,
              message: "Выберите город!",
            },
          ]}
        >
          <Select mode={"multiple"} options={cityOptions} />
        </StyledFormItem>

        <StyledFormItem
          label={"Цена за мероприятие"}
          key={id + "_price"}
          name={"price"}
        >
          <InputNumber min={0}/>
        </StyledFormItem>

        <StyledFormItem
            label={"Дата мероприятия (свободная форма)"}
            key={id + "_eventDate"}
            name={"eventDate"}
        >
          <Input maxLength={255}/>
        </StyledFormItem>

        <StyledFormItem
          key={id + "_startedAt"}
          label={"Начало"}
          name={"startedAt"}
          rules={[
            {
              required: true,
              message: "Выберите дату и время!",
            },
          ]}
        >
          <DatePicker
            showTime
            style={{
              width: "100%",
            }}
            placeholder={"Выберите дату и время"}
          />
        </StyledFormItem>

        <StyledFormItem key={id + "_finishedAt"} label={"Конец"} name={"finishedAt"}>
          <DatePicker
            showTime
            style={{
              width: "100%",
            }}
            placeholder={"Выберите дату время"}
          />
        </StyledFormItem>

        <StyledFormItem label={"Контент"} key={id + "_content"}>
          <TranslatableWysiwyg
            name={"content"}
            value={"test content for edit"}
          ></TranslatableWysiwyg>
        </StyledFormItem>

        <StyledFormItem label={"Описание"} key={id + "_description"}>
          <TranslatableTextarea id={id} name={"description"}></TranslatableTextarea>
        </StyledFormItem>

        <StyledFormItem
          key={id + "_isActive"}
          label={"Включен"}
          name={"isActive"}
          initialValue={form.isActive ? form.isActive : true}
          rules={[
            {
              required: true,
              message: "Выберите вариант!",
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            value={form.isActive ? form.isActive : true}
          >
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_statuses"}
            label={"Доступен от уровня"}
            name={"statuses"}
        >
          <Select mode={"multiple"} options={userStatusOptions} placeholder={"Выберите уровень"} disabled={false} />
        </StyledFormItem>

        <Divider orientation="left">Ссылка</Divider>

        <StyledFormItem
          label={"Ccылка для внешнего перехода"}
          key={id + "_outerLink"}
          name={"outerLink"}
        >
          <Input />
        </StyledFormItem>

        <Divider orientation="left">Кнопка</Divider>
        <div>
          При заполнении этих полей преход в приложении будет осуществляться сначала
          в приложение, а затем на внешний ресурс!!!
        </div>

        <StyledFormItem
          label={"Ccылка для кнопки"}
          key={id + "_buttonLink"}
          name={"buttonLink"}
        >
          <Input maxLength={255}/>
        </StyledFormItem>
        <StyledFormItem
          label={"Текст для кнопки"}
          key={id + "_buttonText"}
          name={"buttonText"}
        >
          <Input />
        </StyledFormItem>

        <ButtonRow>
          <ButtonCol>
            <Button
              danger
              onClick={() => {
                if (initialValues) {
                  form.resetFields(["image"])
                  form.setFieldsValue(getInitialValues(initialValues))
                  return
                }
              }}
              icon={<RotateClockwiseSvg />}
            >
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button icon={<SaveSvg />} type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>
    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledTabs = styled(Tabs)`
  overflow: visible;

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: none;
    user-select: none;
  }

  &.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 3px 7px 6px;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    bottom: -3px;
    margin-bottom: 0;

    &::before {
      border-bottom: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

const StyledImage = styled(Image)`
  display: none;
`
