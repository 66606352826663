import api from "src/api/index";
import {STATE_MEDIA} from "src/config";

export async function getPointsFeatures() {
  try {
    const response =  await api.get(STATE_MEDIA + "api/dashboard/point-features")
    return response.data.items
  } catch (error) {
    console.error("ошибка загрузки особенностей точек", error?.message)
  }
}