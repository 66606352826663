import { Popconfirm, Space, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import styled from "styled-components"
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

export function getColumns(onConfirmPayment, onCanceledPayment) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
    },
    {
      title: "Номер заказа",
      dataIndex: "innerId",
      key: "innerId",
      sorter: (a, b) => a.innerId - b.innerId,
    },
    {
      title: "Итоговая сумма со скидкой",
      dataIndex: "totalPriceWithDiscount",
      key: "totalPriceWithDiscount"
    },
    {
      title: "Статус заказа",
      dataIndex: "orderStatus",
      key: "orderStatus",
      render: (text) => <StyledTag color="default">{text}</StyledTag>,
    },
    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Подтвердить" mouseLeaveDelay={0}>
            <Popconfirm
                placement="top"
                title="Действительно хотите подтвердить оплату?"
                onConfirm={() => onConfirmPayment(record.id)}
                onCancel={() => stopPropagation}
            >
              <Button type="link" onClick={stopPropagation}>
                <CheckCircleOutlined style={{ width: "24px"}}/>
              </Button>
            </Popconfirm>
          </Tooltip>

          <Tooltip placement="top" title="Отменить" mouseLeaveDelay={0}>
            <Popconfirm
              placement="left"
              title="Действительно хотите отменить оплату?"
              onConfirm={() => onCanceledPayment(record.id)}
              onCancel={() => stopPropagation}
            >
              <Button type="link" onClick={stopPropagation}>
                <CloseCircleOutlined style={{ width: "24px"}}/>
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]
}

const stopPropagation = (e) => {
  e?.stopPropagation()
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`
