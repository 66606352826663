import React from "react";
import {DatePicker, Form, Input, Row} from "antd";
import styled from "styled-components";
import {RotateClockwiseSvg, SaveSvg} from "../../components/Svg";
import {Button} from "../../components/actions/button";
import {SearchOutlined} from "@ant-design/icons";

export function ClientsFilters({handleSearch}) {

    const [form] = Form.useForm()

    function onSearch(values){
        let data = {
            phone: values.phone?.trim() ?? null,
            email: values.email?.trim() ?? null,
            dateFrom: values.dateFrom ? values.dateFrom.toISOString() : null,
            dateTo: values.dateTo ? values.dateTo.toISOString() : null,
        }
        handleSearch(data)
    }

    function onClear() {
        form.resetFields()
        handleSearch([])

    }

    return (
        <>
           <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSearch(values)}>
               <StyledFormItem
                   key={ "sort_phone" }
                   label={"По номеру телефона"}
                   name={"phone"}
               >
                   <Input placeholder="Номер телефона" />
               </StyledFormItem>

               <StyledFormItem
                   key={ "sort_email" }
                   label={"По email"}
                   name={"email"}
               >
                   <Input placeholder="Email"/>
               </StyledFormItem>

               <StyledFormItem
                   key={ "sort_date_from" }
                   label={"Cозданный (от)"}
                   name={"dateFrom"}
               >
                   <DatePicker />
               </StyledFormItem>

               <StyledFormItem
                   key={"sort_date_to"}
                   label={"Cозданный (до)"}
                   name={"dateTo"}
               >
                   <DatePicker />
               </StyledFormItem>
               <Button
                   danger
                   onClick={onClear}
                   icon={<RotateClockwiseSvg />}
               >
                   Сбросить
               </Button>
               <Button icon={<SearchOutlined />}  type="primary" htmlType="submit">
                   Искать
               </Button>
           </FormEdit>
        </>
    )
}

const FormEdit = styled(Form)`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`

const StyledFormItem = styled(Form.Item)`
  margin-right: 25px;
`