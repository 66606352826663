import { useAppData } from "src/store/app-data"
import styled from "styled-components"

export function CollapseTrigger() {
  const layoutConfig = useAppData((state) => state.layoutConfig)
  const setLayoutConfig = useAppData((state) => state.setLayoutConfig)

  function toggleCollapsed() {
    setLayoutConfig({
      isMobile: layoutConfig.isMobile,
      collapsed: !layoutConfig.collapsed,
      siderWidth: layoutConfig.collapsed ? 200 : 80,
    })
  }

  return (
    <TriggerWrapper className="ant-base-header-trigger" onClick={toggleCollapsed}>
      <svg
        className={`anticon ${!layoutConfig.collapsed ? "active" : ""}`}
        viewBox="0 0 100 100"
        width="54"
        height="54"
      >
        <path
          className="line top"
          d="m 30,33 h 40 c 0,0 8.5,-0.68551 8.5,10.375 0,8.292653 -6.122707,9.002293 -8.5,6.625 l -11.071429,-11.071429"
        />
        <path className="line middle" d="m 70,50 h -40" />
        <path
          className="line bottom"
          d="m 30,67 h 40 c 0,0 8.5,0.68551 8.5,-10.375 0,-8.292653 -6.122707,-9.002293 -8.5,-6.625 l -11.071429,11.071429"
        />
      </svg>
    </TriggerWrapper>
  )
}

const TriggerWrapper = styled.span`
  padding: ${(layoutConfig) => (layoutConfig.isMobile ? "0 10px" : "0")};
`
