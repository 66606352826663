import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks"
import { EditDrawer } from "src/pages-lib/promotion/edit-drawer"
import { useGetData, useManageItems } from "src/pages-lib/promotion/model"
import { getColumns } from "src/pages-lib/promotion/table-columns"
import styled from "styled-components"

export default function Promotions() {
  const [createDrawerState, setCreateDrawerState] = React.useState(false)
  const [editDrawerState, setEditDrawerState] = React.useState(false)

  // const [filterDrawerState, setFilterDrawerState] = React.useState(false)
  // const [sortableDrawerState, setSortableDrawerState] = React.useState(false)

  const {
    dataPromotions,
    dataPromotionsColumns,
    pagination,
    isLoading,
    handleTableChange,
    refetch: getItems,
    options,
  } = useGetData()

  const { editData, onSaveEdit, onCreate, onDelete, onEditItem } = useManageItems(
    dataPromotions,
    getItems,
    onSuccessEdit,
    onSuccessCreate
  )

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  function onSuccessCreate() {
    setCreateDrawerState(!createDrawerState)
  }

  const headerTitle = useUpdateHeaderTitle({
    items: dataPromotions,
    defaultTitle: "0 Акций",
    arrType: ["Акция", "Акции", "Акций"],
  })

  const columns = getColumns(onEditItem, onDelete)

  return (
    <>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            onCreate={() => setCreateDrawerState(true)}
            // onFilter={() => setFilterDrawerState(!filterDrawerState)}
            // onSort={() => setSortableDrawerState(!sortableDrawerState)}
            titles={{ onCreate: "Добавить акцию" }}
          />
        }
      />
      <StyledTable
        dataSource={dataPromotionsColumns}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
      />
      <VisibilityProvider visible={createDrawerState}>
        <EditDrawer
          onSave={onCreate}
          onClose={() => setCreateDrawerState(false)}
          title={"Создание Акции"}
          categoryOptions={options.categoryOptions}
          cityOptions={options.cityOptions}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onSave={onSaveEdit}
          onClose={() => setEditDrawerState(false)}
          initialValues={editData}
          title={"Редактирование Акции"}
          categoryOptions={options.categoryOptions}
          cityOptions={options.cityOptions}
        />
      </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
