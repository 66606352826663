import React, {useState} from "react"
import {
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
} from "antd"
import moment from "moment"
import { Button } from "src/components/actions/button"
import { DebounceSelect } from "src/components/debounce-select"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { DATE_FORMAT } from "src/config"
import { useAppData } from "src/store/app-data"
import { getProductHints } from "src/utils/getProductHints"
import styled from "styled-components"
import {getUserHints} from "../../utils/getUserHints";
import {DebounceSelectUser} from "../../components/debounce-select-user";
import {uniquePromocodeNameValidation, whiteSpaceValidation} from "../../utils/helpers";

export function EditPromocodes({ title, initialValues, onClose, onSave, categoryOptions, userStatusOptions }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  const { TextArea } = Input

  const [form] = Form.useForm()

  const [validateActiveTo, setValidateActiveTo] = useState(false)
  const [helpActiveTo, setHelpActiveTo] = useState(false)
  const [maxAmount, setMaxAmount] = useState(false)
  const [userDisabled, setUserDisabled] = useState(true)
  const [isForOneDisabled, setIsForOneDisabled] = useState(false)

  async function onSubmit(values) {
    const data = {
      ...values,
      ...((values.userId && values.isPrivate) && {userId: values.userId.value}),
      isForOnePosition: values.isForOnePosition ? 1 : 0,
      activeFrom: moment(values.activeFrom).toISOString(true),
      activeTo: moment(values.activeTo).toISOString(true)
    }
    await onSave(data)
    form.resetFields()
  }

  function onChangeActiveTo(value) {

    if (form.getFieldValue("activeFrom")) {
      if (form.getFieldValue("activeFrom") > value) {
        setValidateActiveTo("error")
        setHelpActiveTo("Дата ДО не может быть меньше")
        form.setFieldValue("activeTo", null)
      } else {
        setValidateActiveTo(false)
        setHelpActiveTo(false)
      }
    } else {
      setValidateActiveTo("error")
      setHelpActiveTo("Сначала выберите дату ОТ")
      form.setFieldValue("activeTo", null)
    }
  }
  function getInitialValues(initialValues) {
    return {
      ...initialValues,
      activeFrom: moment(initialValues.activeFrom, "YYYY-MM-DD"),
      activeTo: moment(initialValues.activeTo, "YYYY-MM-DD"),
      typeId: initialValues.type?.id,
      name: initialValues.promocode,
    }
  }
  function onChangeUserPrivate(value) {
    setUserDisabled(!value.target.value)
  }

  React.useEffect(() => {
    if (initialValues) {
      setUserDisabled(!initialValues.isPrivate)
      form.setFieldsValue(getInitialValues(initialValues))
      if (initialValues.type?.id && parseInt(initialValues.type?.id) === 1) {
        form.setFieldValue("isForOnePosition", 0)
        setIsForOneDisabled(true)
      }
    }
  }, [initialValues, getInitialValues])

  const id = initialValues?.id ?? "new"

  function handleClose() {
    onClose()
    form.resetFields()
  }

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={title}
      onClose={handleClose}
      open
    >
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <StyledFormItem
          key={id + "_name"}
          label={"Название"}
          name={"name"}
          rules={[
            {
              required: initialValues?.id,
              message: "Введите название",
            },
            {
              validator: !initialValues?.id ? uniquePromocodeNameValidation : whiteSpaceValidation
            }
          ]}
        >
          <Input value={form.promocode} type={"text"} placeholder={"Название"} />
        </StyledFormItem>
        <StyledFormItem
          key={id + "_description"}
          label={"Описание"}
          name={"description"}
          rules={[
            {
              required: true,
              message: "Введите описание",
            },
          ]}
        >
          <TextArea
            rows={4}
            value={form.description}
            type={"text"}
            placeholder={"Описание"}
          />
        </StyledFormItem>
        <StyledFormItem
          key={id + "_minPrise"}
          label={"Минимальная сумма корзины"}
          name={"minPrice"}
          rules={[
            {
              required: true,
              message: "Введите минимальную сумму",
            },
          ]}
        >
          <InputNumber
            value={form.minPrice}
            min={1}
            style={{
              width: "100%",
            }}
            placeholder={"Минимальная сумма корзины"}
          />
        </StyledFormItem>
        <StyledFormItem
          key={id + "_code"}
          label={"Код"}
          name={"code"}
          rules={[
            {
              required: true,
              message: "Введите код",
            },
          ]}
        >
          <Input value={form.code} type={"text"} placeholder={"Код"} />
        </StyledFormItem>
        <StyledFormItem
          key={id + "_type"}
          label={"Тип"}
          name={"typeId"}
          rules={[
            {
              required: true,
              message: "Выберите тип",
            },
          ]}
        >
          <Select
            value={form.typeId}
            onChange={(value) => {
              if (value === 1) {
                setMaxAmount(false)
                setIsForOneDisabled(true)
                form.setFieldValue("isForOnePosition", 0)
              } else {
                setMaxAmount(100)
                setIsForOneDisabled(false)
              }
            }}
            options={[
              {
                value: 1,
                label: "Рубли",
              },
              {
                value: 2,
                label: "Проценты",
              },
            ]}
          />
        </StyledFormItem>
        <StyledFormItem
          key={id + "_amount"}
          label={"Скидка"}
          name={"amount"}
          rules={[
            {
              required: true,
              message: "Введите скидку",
            },
          ]}
        >
          <InputNumber
            value={form.amount}
            min={1}
            max={maxAmount}
            style={{
              width: "100%",
            }}
            placeholder={"Скидка"}
          />
        </StyledFormItem>
        <StyledFormItem
            key={id + "_isForOnePosition"}
            label={"Область применения"}
            name={"isForOnePosition"}
            initialValue={!initialValues && 0}
            rules={[
              {
                required: true,
                message: "Выберите область",
              },
            ]}
        >
          <Radio.Group disabled={isForOneDisabled}>
            <Radio value={0}>Для всего заказа</Radio>
            <Radio value={1}>Для 1-ой позиции</Radio>
          </Radio.Group>
        </StyledFormItem>
        <StyledFormItem
          key={id + "_activeFrom"}
          label={"Активность (от)"}
          name={"activeFrom"}
          rules={[
            {
              required: true,
              message: "Выберите дату",
            },
          ]}
        >
          <DatePicker
            style={{
              width: "100%",
            }}
            format={DATE_FORMAT}
            placeholder={"Выберите дату"}
          />
        </StyledFormItem>
        <StyledFormItem
          key={id + "_activeTo"}
          label={"Активность (до)"}
          help={helpActiveTo}
          name={"activeTo"}
          validateStatus={validateActiveTo}
          rules={[
            {
              required: true,
              message: "Выберите дату",
            },
          ]}
        >
          <DatePicker
            onChange={onChangeActiveTo}
            style={{
              width: "100%",
            }}
            format={DATE_FORMAT}
            placeholder={"Выберите дату"}
          />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_isDisposable"}
            label={"Одноразовый для всех"}
            name={"isDisposable"}
            initialValue={form.isDisposable ? form.isPublic : true}
            rules={[
              {
                required: true,
                message: "Выберите свой вариант",
              },
            ]}
        >
          <Radio.Group buttonStyle="solid" value={form.isDisposable}>
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_isDisposableForUser"}
            label={"Одноразовый для пользователя"}
            name={"isDisposableForUser"}
            initialValue={form.isDisposableForUser ? form.isPublic : true}
            rules={[
              {
                required: true,
                message: "Выберите свой вариант",
              },
            ]}
        >
          <Radio.Group buttonStyle="solid" value={form.isDisposableForUser}>
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>
        <StyledFormItem
            key={id + "_isForFirstOrder"}
            label={"На первый заказ через МП"}
            name={"isForFirstOrder"}
            initialValue={form.isForFirstOrder ? form.isPublic : true}
            rules={[
              {
                required: true,
                message: "Выберите свой вариант",
              },
            ]}
        >
          <Radio.Group buttonStyle="solid" value={form.isForFirstOrder}>
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>
        <StyledFormItem
          key={id + "_isActive"}
          label={"Включен"}
          name={"isActive"}
          initialValue={form.isActive ? form.isActive : true}
          rules={[
            {
              required: true,
              message: "Выберите свой вариант",
            },
          ]}
        >
          <Radio.Group buttonStyle="solid" value={form.isActive}>
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>
        <StyledFormItem
          key={id + "_isPublic"}
          label={"Показывать на сайте"}
          name={"isPublic"}
          initialValue={form.isPublic ? form.isPublic : true}
          rules={[
            {
              required: true,
              message: "Выберите свой вариант",
            },
          ]}
        >
          <Radio.Group buttonStyle="solid" value={form.isPublic}>
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>

        <StyledFormItem
          key={id + "_whiteList"}
          label={"Для товаров"}
          name={"whiteList"}
        >
          <DebounceSelect
            mode="multiple"
            value={""}
            placeholder="Выберите продукт"
            fetchOptions={getProductHints}
            style={{
              width: "100%",
            }}
          />
        </StyledFormItem>

        <StyledFormItem
          key={id + "_blackList"}
          label={"Кроме товаров"}
          name={"blackList"}
        >
          <DebounceSelect
            mode="multiple"
            value={""}
            placeholder="Выберите продукт"
            fetchOptions={getProductHints}
            style={{
              width: "100%",
            }}
          />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_categoryWhiteList"}
            label={"Для категорий товаров"}
            name={"categoryWhiteList"}
        >
          <Select mode="multiple" options={categoryOptions} placeholder={"Выберите категорию"} disabled={false} />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_categoryBlackList"}
            label={"Кроме категорий товаров"}
            name={"categoryBlackList"}
        >
          <Select mode="multiple" options={categoryOptions} placeholder={"Выберите категорию"} disabled={false} />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_isPrivate"}
            label={"Привязать к пользователю"}
            name={"isPrivate"}
            initialValue={form.isPrivate ? form.isPrivate : false}
            rules={[
              {
                required: true,
                message: "Выберите свой вариант",
              },
            ]}
        >
          <Radio.Group buttonStyle="solid" onChange={onChangeUserPrivate} value={form.isPrivate}>
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_userId"}
            label={"Пользователь"}
            name={"userId"}
        >
          <DebounceSelectUser
              showSearch
              value={""}
              placeholder="Введите номер телефона"
              fetchOptions={getUserHints}
              style={{
                width: "100%",
              }}
              disabled={userDisabled}
          />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_statuses"}
            label={"Доступен от уровня"}
            name={"statuses"}
        >
          <Select mode={"multiple"} options={userStatusOptions} placeholder={"Выберите уровень"} disabled={false} />
        </StyledFormItem>


        <ButtonRow>
          <ButtonCol>
            <Button
              danger
              onClick={() => {
                if (initialValues) {
                  form.setFieldsValue(getInitialValues(initialValues))
                  return
                }
                form.resetFields()
              }}
              icon={<RotateClockwiseSvg />}
            >
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button icon={<SaveSvg />} type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>
    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`
