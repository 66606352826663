import {Input} from "antd";
import React from "react";
import {VisibilityProvider} from "../visibility-provider";
import {LinkBuilderDrawer} from "./link-builder-drawer";

export  function LinkBuilder({onSetValue,...props}) {
    const [builderSuccess, setBuilderSuccess] = React.useState(false)
    const [buildDrawerState, setBuildDrawerState] = React.useState(false)
    const [inputValue, setInputValue] = React.useState("")

    React.useEffect(() => {
        if(props.value) {
            setInputValue(props.value)
        }
    })
    function handleDrawerClose(){
        setBuildDrawerState(false)
    }

    function handleFocus() {
        setBuildDrawerState(true)
    }

    function successBuilding(value) {
        setInputValue(value)
        onSetValue(value)
        setBuildDrawerState(false)
    }
    return (
        <>
            <Input
                onFocus={handleFocus}
                {...props}
            />

            <VisibilityProvider visible={buildDrawerState}>
                <LinkBuilderDrawer
                    title={"Конструктор ссылки"}
                    onClose={handleDrawerClose}
                    onBuild={successBuilding}
                />
            </VisibilityProvider>
        </>
    )
}