import React from "react"
import api from "src/api"

export function useGetCategories() {
  const [pagination, setPagination] = React.useState(null)
  const [paginationParams, setPaginationParams] = React.useState({
    pagination: {
      current: 1,
      pageSize: 30,
    },
  })

  const [items, setItems] = React.useState([])

  async function getItems() {
    try {
      const { data } = await api.get("event-categories")
      setPagination(data.pagination)
      setPaginationParams({
        pagination: data.pagination,
      })
      updateItems(data.items)
    } catch (error) {
      console.error("Ошибка получения данных", error)
    }
  }

  React.useEffect(() => {
    getItems()
  }, [])

  function updateItems(items) {
    const newData = getFormattedItems(items)
    setItems(newData)
  }

  function getFormattedItems(items) {
    const data = items.map((item) => ({
      id: item.id,
      name: item.name,
      nameRu: item.name?.find((el) => el.language === "ru")?.content,
      isActive: item.isActive,
      sortWeight: item.sortWeight,
    }))

    return data
  }

  return { items, pagination, paginationParams, refetch: () => getItems() }
}
