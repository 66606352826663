import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks"
import { EditDrawer } from "src/pages-lib/articles/edit-drawer"
import { useGetData, useManageItems } from "src/pages-lib/articles/model"
import { getColumns } from "src/pages-lib/articles/table-columns"
import styled from "styled-components"

export default function Articles() {
  const [createDrawerState, setCreateDrawerState] = React.useState(false)
  const [editDrawerState, setEditDrawerState] = React.useState(false)

  const [filterDrawerState, setFilterDrawerState] = React.useState(false)
  const [sortableDrawerState, setSortableDrawerState] = React.useState(false)

  const {
    dataArticles,
    dataArticlesColumns,
    pagination,
    isLoading,
    handleTableChange,
    refetch: getItems,
    options,
  } = useGetData()

  const { editData, resetData, onSaveEdit, onCreate, onDelete, onEditItem } =
    useManageItems(dataArticles, getItems, onSuccessEdit, onSuccessCreate)

  const headerTitle = useUpdateHeaderTitle({
    items: dataArticles,
    defaultTitle: "0 Новостей",
    arrType: ["Новостей", "Новости", "Новостей"],
  })

  const columns = getColumns(onEditItem, onDelete)

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  function onSuccessCreate() {
    setCreateDrawerState(!createDrawerState)
  }

  return (
    <>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            onCreate={() => setCreateDrawerState(true)}
            // onFilter={() => setFilterDrawerState(!filterDrawerState)}
            // onSort={() => setSortableDrawerState(!sortableDrawerState)}
            titles={{ onCreate: "Добавить новость" }}
          />
        }
      />
      <StyledTable
        dataSource={dataArticlesColumns}
        columns={columns}
        loading={isLoading}
        onChange={handleTableChange}
        pagination={pagination}
      />

      <VisibilityProvider visible={createDrawerState}>
        <EditDrawer
          onSave={onCreate}
          onClose={() => setCreateDrawerState(false)}
          title={"Создание новости"}
          visible={createDrawerState}
          cityOptions={options.cityOptions}
          categoryOptions={options.categoryOptions}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onSave={onSaveEdit}
          onClose={() => {
            setEditDrawerState(false)
            resetData()
          }}
          initialValues={editData}
          title={"Редактирование новости"}
          visible={editDrawerState}
          cityOptions={options.cityOptions}
          categoryOptions={options.categoryOptions}
        />
      </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
