import React from "react";
import api from "src/api";

export function useGetProductConstructor() {
    const [pagination, setPagination] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [items, setItems] = React.useState([]);

    async function getItems(page = 1, countOnPage = 20) {
        setIsLoading(true);
        try {
            const response = await api.get(`product-constructor?page=${page}&countOnPage=${countOnPage}`);


            setPagination({
                current: response.data.pagination?.currentPage ?? page,
                pageSize: response.data.pagination?.countOnPage ?? countOnPage,
                total: response.data.pagination?.total ?? response.data.items.length
            });
            setItems(response.data.productConstructors);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }


    const handleTableChange = (pagination) => {
        getItems(pagination.current, pagination.pageSize )
    };

    React.useEffect(() => {
        getItems();
    }, []);

    return {
        items,
        pagination,
        isLoading,
        refetch: () => getItems(),
        handleTableChange,
    };
}


export function getFormattedItems(items) {
    const data = items.map((item) => ({
        key: item.id,
        id: item.id,
        name: item.name,
        nameRu: item.name?.find((el) => el.language === "ru")?.content,
        nameComponent: item.nameComponent,
        nameComponentRu: item.nameComponent?.find((el) => el.language === "ru")?.content,
        nameType: item.nameType,
        nameTypeRu: item.nameType?.find((el) => el.language === "ru")?.content,
        productId: item.productId,
        mainProductId: item.mainProductId,
        clothesSize: item.clothesSize,
        size: item.size,
        externalComponentGuid: item.externalComponentGuid,
        externalGuid: item.externalGuid,
        externalProductGuid: item.externalProductGuid,
        externalSizeGuid: item.externalSizeGuid,
    }))

    return data
}