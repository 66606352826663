import { Button } from "antd"
import { ArrowsSortSvg, FilterSvg, PlusSvg } from "src/components/Svg/Common"

export function ExtraButtons({ onCreate, onFilter, onSort, titles }) {
  return (
    <>
      {onCreate && (
        <Button type="primary" onClick={onCreate} icon={<PlusSvg />}>
          {titles.onCreate ?? "Добавить"}
        </Button>
      )}
      {onFilter && (
        <Button type="primary" onClick={onFilter} icon={<FilterSvg />}>
          {titles.onFilter ?? "Фильтр"}
        </Button>
      )}
      {onSort && (
        <Button type="primary" onClick={onSort} icon={<ArrowsSortSvg />}>
          {titles.onSort ?? "Сортировка"}
        </Button>
      )}
    </>
  )
}
