import React from "react"
import api from "src/api"

export function useGetCategories() {
  const [paginationParams, setPaginationParams] = React.useState({
    pagination: {
      current: 1,
      pageSize: 30,
    },
  })

  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    getItems()
  }, [])

  async function getItems() {
    try {
      const { data } = await api.get("promotion-categories")
      setPaginationParams({
        pagination: data.pagination,
      })
      updateItems(data.items)
    } catch (error) {
      console.error("Ошибка получения данных", error)
    }
  }

  function updateItems(items) {
    const newData = getFormattedItems(items)
    setItems(newData)
  }

  function getFormattedItems(items) {
    let data = []
    items.forEach((item) => {
      let nameItem = item.name
      let name = nameItem.find((el) => el.language === "ru")
      data.push({
        id: item.id,
        name: item.name,
        nameRu: name.content,
        isActive: item.isActive,
        sortWeight: item.sortWeight,
      })
    })
    return data
  }

  return { items, paginationParams, refetch: () => getItems() }
}
