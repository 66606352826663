import { Popconfirm, Space, Tooltip } from "antd"
import moment from "moment"
import { Button } from "src/components/actions/button"
import { PencilSvg, TrashSvg } from "src/components/Svg"

export function getColumns(onEdit, onDelete) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
    },
    {
      title: "Название группы",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Описание группы",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Количество историй",
      dataIndex: "stories",
      key: "stories",
      sorter: (a, b) => a.stories.length - b.stories.length,
      render: (stories) => <p>{stories.length}</p>,
    },
    {
      title: "Начало публикации",
      dataIndex: "startedAt",
      sorter: (a, b) => a.startedAt - b.startedAt,
      render: (startedAt) => moment(startedAt).format("DD.MM.YYYY h:mm"),
    },
    {
      title: "Конец публикации",
      dataIndex: "finishedAt",
      sorter: (a, b) => a.finishedAt - b.finishedAt,
      render: (finishedAt) => moment(finishedAt).format("DD.MM.YYYY h:mm"),
    },
    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.id)}>
              <PencilSvg />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
            <Popconfirm
              placement="left"
              title="Действительно хотите удалить?"
              onConfirm={() => onDelete(record.id)}
              onCancel={() => stopPropagation}
            >
              <Button size="small" type="link" onClick={stopPropagation}>
                <TrashSvg />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]
}

const stopPropagation = (e) => {
  e?.stopPropagation()
}
