import {Avatar, Image, Popconfirm, Space, Tag, Tooltip} from "antd";
import { Button } from "src/components/actions/button";
import { PencilSvg, TrashSvg } from "src/components/Svg";
import styled from "styled-components";

export function getColumns(onEdit, onDelete) {
    return [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 50,
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "ascend",
        },
        {
            title: "Полное название",
            dataIndex: "nameRu",
            key: "nameRu",
            sorter: (a, b) => a.nameRu - b.nameRu,
        },
        {
            title: "Название продукта",
            dataIndex: "nameTypeRu",
            key: "nameTypeRu",
            sorter: (a, b) => a.nameTypeRu - b.nameTypeRu,
        },
        {
            title: "Название компонента",
            dataIndex: "nameComponentRu",
            key: "nameComponentRu",
            sorter: (a, b) => a.nameComponentRu - b.nameComponentRu,
        },
        {
            title: "ID продукта",
            dataIndex: "productId",
            key: "productId",
        },
        {
            title: "Внешний ID",
            dataIndex: "mainProductId",
            key: "mainProductId",
        },
        {
            title: "Действие",
            key: "action",
            width: 120,
            fixed: "right",
            render: (_, record) => (
                <Space size="small">
                     <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
                        <Button size="small" type="link" onClick={() => onEdit(record.id)}>
                            <PencilSvg />
                        </Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
                        <Popconfirm
                            placement="left"
                            title="Действительно хотите удалить?"
                            onConfirm={() => onDelete(record.id)}
                            onCancel={() => stopPropagation}
                        >
                            <Button size="small" type="link" onClick={stopPropagation}>
                                <TrashSvg />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];
}

const stopPropagation = (e) => {
    e?.stopPropagation();
};

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`;
const StyledImage = styled(Image)`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
