import React from "react"
import { PlusOutlined } from "@ant-design/icons"
import {
  Col, Divider,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Tabs,
  Upload,
} from "antd"
import { Button } from "src/components/actions/button"
import { DebounceSelect } from "src/components/debounce-select"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import {
  TranslatableInput,
  TranslatableTextarea,
  TranslatableWysiwyg,
} from "src/components/Translatable"
import { useImage } from "src/hooks"
import { useAppData } from "src/store/app-data"
import { getProductHints } from "src/utils/getProductHints"
import {
  getFormattedLangData,
  getFormData,
  getInitialValues,
  getNestedFormData,
  getOldImage,
} from "src/utils/helpers"
import styled from "styled-components"
import {LinkBuilder} from "../../components/link-builder/link-builder";

export function EditDrawer({
  title,
  initialValues,
  onClose,
  onSave,
  categoryOptions,
  cityOptions,
}) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  const [form] = Form.useForm()

  const { getImage, imageUpdated, onPreview, onChange, preview } = useImage()

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  function prepareData(values) {
    const file = getImage(values.image)
    const id = initialValues?.id
    const isNeedImage = !id || (id && imageUpdated)

    const data = {
      ...(id && { id, _method: "PUT"  }),
      url: values.url ?? null,
      promotionCategoryId: values.promotionCategoryId,
      percent: values.percent,
      buttonLink: values.buttonLink ?? null,
      buttonText: values.buttonText ?? null,
      isActive: values.isActive ? 1 : 0,
      sortWeight: values.sortWeight ?? 0,
      ...(isNeedImage && { image: file }),
    }

    return data
  }

  function handleSetValue(value) {
    form.setFieldValue("buttonLink", value)
  }

  async function onSubmit(values) {
    const baseData = prepareData(values)
    const formData = getFormData({
      values: baseData,
      image: baseData.image,
    })

    const data = getFormattedLangData(values)

    const dataWithNestedItem = {
      name: data.name,
      description: data.description,
      content: data.content,
      pointIds: data.pointIds,
      productIds: data.productIds?.map((item) => item.value) ?? [],
    }

    const formDataWithNestedFormData = getNestedFormData(
      dataWithNestedItem,
      formData
    )

    await onSave(formDataWithNestedFormData, initialValues?.id)
    onClose()
    form.resetFields()
  }

  function selectAllPoints() {
    let all = [];
    cityOptions.forEach((item) => {
      all.push(item.value)
    })
    form.setFieldValue("pointIds", all)
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }

  const id = initialValues?.id ?? "new"

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={title}
      onClose={handleClose}
      open
    >
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <StyledFormItem
          name={"image"}
          label={"Обложка"}
          key={id + "_IMAGE"}
          rules={[
            {
              required: true,
              message: "Выберите изображение!",
            },
          ]}
        >
          <Upload
            listType="picture-card"
            maxCount={1}
            onPreview={onPreview}
            status="done"
            beforeUpload={() => false}
            onChange={onChange}
            defaultFileList={getOldImage(initialValues?.image)}
            dependencies={"image"}
            accept="image/jpeg,image/png,image/jpg"
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Загрузить изображение</div>
            </div>
          </Upload>
        </StyledFormItem>
        <StyledImage
          alt={preview.previewImage?.name || "Превью"}
          preview={{
            visible: preview.visible,
            onVisibleChange: preview.onVisibleChange,
          }}
          src={preview.previewImage?.largePreview || preview.previewImage?.url}
        />
        <StyledFormItem key={id + "_name"} label={"Название"}>
          <TranslatableInput name={"name"} id={id}></TranslatableInput>
        </StyledFormItem>

        <StyledFormItem
          key={id + "_promotionCategoryId"}
          label={"Категория"}
          name={"promotionCategoryId"}
          rules={[
            {
              required: true,
              message: "Выберите категорию!",
            },
          ]}
        >
          <Select options={categoryOptions} />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_sortWeight"}
            label={"Позиция в списке"}
            name={"sortWeight"}
            rules={[
              {
                required: true,
                message: "Введите позицию!",
              },
            ]}
        >
          <InputNumber
              min={0}
              style={{
                width: "100%",
              }}
              placeholder={"Позиция"}
          />
        </StyledFormItem>



        <StyledFormItem
          key={id + "_pointIds"}
          label={"Точки (несколько)"}
          name={"pointIds"}
          rules={[
            {
              required: true,
              message: "Выберите точки!",
            },
          ]}
        >
          <Select mode="multiple" options={cityOptions} />
        </StyledFormItem>
        <Button
            type="dashed"
            onClick={selectAllPoints}
            style={{ marginBottom: "20px" }}
        >
          выбрать все точки
        </Button>

        <StyledFormItem
          label={"Процент скидки"}
          key={id + "_percent"}
          name={"percent"}
        >
          <InputNumber
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
          />
        </StyledFormItem>

        <StyledFormItem
          key={id + "_productIds"}
          label={"Продукты"}
          name={"productIds"}
        >
          <DebounceSelect
            mode="multiple"
            value={""}
            placeholder="Выберите продукты"
            fetchOptions={getProductHints}
            style={{
              width: "100%",
            }}
          />
        </StyledFormItem>

        <StyledFormItem label={"URL"} key={id + "_url"} name={"url"}>
          <Input />
        </StyledFormItem>

        <StyledFormItem label={"Контент"} key={id + "_content"}>
          <TranslatableWysiwyg
            name={"content"}
            value={"test content for edit"}
          ></TranslatableWysiwyg>
        </StyledFormItem>

        <StyledFormItem label={"Описание"} key={id + "_description"}>
          <TranslatableTextarea id={id} name={"description"}></TranslatableTextarea>
        </StyledFormItem>

        <StyledFormItem
          key={id + "_isActive"}
          label={"Включен"}
          name={"isActive"}
          initialValue={form.isActive ? form.isActive : true}
          rules={[
            {
              required: true,
              message: "Please select!",
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            value={form.isActive ? form.isActive : true}
          >
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>

        <Divider orientation="left">Кнопка для перехода</Divider>

        <StyledFormItem
            label={"Ccылка для кнопки"}
            key={id + "_buttonLink"}
            name={"buttonLink"}
        >

          <LinkBuilder
              value={initialValues?.buttonLink ?? null}
              placeholder={"Ссылка"}
              onSetValue={handleSetValue}
          />

        </StyledFormItem>
        <StyledFormItem
            label={"Текст для кнопки"}
            key={id + "_buttonText"}
            name={"buttonText"}
        >
          <Input />
        </StyledFormItem>

        <ButtonRow>
          <ButtonCol>
            <Button
              danger
              onClick={() => {
                if (initialValues) {
                  form.resetFields(["image"])
                  form.setFieldsValue(getInitialValues(initialValues))
                  return
                }
                form.resetFields()
              }}
              icon={<RotateClockwiseSvg />}
            >
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button icon={<SaveSvg />} type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>
    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledTabs = styled(Tabs)`
  overflow: visible;

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: none;
    user-select: none;
  }

  &.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 3px 7px 6px;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    bottom: -3px;
    margin-bottom: 0;

    &::before {
      border-bottom: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

const StyledImage = styled(Image)`
  display: none;
`
