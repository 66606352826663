import { Card, PageHeader } from "antd"
import { useTitle } from "src/hooks/use-title"
import styled from "styled-components"

export function Header({ title, extra }) {
  useTitle(title)

  return (
    <StyledCard bordered={false}>
      <StyledPageHeader
        title={title}
        extra={extra}
        onBack={() => window.history.back()}
      />
    </StyledCard>
  )
}

const StyledPageHeader = styled(PageHeader)`
  padding: 0 8px;

  .ant-page-header-heading {
    flex-wrap: wrap;

    .ant-page-header-heading-extra {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .ant-page-header-heading-title {
      font-weight: 500;
    }
  }
`

const StyledCard = styled(Card)`
  background-color: #e9ebf0;

  .ant-card-body {
    padding: 20px 16px 20px 20px;

    @media (max-width: 768px) {
      padding: 10px 6px 10px 10px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
