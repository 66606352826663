import {Button} from "../../components/actions/button";
import {MessageOutlined, SyncOutlined} from "@ant-design/icons";
import {Table, Tooltip, Space, Tag, Popconfirm} from "antd";
import styled from "styled-components";


export default function CertificateTable({onResend, openResendToEmail, dataSource, pagination, loading, onChange}) {

    const expandedRowRender = (data) => {
        const columns = [
            {
                title: "Email для отправки",
                dataIndex: "emailForCertificate",
                key: "emailForCertificate",
            },

            {
                title: "Действия",
                key: "action",
                render: (_, record) => (
                    <Space size="small">
                        <Tooltip placement="top" title="Повторная отравка" mouseLeaveDelay={0}>
                            <Button size="small" type="link" onClick={() => onResend(record.emailForCertificate, record.certificateId)}>
                                <MessageOutlined style={{ fontSize: "16px" }} />
                            </Button>
                        </Tooltip>
                    </Space>

                ),
            },
        ];
        return <Table columns={columns} dataSource={data} pagination={false} />;
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id - b.id,
            defaultSortOrder: "ascend",
        },
        {
            title: "Код сертификата",
            dataIndex: "certificateCode",
            key: "certificateCode",
        },
        {
            title: "Сумма",
            dataIndex: "sum",
            key: "sum",
        },
        {
            title: "Блокировка сертификата",
            dataIndex: "isBlock",
            key: "isBlock",
            render: (text) => (
                <StyledTag color={text ? "rgb(211, 47, 47)" : "rgb(56, 142, 60)" }>
                    {text ? "ДА" : "НЕТ"}
                </StyledTag>
            ),
        },
        {
            title: "Email для отправки",
            dataIndex: "emailForCertificate",
            key: "emailForCertificate",
        },

        {
            title: "Действия",
            key: "action",
            render: (_, record) => (
                <Space size="small">
                    <Popconfirm
                        placement="left"
                        title="Отправить на указанный email?"
                        onConfirm={() => onResend(record.emailForCertificate, record.id)}
                        onCancel={() => stopPropagation}
                    >
                        <Tooltip placement="top" title="Повторная отравка на указанный email" mouseLeaveDelay={0}>
                            <Button size="small" type="link" onClick={stopPropagation}>
                                <SyncOutlined style={{ fontSize: "16px" }} />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                    <Popconfirm
                        placement="left"
                        title="Отправить на другой email?"
                        onConfirm={() => openResendToEmail(record.id)}
                        onCancel={() => stopPropagation}
                    >
                        <Tooltip placement="top" title="Отравка на другой email" mouseLeaveDelay={0}>
                            <Button size="small" type="link" onClick={stopPropagation}>
                                <MessageOutlined style={{ fontSize: "16px" }} />
                            </Button>
                        </Tooltip>
                    </Popconfirm>

                </Space>

            ),
        },

    ];

    return (
        <>
            <Table
                columns={columns}
                /*expandable={{
                    expandedRowRender: (record) => expandedRowRender(record.orders)
                }}*/
                dataSource={dataSource}
                pagination={pagination}
                loading={loading}
                onChange={onChange}
            />
        </>
    )
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`;

const stopPropagation = (e) => {
    e?.stopPropagation();
};
