import { create } from 'zustand';

export const useAppData = create((set) => ({
  user: null,
  setUser: (data: {email:string, name: string, role: string}) => set({user: data}),
  layoutConfig: {
    isMobile: false,
    collapsed: true,
    siderWidth: 80
  },
  setLayoutConfig: (layoutConfig: {isMobile: boolean, collapsed: boolean, siderWidth: number}) => set({layoutConfig}),
}));
