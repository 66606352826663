import React from "react"
import {
  Col, DatePicker,
  Drawer,
  Form,
  Image,
  Row,
  Select,
  Tabs,
} from "antd"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import {
  getInitialValues,
} from "src/utils/helpers"
import styled from "styled-components"
import moment from "moment";


export function EditDrawer({
  title,
  initialValues,
  onClose,
  onSave,
  pointOptions,
}) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  const [form] = Form.useForm()

  React.useEffect(() => {
    if (initialValues) {
      console.log(initialValues)
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])


  function prepareData(values) {
    const id = initialValues?.id

    const data = {
      ...(id && { id:  id}),
      date: moment(values.date).format("YYYY-MM-DD"),
      pointIds: values.pointIds,
    }
    console.log(data)

    return data
  }

  async function onSubmit(values) {
    const baseData = prepareData(values)

    await onSave(baseData, initialValues?.id)
    onClose()
    form.resetFields()
  }

  function selectAllPoints() {
    let all = [];
    pointOptions.forEach((item) => {
      all.push(item.value)
    })
    form.setFieldValue("pointIds", all)
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }

  const id = initialValues?.id ?? "new"

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={title}
      onClose={handleClose}
      open
    >
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <StyledFormItem
            key={id + "_date"}
            label={"Дата"}
            name={"date"}
            rules={[
              {
                required: true,
                message: "Выберите дату!",
              },
            ]}
        >
          <DatePicker
              style={{
                width: "100%",
              }}
              placeholder={"Выберите дату"}
          />
        </StyledFormItem>

        <StyledFormItem
          key={id + "_pointIds"}
          label={"Точки (несколько)"}
          name={"pointIds"}
          rules={[
            {
              required: true,
              message: "Выберите точки!",
            },
          ]}
        >
          <Select mode="multiple" options={pointOptions} />
        </StyledFormItem>
        <Button
            type="dashed"
            onClick={selectAllPoints}
            style={{ marginBottom: "20px" }}
        >
          выбрать все точки
        </Button>

        <ButtonRow>
          <ButtonCol>
            <Button
              danger
              onClick={() => {
                if (initialValues) {
                  form.setFieldsValue(getInitialValues(initialValues))
                  return
                }
                form.resetFields()
              }}
              icon={<RotateClockwiseSvg />}
            >
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button icon={<SaveSvg />} type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>
    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledTabs = styled(Tabs)`
  overflow: visible;

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: none;
    user-select: none;
  }

  &.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 3px 7px 6px;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    bottom: -3px;
    margin-bottom: 0;

    &::before {
      border-bottom: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

const StyledImage = styled(Image)`
  display: none;
`
