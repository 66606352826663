import React from "react";
import {
    Col,

    Drawer, Form, Input, InputNumber, Popconfirm, Row, Select
} from "antd";
import {useAppData} from "../../store/app-data";
import styled from "styled-components";
import {Button} from "../../components/actions/button";
import {getInitialValues} from "../../utils/helpers";
import {CloseSvg, RotateClockwiseSvg, SaveSvg} from "../../components/Svg";

export function RefusedDrawer({
                            title,
                            onClose,
                            initialValues,
                            orderData,
                            onSaveChange,
                            refusedDelivery
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);

    const [allSumm, setAllSumm] = React.useState(0)

    const [form] = Form.useForm()

    React.useEffect(() => {
        if (initialValues) {
            let allStateSumm = 0
            initialValues.forEach((value) => {
                allStateSumm += value.totalPriceWithDiscount
            })
            if (refusedDelivery) {
                allStateSumm += orderData?.dashboardOrder?.deliveryPrice
            }
            setAllSumm(allStateSumm)
        }
    }, [initialValues, orderData])

    function onSubmit() {
        const newForm =  new FormData()

        newForm.append("orderId", orderData.orderId)
        initialValues.forEach((data, index) => {
            newForm.append(`positions[${index}][positionId]`, data.id)
            newForm.append(`positions[${index}][count]`, form.getFieldValue(data.id + "_refusedItemCount"))
        })
        newForm.append("refusedDelivery", refusedDelivery)

        onSaveChange(newForm, orderData.orderId)
        onClose()
    }

    function changeCountItems(value, positionId) {
        let allStateSumm = 0
        initialValues.forEach((value) => {
            allStateSumm += value.priceWithDiscount * form.getFieldValue(value.id + "_refusedItemCount")
        })

        if (refusedDelivery) {
            allStateSumm += orderData?.dashboardOrder?.deliveryPrice
        }
        setAllSumm(allStateSumm)
    }

    const id = orderData?.orderId ?? "new"

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={onClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>
                <Row gutter={24} style={{ marginBottom: "16px" }}>
                    <Col className="gutter-row" key={"name"} span={12}>
                        <strong>Название</strong>
                    </Col>
                    <Col className="gutter-row" key={"count"} span={6}>
                        <strong>Количество</strong>
                    </Col>
                    <Col className="gutter-row" key={"summ"} span={6}>
                        <strong>Цена за 1 шт.</strong>
                    </Col>
                </Row>
                {initialValues && initialValues.map((refusedItem, index) => (
                    <>
                        <Row gutter={24} style={{ marginBottom: "16px" }}>
                            <Col className="gutter-row" key={"name_" + index} span={12}>
                                {refusedItem.name}
                            </Col>
                            <Col className="gutter-row" key={"count_" + index} span={6}>
                                <StyledFormItem
                                    key={refusedItem.id + "_refusedItemCount"}
                                    name={refusedItem.id + "_refusedItemCount"}
                                    initialValue={refusedItem.count}
                                >
                                    <InputNumber
                                        max={refusedItem.count}
                                        min={1}
                                        onChange={(e) => changeCountItems(e, refusedItem.id)}
                                    />
                                </StyledFormItem>

                            </Col>
                            <Col className="gutter-row" key={"summ_" + index} span={6}>
                                {refusedItem.priceWithDiscount + " р."}
                            </Col>
                        </Row>
                    </>
                )) }

                {refusedDelivery && (
                    <Row gutter={24} style={{ marginBottom: "16px" }}>
                        <Col className="gutter-row" key={"nameDelivery"} span={12}>
                            Услуга: Доставка
                        </Col>
                        <Col className="gutter-row" key={"countDelivery"} span={6}>
                        </Col>
                        <Col className="gutter-row" key={"summDelivery"} span={6}>
                            {orderData?.dashboardOrder?.deliveryPrice}
                        </Col>
                    </Row>
                )}

                <Row gutter={24} style={{ marginBottom: "16px" }}>
                    <Col className="gutter-row" key={"bottom_name"} span={12}>
                    </Col>
                    <Col className="gutter-row" key={"bottom_count"} span={6}>
                    </Col>
                    <Col className="gutter-row" key={"bottom_summ"} span={6}>
                        <strong>Всего к возврату: {allSumm} р.</strong>
                    </Col>
                </Row>

                <ButtonRow>
                    <ButtonCol>
                        <Button onClick={onClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Popconfirm
                            placement="left"
                            title="Действительно хотите отправить эти позиции на возврат?"
                            onConfirm={() => onSubmit()}
                            onCancel={() => stopPropagation}
                        >
                            <Button
                                danger
                                icon={<RotateClockwiseSvg />}
                                onClick={stopPropagation}
                            >
                                Отправить на возврат
                            </Button>
                        </Popconfirm>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>

        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
  
  .ant-popover-buttons {
    display: flex;
    gap: 5px;
  }
`

const stopPropagation = (e) => {
    e?.stopPropagation()
}
