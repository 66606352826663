import api from "src/api/index";

export async function getUserHints(phone) {
    if (!phone?.trim()) {
        return
    }

    const hintsForm = new FormData()
    hintsForm.append("phone", phone)

    return await api.post("hints-users", hintsForm)
}