import React from "react"
import { notification } from "antd"
import api from "src/api"

export function useManageItems(pagination, getItems) {

  async function onConfirmPayment(id) {
    try {
      await api.post(`orders/hold-payment/${id}`)

      notification.success({ message: "Подтверждено" })
    } catch (error) {
      notification.error({ message: "Ошибка подтверждения" })
      throw error
    } finally {
      getItems(pagination.current, pagination.pageSize)
    }
  }

  async function onCanceledPayment(id) {
    try {
      await api.post(`orders/hold-payment/reject/${id}`)

      notification.success({ message: "Отменено" })
    } catch (error) {
      notification.error({ message: "Ошибка отмены" })
      throw error
    } finally {
      getItems(pagination.current, pagination.pageSize)
    }
  }

  return { onConfirmPayment, onCanceledPayment }
}
