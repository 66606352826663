import React from "react"
import api from "src/api"
import { getArticleCategories, getCities } from "src/utils"

export function useGetData() {
  const [dataArticles, setDataArticles] = React.useState([])
  const [dataArticlesColumns, setDataArticlesColumns] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)
  const [pagination, setPagination] = React.useState(null)

  const [cityOptions, setCityOptions] = React.useState([])
  const [categoryOptions, setCategoryOptions] = React.useState([])

  React.useEffect(() => {
    getItems()
    getCityOptions()
    getCategoryOptions()
  }, [])

  async function getCityOptions() {
    const data = await getCities()
    const options = []
    data.forEach((item) => {
      options.push({
        label: item.name,
        value: item.id,
      })
    })
    setCityOptions(options)
  }

  async function getCategoryOptions() {
    const data = await getArticleCategories()

    const options = []
    data.forEach((item) => {
      if (item.isActive) {
        let name = item.name
        let searchName = name.find((el) => el.language === "ru")
        options.push({
          label: searchName.content,
          value: item.id,
        })
      }
    })
    setCategoryOptions(options)
  }

  const getItems = (page = 1, countOnPage = 20) => {
    setIsLoading(true)
    api.get(`articles?page=${page}&countOnPage=${countOnPage}`).then(({ data }) => {
      setPagination({
        current: data.pagination.currentPage,
        pageSize: data.pagination.countOnPage,
        total: data.pagination.total,
      })
      updateItems(data.items)
    })

    setIsLoading(false)
  }

  function updateItems(items) {
    const newData = getFormattedItems(items)
    setDataArticlesColumns(newData)
    setDataArticles(items)
  }

  function handleTableChange(pagination) {
    getItems(pagination.current, pagination.pageSize)
  }

  function getFormattedItems(items) {
    const newItems = []

    items.forEach((item) => {
      let category = item.articleCategory.name
      let name = item.name
      let description = item.description
      let cityNames = []
      item.cities.forEach((city) => {
        cityNames.push(city.name)
      })
      let newItem = {
        id: item.id,
        sortWeight: item.sortWeight,
        isActive: item.isActive,
        category: category.find((el) => el.language === "ru").content,
        name: name.find((el) => el.language === "ru").content,
        description: description.find((el) => el.language === "ru").content,
        cities: cityNames.join(", "),
      }
      newItems.push(newItem)
    })

    return newItems
  }

  return {
    dataArticles,
    dataArticlesColumns,
    pagination,
    isLoading,
    handleTableChange,
    refetch: () => getItems(),
    options: { cityOptions, categoryOptions },
  }
}
