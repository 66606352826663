import React from 'react'
import Icon from '@ant-design/icons'

const SvgIcon = ({ stroke = 'currentColor', strokeWidth = 1.5, size = 20 }) => {
    return <svg
        width={size}
        height={size}
        viewBox='0 0 24 24'
        strokeWidth={strokeWidth}
        stroke={stroke}
        fill='none' strokeLinecap='round' strokeLinejoin='round'
    >
        <path stroke='none' d='M0 0h24v24H0z' fill='none' />
        <circle cx={9} cy={7} r={4} />
        <path d='M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2' />
        <path d='M16 3.13a4 4 0 0 1 0 7.75' />
        <path d='M21 21v-2a4 4 0 0 0 -3 -3.85' />
    </svg>
}

export function UsersSvg(props) {
    return <Icon
        component={SvgIcon}
        {...props}
    />
}
