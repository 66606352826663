import React from "react";
import api from "../../../api";

export function useGetNotificationClients() {
    const [notificationPagination, setNotificationPagination] = React.useState(null);
    const [isNotificationLoading, setIsNotificationLoading] = React.useState(false);
    const [userId, setUserId] = React.useState(false);
    const [notificationItems, setNotificationItems] = React.useState([]);
    async function getNotificationItems(id, page = 1, countOnPage = 20,) {
        setIsNotificationLoading(true);
        try {
            const response = await api.get(`user-notification/${id}?page=${page}&countOnPage=${countOnPage}`);

            setNotificationPagination({
                current: response.data.pagination.currentPage,
                pageSize: response.data.pagination.countOnPage,
                total: response.data.pagination.total
            });
            setNotificationItems(response.data.items);

            setIsNotificationLoading(false);
        } catch (error) {
            setIsNotificationLoading(false);
        }
    }

    const setUserIdHandler = (id) => {
        setUserId(id);
    }
    const handleNotificationTableChange = (pagination) => {
        getNotificationItems(userId, pagination.current, pagination.pageSize )
    };

    const clearNotificationData = () => {
        setNotificationPagination(false)
        setUserId(false)
        setNotificationItems([])
    }

    return {
        notificationItems,
        notificationPagination,
        isNotificationLoading,
        getNotificationItems,
        handleNotificationTableChange,
        clearNotificationData,
        setUserIdHandler
    };
}