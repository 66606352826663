export function getLanguages(){
  return [
    {
      label: "Русский",
      value: "ru",
      textErrors: {
        required: "Пожалуйста, заполните поле"
      }
    },
    {
      label: "English",
      value: "en",
      textErrors: {
        required: "Please, enter field"
      }
    }
  ]
}