import React from "react"
import { notification } from "antd"
import moment from "moment"
import api from "src/api"

export function useManageItems(items, getItems, onSuccessEdit, onSuccessCreate) {
  const [editData, setEditData] = React.useState(null)

  function onEditItem(id) {
    const item = items.find((item) => item.id === id)
    const dataItem = formmatedEditData(item)
    setEditData(dataItem)
    onSuccessEdit()
  }

  async function onCreate(values) {
    try {
      await api.post("events", values)

      notification.success({ message: "Мероприятие добавлено" })
      onSuccessCreate()
      getItems()
    } catch (error) {
      console.error("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }

  async function onSaveEdit(values, id) {
    try {
      await api.post(`events/${id}`, values)

      notification.success({ message: "Мероприятие изменено" })
      getItems()
    } catch (error) {
      console.error("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  async function onDelete(id) {
    try {
      await api.delete(`events/${id}`)

      notification.success({ message: "Мероприятие удалено" })
      getItems()
    } catch (error) {
      console.error("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  const formmatedEditData = (item) => {
    const newItem = {
      ...item,
      cities: item.cities.map((element) => element.id),
      cityIds: item.cities.map((element) => element.id),
      eventCategoryId: item.eventCategory.id,
      startedAt: moment(item.startedAt, "YYYY-MM-DD  HH:mm:ss"),
      finishedAt: item.finishedAt ? moment(item.finishedAt, "YYYY-MM-DD  HH:mm:ss") : null,
      buttonLink: item.buttonLink !== "null" ? item.buttonLink : null,
      buttonText: item.buttonText !== "null" ? item.buttonText : null,
      statuses: item.statuses.map((element) => element.id)
    }

    return newItem
  }

  function resetData() {
    setEditData(null)
  }

  return { editData, resetData, onSaveEdit, onCreate, onDelete, onEditItem }
}
