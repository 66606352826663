import React from "react"
import {Col, Drawer, Form, Input, InputNumber, Radio, Row, Select, Tabs} from "antd"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import { getLanguages } from "src/utils"
import {
  getFormattedLangData,
  getFormData,
  getInitialValues,
  getNestedFormData,
  isObject,
  whiteSpaceValidation
} from "src/utils/helpers"
import styled from "styled-components"
import {TranslatableInput} from "../../components/Translatable";

export function EditDrawer({ title, initialValues, onClose, onSave, systemOptions }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)


  const [form] = Form.useForm()

  function prepareData(values) {
    const id = initialValues?.id

    const data = {
      ...(id && { id, _method: "PUT" }),
      password: values.password,
      token: values.token,
      login: values.login,
      slug: values.slug,
      isHaveVat: values.isHaveVat ? 1 : 0,
      isDefault: values.isDefault ? 1 : 0,
      taxSystem: values.taxSystem,
    }

    return data
  }

  async function onSubmit(values) {
    const baseData = prepareData(values)
    const formData = getFormData({
      values: baseData
    })

    const data = getFormattedLangData(values)

    const dataWithNestedItem = {
      name: data.name
    }

    const formDataWithNestedFormData = getNestedFormData(
        dataWithNestedItem,
        formData
    )
    await onSave(formDataWithNestedFormData)
    onClose()
    form.resetFields()
  }

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  function handleClose() {
    onClose()
    form.resetFields()
  }

  const id = initialValues?.id ?? "new"

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={title}
      onClose={handleClose}
      open
    >
      <FormEdit
        form={form}
        layout={"vertical"}
        onFinish={(values) => onSubmit(values)}
      >
        <StyledFormItem label={"Название"}>
          <TranslatableInput maxLength={255} name={"name"} id={id}></TranslatableInput>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_slug"}
            label={"Системное название"}
            name={"slug"}
            rules={[
              {
                required: true,
                message: "Введите слаг",
              },
            ]}
        >
          <Input maxLength={255} type={"text"} placeholder={"Слаг"} />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_login"}
            label={"Логин"}
            name={"login"}
            rules={[
              {
                required: true,
                message: "Введите логин",
              },
            ]}
        >
          <Input maxLength={255} type={"text"} placeholder={"Логин"} />
        </StyledFormItem>

        {id === "new" && (
            <StyledFormItem
                key={id + "_password"}
                label={"Пароль"}
                name={"password"}

                rules={[
                  {
                    required: true,
                    message: "Введите пароль",
                  },
                ]}
            >
              <Input maxLength={255} type={"text"} placeholder={"Пароль"} />
            </StyledFormItem>
        )}

        {id !== "new" && (
            <StyledFormItem
                key={id + "_password"}
                label={"Пароль"}
                name={"password"}

                rules={[
                  {
                    required: true,
                    message: "Введите пароль",
                  },
                ]}
            >
              <Input.Password maxLength={255} placeholder={"Пароль"} />
            </StyledFormItem>
        )}

        <StyledFormItem
            key={id + "_token"}
            label={"Токен"}
            name={"token"}

            rules={[
              {
                validator: whiteSpaceValidation
              },
            ]}
        >
          <Input maxLength={255} type={"text"} placeholder={"token"} />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_taxSystem"}
            label={"Система налогооблажения"}
            name={"taxSystem"}
            rules={[
              {
                required: true,
                message: "Выберите систему налогооблажения!",
              },
            ]}
        >
          <Select options={systemOptions} />
        </StyledFormItem>

        <StyledFormItem
            key={id + "_isHaveVat"}
            label={"с НДС"}
            name={"isHaveVat"}
            initialValue={form.isDefault ? form.isDefault : false}
            rules={[
              {
                required: true,
                message: "Выберите вариант!",
              },
            ]}
        >
          <Radio.Group
              buttonStyle="solid"
              value={form.isHaveVat ? form.isHaveVat : false}
          >
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>

        <StyledFormItem
            key={id + "_isDefault"}
            label={"Основной"}
            name={"isDefault"}
            initialValue={form.isDefault ? form.isDefault : false}
            rules={[
              {
                required: true,
                message: "Выберите вариант!",
              },
            ]}
        >
          <Radio.Group
              buttonStyle="solid"
              value={form.isDefault ? form.isDefault : false}
          >
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>


        <ButtonRow>
          <ButtonCol>
            <Button
              danger
              onClick={() => {
                if (initialValues) {
                  form.setFieldsValue(getInitialValues(initialValues))
                  return
                }
                form.resetFields()
              }}
              icon={<RotateClockwiseSvg />}
            >
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button icon={<SaveSvg />} type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>
    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledTabs = styled(Tabs)`
  overflow: visible;

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-bottom: none;
    user-select: none;
  }

  &.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    padding: 3px 7px 6px;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    bottom: -3px;
    margin-bottom: 0;

    &::before {
      border-bottom: none;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`
