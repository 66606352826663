import React from "react"
import { PlusOutlined } from "@ant-design/icons"
import {
  Col,
  Drawer,
  Form,
  Image,
  Input, InputNumber,
  notification,
  Radio,
  Row,
  Upload,
} from "antd"
import api from "src/api"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { TinyMCE } from "src/components/Wysiwyg/TinyMCE"
import { useAppData } from "src/store/app-data"
import { getFormData, getOldImage } from "src/utils/helpers"
import styled from "styled-components"

export function DrawerEdit({ onClose, initialData }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  const [editForm] = Form.useForm()
  //const [loading, setLoading] = React.useState(false)
  const [previewVisible, setPreviewVisible] = React.useState(false)
  const [previewImage, setPreviewImage] = React.useState({})
  const [fileList, setFileList] = React.useState([])
  const [editImage, setEditImage] = React.useState(false)

  React.useEffect(() => {
    if (initialData) {
      updateInitialValues(initialData)
    }
  }, [initialData])

  function updateInitialValues(initialData) {
    editForm.setFieldsValue({ ...initialData, image_update: false })
    setImage(initialData.image)
    setEditImage(false)
  }

  function setImage(image) {
    if (image) {
      const fileList = getOldImage(image)
      setFileList(fileList)
    }
  }

  const onChange = (file) => {
    setFileList(file ? [file] : [])
    setEditImage(true)
  }

  function onBeforeUpload(file) {
    handleChanges(file)
    return false
  }

  async function handleChanges(file) {
    if (file instanceof File) {
      file.url = await URL.createObjectURL(file)
    }
    onChange(file)
  }

  function onRemove() {
    editForm.setFieldValue("image", null)
    onChange(null)
  }

  function onPreview(file) {
    setPreviewImage(file)
    setPreviewVisible(!previewVisible)
  }

  async function onFinishEdit(values) {
    const formData = getFormData({
      values,
      isPut: true,
      image: editImage && fileList[0],
    })

    if (editImage) {
      formData.append("checkUpdate", editImage)
    }

    try {
      await api.post(`live-love-work/${initialData.id}`, formData)

      notification.success({ message: "Объект обновлён" })
      editForm.resetFields()
      onClose()
    } catch (error) {
      notification.error({ message: "Ошибка обновления" })
    }
  }

  return (
    <Drawer
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={"Редактировать объект"}
      onClose={onClose}
      open
    >
      <Form form={editForm} layout={"vertical"} onFinish={onFinishEdit}>
        <StyledFormItem
          key={initialData.id + "_title"}
          label={"Заголовок"}
          name={"title"}
          rules={[
            {
              required: true,
              message: "Пожалуйста введите заголовок!",
            },
          ]}
        >
          <Input value={""} type={"text"} maxLength={200} placeholder={"Заголовок"} />
        </StyledFormItem>
        <StyledFormItem
          key={initialData.id + "_description"}
          label={"Подзаголовок"}
          name={"description"}
          rules={[
            {
              required: true,
              message: "Пожалуйста введите подзаголовок!",
            },
          ]}
        >
          <Input value={""} type={"text"} maxLength={200} placeholder={"Подзаголовок"} />
        </StyledFormItem>
        <StyledFormItem
            key={initialData.id + "_sortWeight"}
            label={"Позиция в списке"}
            name={"sortWeight"}
            rules={[
              {
                required: true,
                message: "Введите позицию!",
              },
            ]}
        >
          <InputNumber
              min={0}
              style={{
                width: "100%",
              }}
              placeholder={"Позиция"}
          />
        </StyledFormItem>
        <StyledFormItem
          key={initialData.id + "_content"}
          label={"Контент"}
          name={"content"}
          rules={[
            {
              required: true,
              message: "Пожалуйста введите контент!",
            },
          ]}
        >
          <TinyMCE />
        </StyledFormItem>
        <StyledFormItem
          key={initialData.id + "_isActive"}
          label={"Включен"}
          name={"isActive"}
          rules={[
            {
              required: true,
              message: "Please select!",
            },
          ]}
          initialValue={initialData.isActive ? initialData.isActive : true}
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={true}>Да</Radio.Button>
            <Radio.Button value={false}>Нет</Radio.Button>
          </Radio.Group>
        </StyledFormItem>
        <StyledFormItem
          key={initialData.id + "_image"}
          label={"Изображение"}
          name={"image"}
          valuePropName={"image"}
          rules={[
            {
              required: true,
              message: "Пожалуйста загрузите изображение!",
            },
          ]}
        >
          <div>
            <Upload
              maxCount={1}
              showUploadList={true}
              status="done"
              listType="picture-card"
              fileList={fileList}
              onRemove={onRemove}
              onPreview={onPreview}
              accept="image/jpeg,image/png,image/jpg"
              beforeUpload={(file) => onBeforeUpload(file)}
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Загрузить
                </div>
              </div>
            </Upload>
            <StyledImage
              alt={previewImage.name || "Превью"}
              preview={{
                visible: previewVisible,
                onVisibleChange: () => setPreviewVisible(!previewVisible),
              }}
              src={previewImage.largePreview || previewImage.url}
            />
          </div>
        </StyledFormItem>
        <ButtonRow>
          <ButtonCol>
            <Button
              danger
              onClick={() => {
                if (initialData) {
                  updateInitialValues(initialData)
                  return
                }
              }}
              icon={<RotateClockwiseSvg />}
            >
              Вернуть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button onClick={onClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
          <ButtonCol>
            <Button icon={<SaveSvg />} type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ButtonCol>
        </ButtonRow>
      </Form>
    </Drawer>
  )
}

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`
const StyledImage = styled(Image)`
  display: none;
`
const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`
