import {Button} from "../../components/actions/button";
import styled from "styled-components";
import {DollarOutlined, ExportOutlined} from "@ant-design/icons";

export function OrderExpandableRow({data, onSend, onPayment}) {


    return (
        <>
            <StyledFlex>
                <StyledButton
                    color={"#808080"}
                    icon={<ExportOutlined />}
                    onClick={() => onSend(data.id)}
                >
                    {"Отправить в CRM"}
                </StyledButton>
                <StyledButton
                    color={"#808080"}
                    icon={<DollarOutlined />}
                    onClick={() => onPayment(data.id)}
                >
                    {"Проверить оплату"}
                </StyledButton>
            </StyledFlex>

        </>
    )
}

const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  margin-left: 35px;
  gap: 30px;
  
`
const StyledButton = styled(Button)`
    padding: 0 30px;
`