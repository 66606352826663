import { STATE_MEDIA } from "src/config";
import api from "../api";

export const getWordDeclension  = (count, arr) => {
  let resultStr = count + " ";
  const strLength = String(count).length;
  if ([11, 12, 13, 14].indexOf(Number(count)) !== -1) {
    resultStr += arr[2];
  } else {
    const symbol = strLength > 1 ? String(count)[strLength - 1] : count;
    switch (Number(symbol)) {
      case 1:
        resultStr += arr[0];
        break;
      case 2:
      case 3:
      case 4:
        resultStr += arr[1];
        break;
      default:
        resultStr += arr[2];
    }
  }
  return resultStr;
};

export function isObject(value) {
  return (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
  );
}

export function getFormData({
                              values, isPut, image, isImageUpdated
                            }) {
  const formData = new FormData()
  Object.entries(values).forEach(([key, value]) => {
    if (key !== "image") {
      formData.append(key, value)
    }
  })

  if(isPut) {
    formData.append("_method", "PUT")
  }

  if (image) {
    formData.append("image", image, "image_" + Date.now())
  }

  if (isImageUpdated) {
    formData.append("imageUpdated", isImageUpdated)
  }

  return formData
}

export function isLangItem(item) {
  return isObject(item) && (item["ru"] || item["en"])
}

export function getNestedFormData(dataWithNestedItem, formData) {
  const arr = Object.entries(dataWithNestedItem)

  arr.forEach(([keyObj, arrItem]) => {
    arrItem.forEach((item, index) => {
      if(!item["language"]) {
        formData.append(`${keyObj}[${index}]`, item)
        return
      }

      Object.entries(item).forEach(([key, value]) => {
        formData.append(`${keyObj}[${index}][${key}]`, value)
      })
    })

  })
  return formData
}

export function getInitialValues(data) {
  const arr = Object.entries(data)
  function getFormattedItem(item) {
    return Object.entries(item).reduce((acc, [_, item]) => {
      return {
        ...acc,
        [item.language]: item.content,
      }
    }, {})
  }
  const newData = arr.reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]:
            Array.isArray(item) && item.length && item[0]?.language
                ? getFormattedItem(item)
                : item,
      }),
      {}
  )
  return newData
}

export function isDefined(value) {
  return typeof value !== "undefined"
}

export function getFormattedLangData(data) {
  const arr = Object.entries(data)

  function getFormattedItem(item) {
    return Object.entries(item).reduce((acc, [key, item]) => {
      if(!isDefined(item)) {
        return acc
      }
      return [
        ...acc,
        {
          language: key,
          content: item,
        },
      ]
    }, [])
  }

  const arrData = arr.reduce(
      (acc, [key, item]) => ({
        ...acc,
        [key]: isLangItem(item) ? getFormattedItem(item) : item,
      }),
      {}
  )

  return arrData
}

export function getOldImage(image) {
  if(!image) {
    return []
  }

  const file = {
    url: STATE_MEDIA + image.path + "/" + image.systemName,
    uid: image.id,
    name: image.originalName,
    status: "done",
  }

  return [file]
}

export function whiteSpaceValidation(_, value) {
  const regexp = new RegExp(/^\s*$/)
  return !value || !regexp.test(value)
      ? Promise.resolve()
      : Promise.reject(new Error("Пробелы не допускаются"))
}

export function maxFileSizeValidation(_, value) {
  return value?.fileList?.[0]
      ? (value?.fileList[0]?.size < 3000000
        ? Promise.resolve()
        : Promise.reject(new Error("Файл должен быть меньше 3мб")))
      : Promise.resolve()
}

export async function phoneValidation(_, value) {
  const reg = new RegExp("^(79)?(\\d{11})$");
  if (value.length !== 11 || !reg.test(value)) {
    return Promise.reject(new Error("Введите корректный номер 79*********"));
  } else {
    let check = false
    const { data } = await api.get(`users-filter?phone=${value}`)
    data.items.length > 0 ? check = false : check = true
    return check
        ? Promise.resolve()
        : Promise.reject(new Error("Такой номер уже есть или не прошёл проверку"));
  }
}

export async function formatPhoneValidation(_, value) {
  const reg = new RegExp("^(79)?(\\d{11})$");
  return value.length === 11 && reg.test(value)
      ? Promise.resolve()
      : Promise.reject(new Error("Введите корректный номер 79*********"));
}

export async function emailValidation(_, value) {
  const reg = new RegExp("([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+)");
  if (value == null || value?.trim() === "") {
    return Promise.resolve()
  } else if (value.trim() !== "" && !reg.test(value)) {
    return Promise.reject(new Error("Введите корректный email"));
  } else {
    let check = false
    const { data } = await api.get(`users-filter?email=${value}`)
    data.items.length > 0 ? check = false : check = true
    return check
        ? Promise.resolve()
        : Promise.reject(new Error("Пользователь с таким email уже есть"));
  }
}

export async function formatEmailValidation(_, value) {
  const reg = new RegExp("([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9_-]+)");
  if (value == null || value?.trim() === "") {
    return Promise.resolve()
  } else if (value.trim() !== "" && !reg.test(value)) {
    return Promise.reject(new Error("Введите корректный email"));
  } else {
    return Promise.resolve()
  }
}


export async function formatGuidValidation(_, value) {
    const reg = new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$", "i");
    if (value == null || value?.trim() === "") {
      return Promise.reject(new Error("Введите guid"));
    } else if (value.trim() !== "" && !reg.test(value)) {
      return Promise.reject(new Error("Введите корректный guid или удалите поле"));
    } else {
      return Promise.resolve()
    }
}

export async function uniquePromocodeNameValidation(_, value) {
  if (value.length > 3) {
    let check
    const { data } = await api.get(`promo-codes?&filters[0][t]=equal&filters[0][f]=name&filters[0][v]=${value}`)
    data.items.length > 0 ? check = false : check =  true;
    return check
        ? Promise.resolve()
        : Promise.reject(new Error("Промокод с таким названием уже есть"));
  } else {
    if (value === "")  {
      return Promise.reject(new Error("Введите название"));
    } else {
      return Promise.reject(new Error("Введите более 3-ех символов"));
    }
  }
}
