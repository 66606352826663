import React from "react"
import api from "src/api"
import {getPaymentTypes} from "../../../utils";
import {value} from "lodash/seq";

export function useGetOrders() {
  const [pagination, setPagination] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [items, setItems] = React.useState([])
  const [deliveryTypes, setDeliveryTypes] = React.useState([])
  const [paymentTypes, setPaymentTypes] = React.useState([])

  async function getItems(page = 1, countOnPage = 20, filters = "") {
    setIsLoading(true)
    try {
      const response = await api.get(
        `orders?page=${page}&countOnPage=${countOnPage}${filters}`
      )
      setPagination({
        current: response.data.pagination.currentPage,
        pageSize: response.data.pagination.countOnPage,
        total: response.data.pagination.total,
      })
      setItems(response.data.items)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    getItems()
    getDeliveryTypes()
    getDataPaymentTypes()
  }, [])

  function handleTableChange(pagination) {
    getItems(pagination.current, pagination.pageSize)
  }

  async function handlePaymentCheck(id) {
    setIsLoading(true)
    try {
      const response = await api.get(`orders/payment/${id}`)

      getItems(pagination?.current ?? 1, pagination?.pageSize ?? 20)

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  async function resendToCrm(id) {
    setIsLoading(true)
    try {
      const response = await api.get(`orders/send/${id}`)

      getItems(pagination?.current ?? 1, pagination?.pageSize ?? 20)

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  function handleSearchTable(filters) {
    let filters_str = ""
    let count = 0;
    for (let [key, value] of Object.entries(filters)) {

      if(value) {
        switch (key) {
          case "phone":
            filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=user.phone&filters[${count}][v]=${value}`
            break;
          case "email":
            filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=user.email&filters[${count}][v]=${value}`
            break;
          case "innerId":
            filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=inner_id&filters[${count}][v]=${value}`
            break;
          case "dateFrom":
            filters_str += `&filters[${count}][t]=more&filters[${count}][f]=created_at&filters[${count}][v]=${value}`
            break;
          case "dateTo":
            filters_str += `&filters[${count}][t]=less&filters[${count}][f]=created_at&filters[${count}][v]=${value}`
            break;
        }
      }
      
      count++
    }
    getItems(1,  20, filters_str)
  }

  async function getDataPaymentTypes() {
    const types = await getPaymentTypes()
    const newItems = types.map((item) => {
      return {
        label: item.name.find((el) => el.language === "ru").content,
        value: item.id
      }
    })
    setPaymentTypes(newItems)
  }
  async function getDeliveryTypes() {
    try {
      const { data } = await api.get("delivery-types")
      const options = data.items.map((item) => ({
        ...item,
        nameRu: item?.name?.find((el) => el.language === "ru").content,
      }))
      setDeliveryTypes(options)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    items,
    pagination,
    isLoading,
    refetch: () => getItems(),
    options: { deliveryTypes, paymentTypes },
    handleTableChange,
    handleSearchTable,
    handlePaymentCheck,
    resendToCrm
  }
}

export function getFormattedItems(items) {
  const data = items.map((item) => ({
    key: item.dashboardOrder.id,
    phone: item.dashboardUser.phone,
    outNumber: item.dashboardOrder.outNumber,
    externalGuid: item.dashboardOrder.externalGuid,
    isNeedAccept: item.dashboardOrder.isNeedAccept,
    isPaid: item.dashboardOrder.isPaid,
    id: item.dashboardOrder.id,
    innerId: item.dashboardOrder.innerId,
  }))

  return data
}
