import React from "react"
import api from "src/api"
import {getEventHints, getProductCategories} from "src/utils"

export function useGetData() {
  const [dataItemsColumns, setDataItemsColumns] = React.useState([])
  const [dataItems, setDataItems] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)
  const [pagination, setPagination] = React.useState(null)

  const [ rewardTypesOptions, setRewardTypesOptions] = React.useState([])
  const [ eventOptions, setEventOptions ] = React.useState([]);

  React.useEffect(() => {
    getRewardTypes()
    getEventOptions()
    getItems()
  }, [])

  const getItems = (page = 1, countOnPage = 20) => {
    setIsLoading(true)
    api.get(`loyalty-rewards?page=${page}&countOnPage=${countOnPage}`).then(({ data }) => {
      setPagination({
        current: data.pagination.currentPage,
        pageSize: data.pagination.countOnPage,
        total: data.pagination.total,
      })
      const columnsItems = getFormattedItems(data.items)
      setDataItemsColumns(columnsItems)
      setDataItems(data.items)
    })

    setIsLoading(false)
  }

  async function getRewardTypes() {
    api.get("task-reward-types").then(({ data }) => {
      const options = []
      data.items.forEach((item) => {
        options.push({
          label: item.name,
          value: item.id
        })
      })
      setRewardTypesOptions(options)
    })
  }

  async function getEventOptions() {
    const events = getEventHints()
    events.then(( data ) => {
      const options = []
      data.forEach((item) => {
        options.push({
          label: item.name,
          value: item.id,
        })
      })
      setEventOptions(options)
    })
  }

  function handleTableChange(pagination) {
    getItems(pagination.current, pagination.pageSize)
  }

  function getFormattedItems(items) {
    const newItems = []

    items.forEach((item) => {
      let newItem = {
        key: item.id,
        id: item.id,
        isActive: item.isActive,
        name: item.name.find((el) => el.language === "ru").content,
        description: item.description.find((el) => el.language === "ru").content,
      }
      newItems.push(newItem)
    })

    return newItems
  }

  return {
    dataItems,
    dataItemsColumns,
    pagination,
    isLoading,
    handleTableChange,
    refetch: () => getItems(),
    options: { rewardTypesOptions, eventOptions},
  }
}
